import { useLayoutEffect, useState } from 'react';

/**
 * Uses the MatchMedia API to determine if the document matches the given media
 * query string. Also monitors the document to detect when the match changes.
 * @param query {string} A media query string, i.e. `(min-width: 768px)`.
 * @returns {boolean}
 */
export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState(() => matchMedia(query).matches);

  useLayoutEffect(() => {
    const mediaQueryList = matchMedia(query);
    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches);

    // note 1: Safari currently doesn't support add/removeEventListener so we
    //         use add/removeListener
    // note 2: add/removeListener are maybe marked as deprecated, but that could
    //         be wrong.
    //         See https://github.com/microsoft/TypeScript/issues/32210
    mediaQueryList.addListener(onChange);
    return () => mediaQueryList.removeListener(onChange);
  }, [query]);

  return matches;
}
