import {
  type MouseEventHandler,
  type RefObject,
  useCallback,
  useLayoutEffect,
  useRef
} from 'react';
import { useLocation } from 'react-router-dom';
import { isFocusableElement } from '../getFocusableElements';
import { get } from '../store';
import { useLogger } from './useLogger';

function findNestedTarget(element: HTMLElement): HTMLElement | null {
  return element.querySelector('nav,[role="nav"],h1,h2,h3,h4,h5,h6,input');
}

function focusElement(element: HTMLElement) {
  queueMicrotask(() => {
    if (!isFocusableElement(element)) {
      element.setAttribute('tabIndex', '-1');
    }

    element.focus({ preventScroll: true });
  });
}

export type GuidedFocusAfterNavigationOptions = {
  lookupNestedTarget?: boolean;
  retryDelay?: number;
  maxRetryAttempts?: number;
};

export function useGuidedFocusAfterLinkNavigation<T extends HTMLElement = HTMLDivElement>(
  contentRef: RefObject<T>,
  {
    lookupNestedTarget = true,
    maxRetryAttempts = 3,
    retryDelay = 150
  }: GuidedFocusAfterNavigationOptions = {}
) {
  const { key } = useLocation();
  const shouldFocusRef = useRef(false);
  const retryTimerRef = useRef<number>();
  const retryAttemptsRef = useRef(0);
  const logger = useLogger('GuidedFocus', Boolean(get('isA11yDebugEnabled')));

  useLayoutEffect(() => {
    if (shouldFocusRef.current) {
      shouldFocusRef.current = false;

      if (contentRef.current) {
        const element = contentRef.current;

        if (!lookupNestedTarget) {
          // We don't want to look for nested targets, so we just focus the
          // content element.
          logger('log', 'Focusing content element after navigation.', element);
          focusElement(element);
        } else {
          let match = findNestedTarget(element);

          if (match) {
            logger('log', 'Focusing nested target after navigation', match);
            focusElement(match);
          } else {
            logger('log', `No nested target found, retrying in ${retryDelay} ms`);

            retryTimerRef.current = window.setInterval(() => {
              retryAttemptsRef.current += 1;

              match = findNestedTarget(element);

              if (match) {
                logger('log', `Retry attempt ${retryAttemptsRef.current}/${maxRetryAttempts}: Focusing nested target`, match); // prettier-ignore

                // Focus nested target and clean up timer + retry attempts.
                focusElement(match);
                clearInterval(retryTimerRef.current);
                retryAttemptsRef.current = 0;
              } else {
                logger('log', `Retry attempt ${retryAttemptsRef.current}/${maxRetryAttempts}: No nested target found`); // prettier-ignore

                if (retryAttemptsRef.current >= maxRetryAttempts) {
                  logger('log', 'Max retry attemps reached, focus content element instead.');

                  // Reached max retry attempts, focus content element instead and
                  // clean up timer + retry attempts.
                  focusElement(element);
                  clearInterval(retryTimerRef.current);
                  retryAttemptsRef.current = 0;
                }
              }
            }, retryDelay);
          }
        }
      } else {
        logger('log', 'No content element found, aborting focus attempt.');
      }
    }

    return () => {
      // Clearing timers from previous pathname
      clearInterval(retryTimerRef.current);
      retryAttemptsRef.current = 0;
    };
  }, [key, lookupNestedTarget, maxRetryAttempts, retryDelay, logger, contentRef]);

  const handler: MouseEventHandler<HTMLAnchorElement> = useCallback(
    evt => {
      logger('log', 'Click handler invoked, navigating to', evt.currentTarget.getAttribute('href'));
      clearInterval(retryTimerRef.current);
      shouldFocusRef.current = true;
    },
    [logger]
  );

  return handler;
}
