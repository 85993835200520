import { forwardRef, type HTMLAttributes, type ReactNode } from 'react';
import { Box, Flex, type FlexProps, Spacer } from '@/components/primitives';
import { ShowAt } from '@/components/Responsive';
import { Article } from '@/components/SectioningContentElement';

type Slots = {
  content: ReactNode;
  header: ReactNode;
  iconOrActor: ReactNode;
};

type Props = HTMLAttributes<HTMLElement> & {
  py?: FlexProps['py'];
  slots: Slots;
};

export const FeedEntryLayout = forwardRef<HTMLDivElement, Props>(
  ({ py = 's08', slots, ...props }, ref) => (
    <Flex
      alignItems="flex-start"
      as={Article}
      overflowWrap="break-word"
      px={{ _: 's00', lg: 's04' }}
      py={py}
      wordBreak="break-word"
      {...props}
      ref={ref}
    >
      <ShowAt breakpoint="lg" css={{ display: 'flex' }}>
        <Flex justifyContent="right" width="2.25rem">
          {slots.iconOrActor}
        </Flex>
        <Spacer mr="s04" />
      </ShowAt>
      <Box flex={1}>
        {slots.header}
        {slots.content && <Box mt="s06">{slots.content}</Box>}
      </Box>
    </Flex>
  )
);
