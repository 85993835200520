import PropTypes from 'prop-types';

export const TaskAssignmentMode: Record<Api.TaskAssignmentMode, string> = {
  ALL_CONTRIBUTIONS: 'ALL_CONTRIBUTIONS',
  OWNED_CONTRIBUTIONS: 'OWNED_CONTRIBUTIONS',
  UNOWNED_CONTRIBUTIONS: 'UNOWNED_CONTRIBUTIONS'
};

// TODO: "ALL" doesn't belong here.
export const TaskStatus: Record<Api.TaskState | 'ALL', string> = {
  ALL: 'ALL',
  CLOSED: 'CLOSED',
  COMPLETE: 'COMPLETE',
  EXPIRED: 'EXPIRED',
  INCOMPLETE: 'INCOMPLETE',
  REJECTED: 'REJECTED'
};

export const TaskMode: Record<Api.TaskMode, string> = {
  CONTINUOUS: 'CONTINUOUS',
  SPRINT: 'SPRINT'
};

export const TaskModePropType = PropTypes.oneOf(Object.values(TaskMode));

export const FieldMode = {
  OPTIONAL: 'OPTIONAL',
  REQUIRED: 'REQUIRED',
  UNAVAILABLE: 'UNAVAILABLE'
};
