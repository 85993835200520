import { memo, type MouseEventHandler, type ReactChild } from 'react';
import { css } from '@emotion/react';
import { FauxLinkButton } from '@/components/FauxLinkButton';
import { useI18n } from '@/components/I18n';
import { Box, Text } from '@/components/primitives';
import type { Image } from '@/types';
import { DashboardFilterListItemIcon } from './DashboardFilterListItemIcon';
import { DashboardFilterListItemLayout } from './DashboardFilterListItemLayout';
import { DashboardFilterListItemThumbnail } from './DashboardFilterListItemThumbnail';

export type CommunityData = {
  id: string;
  image: Image | null;
  name: string;
};

type Props = {
  data: CommunityData;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const nameStyle = css({ whiteSpace: 'nowrap' });

export const DashboardFilterListHiddenCommunityItem = memo<Props>(({ data, onClick }) => {
  const i18n = useI18n();
  let contentBefore: ReactChild;

  if (data.image) {
    contentBefore = <DashboardFilterListItemThumbnail image={data.image} />;
  } else {
    contentBefore = <DashboardFilterListItemIcon bgColor="grey600" icon="user-group" />;
  }

  return (
    <DashboardFilterListItemLayout
      slots={{
        content: (
          <Box overflow="hidden">
            <Text
              color="textLight"
              css={nameStyle}
              fontSize="md"
              lineHeight="tight"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {data.name}
            </Text>
            <FauxLinkButton
              display="block"
              fontSize="sm"
              fontWeight="normal"
              lineHeight="tight"
              onClick={onClick}
            >
              {i18n.t('main', 'community.viewUserAgreementsButton.text')}
            </FauxLinkButton>
          </Box>
        ),
        contentBefore
      }}
    />
  );
});
