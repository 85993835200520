import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useAppConfigStore, useJargon } from '@/components/AppConfig';
import { lookupJargonTranslation, useI18n } from '@/components/I18n';
import { Screen } from '@/components/Screen';
import { SkipNavTarget } from '@/components/SkipNav';
import {
  DashboardViewContainer,
  type FilterValue,
  type GetLocation as GetDashboardLocation,
  type View
} from '@/features/dashboard/containers/DashboardViewContainer';
import { Page } from '@/pages/Page';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { ensureOptionalRouteParamIsString, type PathParamObject } from '@/utils/routing';
import { GetCommunityName } from './GetCommunityName.gql';

export type { GetDashboardLocation };

let isFirstRender = true;

type Props = {
  activeView?: View;
};

export const DashboardPage = ({ activeView = 'overview' }: Props) => {
  const { communityId } = useParams() as PathParamObject<'main', 'home'> & { communityId?: unknown; }; // prettier-ignore

  ensureOptionalRouteParamIsString(communityId);

  const location = useLocation();
  const i18n = useI18n();
  const { getHomePage } = useSharedLocations('main');
  const { reloadConfig } = useAppConfigStore();
  const communityLabel = lookupJargonTranslation<'community'>(useJargon().community, {
    COMMUNITY: () => i18n.t('main', 'community.label')
  });

  let contentTitle: string | undefined;
  let title: string;

  const queryResult = useQuery(GetCommunityName, {
    skip: !communityId,
    fetchPolicy: 'cache-first',
    variables: {
      communityId: communityId || '00000000-0000-0000-0000-000000000000'
    }
  });

  if (communityId) {
    title = communityLabel;
    contentTitle = queryResult.data?.community?.name;
  } else {
    title = i18n.t('main', 'dashboard.label');
  }

  useEffect(() => {
    // The code below will reload the AppConfig on any <DashboardPage> mounts
    // except for the first one. This works around an edge case where the user's
    // viewer_favoriteCommunityIds might become stale during their session,
    // typically the result of an admin having changed the viewer's
    // authorization on one or more communities. *If* that change impacts the
    // viewer's previous favorites, they might end up with an inconsistent
    // dashboard, i.e. dashboard being filtered on favoriteCommunityIds that
    // have since been removed and are also not visible anymore in the sidebar,
    // or vice versa.
    // This is not the most elegant approach. It will also trigger a double
    // fetch of the dashboard queries, but *only* in those cases where the
    // favorites have actually changed. If not, all remains the way it was
    // before.
    // Ideally we would like to trigger this reload earlier, but the only way to
    // achieve that is _before_ the navigation to the dashboard actually happens
    // which can only be done by putting the reloadConfig() in *every* Page
    // unmount, or by configuring a global history listener so that we can
    // listen for dashboard navigations and trigger it independently from the
    // react lifecycle. I've tried this method, and even this generally does not
    // avoid the double fetch because we're rendering pages quicker than we can
    // fetch the config.
    if (isFirstRender) {
      isFirstRender = false;
    } else {
      reloadConfig();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let activeFilter: FilterValue | undefined;

  if (communityId) {
    activeFilter = ['community', communityId];
  } else {
    const filter = new URLSearchParams(location.search).get('filter');

    if (filter === 'favorites' || filter === 'all') {
      activeFilter = filter;
    }
  }

  return (
    <Page contentTitle={contentTitle} id="main-home" title={title}>
      <Screen title={title}>
        <SkipNavTarget role="main">
          <DashboardViewContainer
            activeFilter={activeFilter}
            activeView={activeView}
            getLocation={getHomePage}
          />
        </SkipNavTarget>
      </Screen>
    </Page>
  );
};
