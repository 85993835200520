import { type PathParam as RRPathParam, useParams } from 'react-router-dom';
import type { AdminPathMap } from '@/pages/adminRouteConfig';
import type { DebugPathMap } from '@/pages/debugRouteConfig';
import type { MainPathMap } from '@/pages/mainRouteConfig';
import { hasValue } from './common';

type PathGroup = 'admin' | 'debug' | 'main';

// TODO: Simplify these generics since we now assume everything is unknown by
// default, rather than trying to infer the type from the regex.

export type PathParamObject<T extends PathGroup, U extends keyof PathMap<T>> = {
  [k in PathParam<T, U>]?: unknown;
};

type PathParam<T extends PathGroup, U extends keyof PathMap<T>> = PathMap<T>[U] extends string
  ? RRPathParam<PathMap<T>[U]>
  : never;

type PathMap<T extends PathGroup> = T extends 'main'
  ? MainPathMap
  : T extends 'debug'
    ? DebugPathMap
    : T extends 'admin'
      ? AdminPathMap
      : {}; // eslint-disable-line @typescript-eslint/ban-types

// @ts-expect-error
export function useRouteParams<T extends PathGroup, U extends keyof PathMap<T>>(group: T, path: U) {
  return useParams() as PathParamObject<T, U>;
}

export function getRouteParam(value: string | undefined): string {
  ensureRouteParamExists(value);
  return value;
}

class RouteParamError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RouteParamError';
  }
}

export class RouteParamNotFoundError extends RouteParamError {
  constructor() {
    super('Expected parameter was not found.');
    this.name = 'RouteParamNotFoundError';
  }
}

/** @public */
export class RouteParamNotStringError extends RouteParamError {
  constructor(param: unknown) {
    super(
      `Expected parameter to be a string, instead received "${typeof param}" (${String(param)})`
    );
    this.name = 'RouteParamNotStringError';
  }
}

// export class RouteParamNotStringConstraintError extends RouteParamError {
//   constructor(param: string, oneOf: string[]) {
//     super(`Expected parameter to be one of [${oneOf.join(',')}], instead received "${param}"`);
//     this.name = 'RouteParamNotStringConstraintError';
//   }
// }

export function ensureRouteParamExists(value: unknown): asserts value is string {
  if (!hasValue(value)) throw new RouteParamNotFoundError();
  if (typeof value !== 'string') throw new RouteParamNotStringError(value);
}

// export function ensureRouteParamIsStringConstraint<StringConstraint extends string>(
//   value: string,
//   constraints: StringConstraint[]
// ): asserts value is StringConstraint {
//   if (!constraints.includes(value)) {
//     throw new RouteParamNotStringConstraintError(value, constraints);
//   }
// }

export function ensureOptionalRouteParamIsString(
  value: unknown
): asserts value is string | undefined {
  if (value !== undefined && typeof value !== 'string') {
    throw new RouteParamNotStringError(value);
  }
}

// export function ensureOptionalRouteParamIsStringConstraint<StringConstraint extends string>(
//   value: unknown | undefined,
//   constraints: StringConstraint[]
// ): asserts value is StringConstraint | undefined {
//   ensureOptionalRouteParamIsString(value);

//   if (value !== undefined) {
//     ensureRouteParamIsStringConstraint(value, constraints);
//   }
// }
