import { lazy, Suspense } from 'react';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Heading } from '@/components/Heading';
import { useI18n } from '@/components/I18n';
import { Box, Card, Text } from '@/components/primitives';
import { StatusPageLayout } from '@/components/StatusPageLayout';
import { VisuallyHidden } from '@/components/VisuallyHidden';

const CurrentUserPendingGlobalUserAgreementsView = lazy(async () => ({
  default: (await import('@/features/user-agreements/main/views/CurrentUserPendingGlobalUserAgreementsView')).CurrentUserPendingGlobalUserAgreementsView // prettier-ignore
}));

type Props = {
  onSubmissionCompleted: () => void;
};

export const PendingGlobalUserAgreementsPage = ({ onSubmissionCompleted }: Props) => {
  const i18n = useI18n();

  return (
    <StatusPageLayout>
      <Box flex={1} maxWidth="48rem">
        <VisuallyHidden>
          <Heading>{i18n.t('main', 'userAgreement.label_multiple')}</Heading>
        </VisuallyHidden>
        <Text color="textLight" fontSize="lg" textAlign="center">
          {i18n.t('main', 'userAgreements.info.text')}
        </Text>
        <Card bgColor="white" boxShadow="lg" mt="s06" overflow="hidden" p={{ _: 's06', md: 's08' }}>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <CurrentUserPendingGlobalUserAgreementsView
                onSubmissionCompleted={onSubmissionCompleted}
              />
            </Suspense>
          </ErrorBoundary>
        </Card>
      </Box>
    </StatusPageLayout>
  );
};
