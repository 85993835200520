import type { HTMLAttributes, ReactNode } from 'react';
import { Flex, Spacer } from '@/components/primitives';
import { Article } from '@/components/SectioningContentElement';

type Slots = {
  description: ReactNode;
  header: ReactNode;
  meta: ReactNode;
  thumbnail: ReactNode;
};

type Props = HTMLAttributes<HTMLElement> & {
  slots: Slots;
};

export const FeedContributionCardLayout = ({ slots, ...props }: Props) => (
  <Flex as={Article} {...props}>
    {slots.thumbnail}
    <Spacer mr="s04" />
    <Flex dir="auto" flex={1} flexDirection="column">
      <Flex flex={1} flexDirection="column" mb={{ _: 's03', md: 's04' }}>
        {slots.header}
        {slots.description}
      </Flex>
      {slots.meta}
    </Flex>
  </Flex>
);
