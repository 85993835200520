import { lazy, Suspense } from 'react';
import type { To } from 'react-router-dom';
import { useAppConfig } from '@/components/AppConfig';
import { Image } from '@/components/Image';
import { Card, Flex, Spacer } from '@/components/primitives';
import { UnstyledLink } from '@/components/UnstyledLink';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { AppHeaderSearchForm } from '@/features/search/components/AppHeaderSearchForm';
import { appendOptionsToImageUrl } from '@/utils/getImageUrl';
import { useToggle } from '@/utils/hooks/useToggle';
import { join } from '@/utils/url';
import { AppHeaderUserDropdownMenu, type User } from './AppHeaderUserDropdownMenu';
import { Menu, type MenuItem } from './Menu';

const LanguageSelectionFormModal = lazy(async () => ({
  default: (await import('@/features/language-settings/views/LanguageSelectionFormModal')).LanguageSelectionFormModal // prettier-ignore
}));

export type { MenuItem, User };

type Props = {
  ideationModuleRequestsRoute: To;
  logoFileId: string | null;
  logoutUrl: string;
  menuAriaLabel: string;
  menuConfig?: MenuItem[];
  profileRoute: To;
  user?: User;
};

export const AppHeader = ({
  ideationModuleRequestsRoute,
  logoFileId,
  logoutUrl,
  menuAriaLabel,
  menuConfig = [],
  profileRoute,
  user
}: Props) => {
  const config = useAppConfig();
  const [isLangPopupVisible, { toggle: toggleLangPopup }] = useToggle(false);

  let logoUrl: string | null = null;

  if (logoFileId) {
    const baseUrl = join(config.application_url, config.image_endpoint, logoFileId);
    logoUrl = appendOptionsToImageUrl(baseUrl, { mode: 'max', h: 36 });
  }
  return (
    <>
      {isLangPopupVisible && (
        <Suspense fallback={null}>
          <LanguageSelectionFormModal
            onRequestClose={toggleLangPopup}
            onSubmitted={toggleLangPopup}
          />
        </Suspense>
      )}
      <Card
        bgColor="white"
        borderBottom={1}
        borderColor="grey100"
        boxShadow="sm"
        position="relative"
      >
        <Flex
          alignItems="stretch"
          height={52}
          justifyContent="space-between"
          px={{ _: 's03', lg: 's06' }}
        >
          <Flex>
            <UnstyledLink display="flex" rel="home" to="/">
              <VisuallyHidden>{config.application_applicationName}</VisuallyHidden>
              {logoUrl && (
                <Flex alignItems="center">
                  <Image
                    alt=""
                    data-testid="logo"
                    display="block"
                    maxHeight={36}
                    role="presentation"
                    src={logoUrl}
                  />
                  <Spacer mr="s06" />
                </Flex>
              )}
            </UnstyledLink>
            <Menu ariaLabel={menuAriaLabel} items={menuConfig} />
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <AppHeaderSearchForm />
            {user && (
              <>
                <Spacer mr="s04" />
                <AppHeaderUserDropdownMenu
                  ideationModuleRequestsRoute={ideationModuleRequestsRoute}
                  logoutUrl={logoutUrl}
                  onLanguageSettingsClick={toggleLangPopup}
                  profileRoute={profileRoute}
                  user={user}
                />
              </>
            )}
          </Flex>
        </Flex>
      </Card>
    </>
  );
};
