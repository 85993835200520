import { forwardRef } from 'react';
import { Box, Flex, Text } from '@/components/primitives';
import { Thumbnail } from '@/components/Thumbnail';
import { getColorToken } from '@/css/utils';
import type { Image } from '@/types';
import { getImageUrl } from '@/utils/getImageUrl';

type ComboboxOption = {
  id: string;
  thumbnail: Image | null;
  title: string;
};

type Props = Record<string, unknown> & {
  isHighlighted: boolean;
  option: ComboboxOption;
};

const boxStyle = { transition: 'background-color 150ms ease-out' };

export const ContentComboboxOption = forwardRef<HTMLDivElement, Props>(
  ({ children, isHighlighted, option, ...props }, ref) => {
    // Check if the title contains html
    const label = /<\/?[a-z][\s\S]*>/i.test(option.title) ? (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={{ __html: option.title }} />
    ) : (
      option.title
    );

    const bgColor = isHighlighted ? getColorToken('neutral100') : 'transparent';

    return (
      <Box ref={ref} bgColor={bgColor} css={boxStyle} {...props}>
        <Flex alignItems="center" p="s02">
          <Thumbnail imageUrl={getImageUrl(option.thumbnail, { mode: 'crop', w: 40, h: 40 })} />
          <Box mx="s02">
            <Text fontSize="md" fontWeight="semibold" lineHeight="tight">
              {label}
            </Text>
            {children}
          </Box>
        </Flex>
      </Box>
    );
  }
);
