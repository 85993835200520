import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { parseMember } from '@/utils/api/parsers';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, userInviteLinkKeys } from '../utils';
import { GetUserInviteLink, type GetUserInviteLinkQuery } from './graphql/GetUserInviteLink.gql';

export function getUserInviteLinkQueryOptions(params: Params<'inviteLinkId'>) {
  return queryOptions({
    queryKey: userInviteLinkKeys.userInviteLinkById(getRouteParam(params.inviteLinkId)),
    queryFn: ({ queryKey: [{ inviteLinkId }] }) => fetchDocument(GetUserInviteLink, { inviteLinkId }), // prettier-ignore
    staleTime: LOADER_STALE_TIME
  });
}

export function userInviteLinkLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getUserInviteLinkQueryOptions(params));
}

export function parseData(data: GetUserInviteLinkQuery | undefined) {
  const userInviteLink = data?.userInviteLink;
  const viewer = data?.sessionUser;

  return {
    userInviteLink: userInviteLink
      ? { ...userInviteLink, groups: userInviteLink.groups.map(parseMember) }
      : userInviteLink,
    viewer: {
      canChangeUserInviteLinkStatus: Boolean(viewer?.canEditInviteLink),
      canEditUserInviteLink: Boolean(viewer?.canEditInviteLink),
      canDeleteUserInviteLink: Boolean(viewer?.canDeleteInviteLink) && userInviteLink?.usage === 0
    }
  };
}
