import type { MouseEventHandler, ReactNode } from 'react';
import type { To } from 'react-router-dom';
import { css } from '@emotion/react';
import { Link } from '@/components/Link';

type Props = {
  children: ReactNode;
  isSelected: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  to: To;
};

const linkStyle = css({
  flex: 1,
  whiteSpace: 'nowrap'
});

export const DashboardFilterListItemLink = ({ children, isSelected, onClick, to }: Props) => (
  <Link
    aria-current={isSelected}
    color={isSelected ? 'textDark' : 'textLight'}
    css={linkStyle}
    fontSize="md"
    fontWeight={isSelected ? 'semibold' : 'normal'}
    hoverColor="textDark"
    onClick={onClick}
    overflow="hidden"
    textOverflow="ellipsis"
    to={to}
    variant="no-underline"
  >
    {children}
  </Link>
);
