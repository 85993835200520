import { lazy, Suspense } from 'react';
import { Spacer, Text } from '@/components/primitives';
import type { UserNotAuthorizedError } from '@/utils/errors';
import { useToggle } from '@/utils/hooks/useToggle';
import { Alert } from '../Alert';
import { Button } from '../Button';
import { useI18n } from '../I18n';

const CurrentUserBlockingUserAgreementsForSubjectModal = lazy(async () => ({
  default: (await import('@/features/user-agreements/main/components/CurrentUserBlockingUserAgreementsForSubjectModal')).CurrentUserBlockingUserAgreementsForSubjectModal // prettier-ignore
}));

export const UserNotAuthorizedErrorAlert = ({ error }: { error: UserNotAuthorizedError }) => {
  const i18n = useI18n();
  const [isVisible, { on: showModal, off: hideModal }] = useToggle();

  const heading =
    error.operationType === 'mutation'
      ? i18n.t('common', 'resourceError.userNotAuthorized.text_action')
      : i18n.t('common', 'resourceError.userNotAuthorized.text');

  return (
    <>
      <Suspense fallback={null}>
        {isVisible && (
          <CurrentUserBlockingUserAgreementsForSubjectModal
            headingText={
              <Text color="textLight" fontSize="md" lineHeight="snug">
                {i18n.t('common', 'resourceError.userNotAuthorized.userAgreementList.heading')}
              </Text>
            }
            onRequestClose={hideModal}
            onSubmissionCompleted={hideModal}
            subjectId={error.resourceId}
          />
        )}
      </Suspense>
      <Alert role="alert" swatchColor="red">
        <Text fontSize="md" fontWeight="semibold">
          {heading}
        </Text>
        <Spacer mb="s03" />
        <Button onClick={showModal} type="button" variant="primary">
          {i18n.t('common', 'resourceError.userNotAuthorized.showUserAgreementsButton.text')}
        </Button>
      </Alert>
    </>
  );
};
