import { useI18n } from '@/components/I18n';
import { Spacer } from '@/components/primitives';
import { setStatus } from '@/utils/setA11yStatus';
import {
  type ContributionVoteHandler,
  getAccessibleVotingStatusText
} from '../../hooks/useContributionVoting';
import { ContributionCardVote } from './ContributionCardVote';

export type { ContributionVoteHandler };

type Props = {
  allowVoting?: boolean;
  contributionId: string;
  downvoteCount: number;
  userVote: Api.VoteType | null;
  onVote: ContributionVoteHandler;
  showDownvote?: boolean;
  upvoteCount: number;
};

export const ContributionCardVoting = ({
  allowVoting = false,
  contributionId,
  downvoteCount,
  userVote,
  onVote,
  showDownvote = false,
  upvoteCount
}: Props) => {
  const i18n = useI18n();

  async function onDownvoteClick() {
    const nextVote = await onVote(contributionId, userVote === 'NEGATIVE' ? 'undo' : 'downvote');
    setStatus(getAccessibleVotingStatusText(i18n, nextVote, userVote));
  }

  async function onUpvoteClick() {
    const nextVote = await onVote(contributionId, userVote === 'POSITIVE' ? 'undo' : 'upvote');
    setStatus(getAccessibleVotingStatusText(i18n, nextVote, userVote));
  }

  const upvoteText =
    userVote === 'POSITIVE'
      ? i18n.t('main', 'voteResults.upvoteResult.helpText_includeSelf', { count: upvoteCount })
      : i18n.t('main', 'voteResults.upvoteResult.helpText', { count: upvoteCount });

  const downvoteText =
    userVote === 'NEGATIVE'
      ? i18n.t('main', 'voteResults.downvoteResult.helpText_includeSelf', { count: downvoteCount })
      : i18n.t('main', 'voteResults.downvoteResult.helpText', { count: downvoteCount });

  return (
    <>
      <ContributionCardVote
        allowVoting={allowVoting}
        count={upvoteCount}
        isActive={userVote === 'POSITIVE'}
        label={upvoteText}
        mode="up"
        onClick={onUpvoteClick}
      />
      {showDownvote && (
        <>
          <Spacer mr="s02" />
          <ContributionCardVote
            allowVoting={allowVoting}
            count={downvoteCount}
            isActive={userVote === 'NEGATIVE'}
            label={downvoteText}
            mode="down"
            onClick={onDownvoteClick}
          />
        </>
      )}
    </>
  );
};
