import { Trans, type TransProps } from 'react-i18next';
import type { Namespace, ResourceKey } from './types';
import { useI18n } from './useI18n';

export type I18nTransProps<NS extends Namespace> = Omit<
  TransProps<any>,
  'components' | 'i18n' | 'i18nKey' | 'ns' | 't'
> & {
  components: Record<string, JSX.Element | undefined>;
  count?: number;
  ns: NS;
  i18nKey: ResourceKey<NS>;
};

export const I18nTrans = <NS extends Namespace>({
  components,
  ns,
  i18nKey,
  ...props
}: I18nTransProps<NS>) => {
  const i18n = useI18n();

  return (
    <Trans
      {...props}
      components={components as Record<string, JSX.Element>}
      i18n={i18n.i18nClient}
      i18nKey={`${ns}:${i18nKey}`}
    />
  );
};
