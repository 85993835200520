import type { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { CloseButton } from '@/components/CloseButton';
import { Heading } from '@/components/Heading';
import { useI18n } from '@/components/I18n';
import { Flex, Spacer, Text } from '@/components/primitives';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { hasValue } from '@/utils/common';

type Props = {
  accessibleItemCountText?: string;
  children: ReactNode;
  id: string;
  itemCount?: number;
  onCloseClick?: (event: KeyboardEvent | MouseEvent) => void;
};

export const GridModalHeader = ({
  children,
  accessibleItemCountText,
  id,
  itemCount,
  onCloseClick
}: Props) => {
  const i18n = useI18n();

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      px={{ _: 's04', sm: 's06', md: 's06', lg: 's08', xl: 's12' }}
      py={{ _: 's04', sm: 's06', lg: 's08' }}
    >
      <Flex alignItems="baseline">
        <Heading id={id} variant="heading.700">
          {children}
        </Heading>
        {accessibleItemCountText && <VisuallyHidden>{accessibleItemCountText}</VisuallyHidden>}
        {hasValue(itemCount) && (
          <Flex alignItems="baseline" aria-hidden>
            <Spacer mr="s02" />
            <Text color="grey400" fontSize="3xl" fontWeight="normal" lineHeight="none">
              &ndash;
            </Text>
            <Spacer mr="s02" />
            <Text color="textLight" fontSize="2xl" fontWeight="semibold" lineHeight="none">
              {itemCount}
            </Text>
          </Flex>
        )}
      </Flex>
      {onCloseClick && (
        <CloseButton
          aria-label={i18n.t('common', 'modalWindow.closeButton.text')}
          hoverColor="danger"
          onClick={onCloseClick}
          size="xl"
        />
      )}
    </Flex>
  );
};
