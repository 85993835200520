import type ApolloClient from 'apollo-client';

function loadAddDownvoteMutation() {
  return import('./AddDownvote.gql').then(module => module.AddDownvote);
}

function loadAddUpvoteMutation() {
  return import('./AddUpvote.gql').then(module => module.AddUpvote);
}

function loadUndoVoteMutation() {
  return import('./UndoVote.gql').then(module => module.UndoVote);
}

type ContributionVotingService = {
  addDownvote: (contributionId: string) => Promise<void>;
  addUpvote: (contributionId: string) => Promise<void>;
  undoVote: (contributionId: string) => Promise<void>;
};

const noop = () => {};

export function createContributionVotingService(
  client: ApolloClient<object>
): ContributionVotingService {
  return {
    addDownvote: contributionId =>
      loadAddDownvoteMutation()
        .then(mutation => client.mutate({ mutation, variables: { input: { contributionId } } }))
        .then(noop),
    addUpvote: contributionId =>
      loadAddUpvoteMutation()
        .then(mutation => client.mutate({ mutation, variables: { input: { contributionId } } }))
        .then(noop),
    undoVote: contributionId =>
      loadUndoVoteMutation()
        .then(mutation => client.mutate({ mutation, variables: { input: { contributionId } } }))
        .then(noop)
  };
}
