import { forwardRef } from 'react';
import { Card, Text } from '@/components/primitives';
import { getAvatarColor, getFullName, getInitials } from '@/utils/user';
import type { UserAvatarSize } from './types';
import { mapSizeToFontSize, mapSizeToRem } from './utils';

export type UserAvatarProps = {
  imageUrl?: string | null; // Temporary prop
  size?: UserAvatarSize;
  user: User;
};

export type User = {
  // avatarUrl: string | null;   TODO: Waiting for API changes
  id: string;
  firstName: string | null;
  lastName: string | null;
};

// TODO: Sort of duplicated in ProfileAvatar, consider extracting a component.
export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ imageUrl, user, size = 'md' }, ref) => (
    <Card
      ref={ref}
      aria-label={getFullName(user)}
      bgColor={!imageUrl ? getAvatarColor(user.id) : 'neutral200'}
      border={1}
      borderColor="white"
      borderRadius="full"
      css={{
        alignItems: 'center',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
        justifyContent: 'center'
      }}
      display="flex"
      height={mapSizeToRem(size)}
      overflow="hidden"
      role="img"
      width={mapSizeToRem(size)}
    >
      {!imageUrl && (
        <Text
          aria-hidden
          color="white"
          fontSize={mapSizeToFontSize(size)}
          fontWeight="semibold"
          textStyle="uppercase"
        >
          {getInitials(user)}
        </Text>
      )}
    </Card>
  )
);
