import type { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import { type Location, NavLink, type To, useLocation } from 'react-router-dom';
import { Icon, type IconType } from '@/components/Icon';
import { NotificationIcon } from '@/components/NotificationIcon';
import { Box, Flex, Text } from '@/components/primitives';
import { UnstyledButton } from '@/components/UnstyledButton';
import { hasValue } from '@/utils/common';
import { Link } from '../Link';
import * as css from './SidebarNavItem.styles';

export type MenuItemConfig = {
  exact?: boolean;
  icon: IconType;
  isActive?: boolean | ((location: Location) => boolean);
  label: string;
  onClick?: MouseEventHandler;
  showNotification?: boolean;
} & (
  | {
      to?: never;
      type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
    }
  | {
      to?: To;
      type?: never;
    }
);

export const SidebarNavItem = ({
  exact,
  icon,
  isActive: _isActive,
  label,
  onClick,
  showNotification,
  to,
  type
}: MenuItemConfig) => {
  const location = useLocation();

  const item = (
    <Flex alignItems="center" px="s08" py="s03">
      <Icon aria-hidden size={22} type={icon} />
      <Flex alignItems="center" flex={1} justifyContent="space-between" ml="s04">
        <Text fontSize="md" lineHeight="normal">
          {label}
        </Text>
        {showNotification && (
          <Box ml="s01">
            <NotificationIcon />
          </Box>
        )}
      </Flex>
    </Flex>
  );

  if (hasValue(to)) {
    if (hasValue(_isActive)) {
      const isActive = typeof _isActive === 'function' ? _isActive(location) : _isActive;

      return (
        <Link
          aria-current={isActive ? 'page' : undefined}
          css={isActive ? css.activeLink : css.link}
          onClick={onClick}
          to={to}
        >
          {item}
        </Link>
      );
    }

    return (
      <NavLink css={css.navLink} end={exact} onClick={onClick} to={to}>
        {item}
      </NavLink>
    );
  }

  return (
    <UnstyledButton css={css.navLink} onClick={onClick} type={type}>
      {item}
    </UnstyledButton>
  );
};
