import { Heading } from '@/components/Heading';
import { useI18n } from '@/components/I18n';
import { Link } from '@/components/Link';
import { Card, Flex, Spacer, Text } from '@/components/primitives';
import { Screen } from '@/components/Screen';
import { SkipNavTarget } from '@/components/SkipNav';
import { Page } from '@/pages/Page';
import { useId } from '@/utils/hooks/useId';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';

const PageNotFound = () => {
  const i18n = useI18n();
  const { getHomePage } = useSharedLocations('main');

  const pageId = useId('page');
  const headingId = `${pageId}-heading`;
  const descriptionId = `${pageId}-description`;

  return (
    <Flex alignItems="baseline" flex={1} justifyContent="center" px="s06" py="s32">
      <Card
        aria-describedby={descriptionId}
        aria-labelledby={headingId}
        bgColor="white"
        borderRadius="md"
        boxShadow="base"
        maxWidth={680}
        px="s10"
        py="s10"
        role="alert"
      >
        <Heading id={headingId} mb="s06">
          {i18n.t('common', 'pageNotFound.heading')}
        </Heading>
        <Text as="p" color="textLight" id={descriptionId}>
          {i18n.t('common', 'pageNotFound.description_part1')}
          <br />
          {i18n.t('common', 'pageNotFound.description_part2')}
        </Text>
        <Spacer mb="s04" />
        <Link to={getHomePage()}>{i18n.t('common', 'pageNotFound.goToHomeLink.text')}</Link>
      </Card>
    </Flex>
  );
};

export const NotFoundPage = () => {
  const title = 'Page not found';

  return (
    <Page id="notFound" title={title}>
      <Screen title={title}>
        <SkipNavTarget role="main">
          <PageNotFound />
        </SkipNavTarget>
      </Screen>
    </Page>
  );
};
