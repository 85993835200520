import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type {
  FetchInfiniteQueryOptions,
  FetchQueryOptions,
  QueryKey as RQQueryKey,
  UseMutationOptions
} from '@tanstack/react-query';
import { useMutation as useRQMutation } from '@tanstack/react-query';
import type { RequestError } from '@/utils/api/error';
import { fetchDocument } from '@/utils/api/fetcher';
import type { GetUserImportsQueryVariables } from './admin._user-management.user-imports/graphql/GetUserImports.gql';
import type { GetUserInviteLinkUsersQueryVariables } from './admin._user-management.user-invite-links.$inviteLinkId.users/graphql/GetUserInviteLinkUsers.gql';
import type { GetUserInviteLinksQueryVariables } from './admin._user-management.user-invite-links/graphql/GetUserInviteLinks.gql';
import type { GetUserRemovalRequestsQueryVariables } from './admin._user-management.user-removal-requests/graphql/GetUserRemovalRequests.gql';
import type { GetUsersQueryVariables } from './admin._user-management.users/graphql/GetUsers.gql';
import type { GetCommunitiesQueryVariables } from './admin.communities/graphql/GetCommunities.gql';
import type { GetCommunityProgramsQueryVariables } from './admin.communities_.$communityId.$/graphql/GetCommunityPrograms.gql';
import type { GetFieldTypesQueryVariables } from './admin.field-types/graphql/GetFieldTypes.gql';
import type { GetRolesQueryVariables } from './admin.roles/graphql/GetRoles.gql';
import type { GetUserClaimSettingsQueryVariables } from './admin.user-claims/graphql/GetUserClaimSettings.gql';
import type { GetUserGroupsQueryVariables } from './admin.user-groups/graphql/GetUserGroups.gql';

/**
 * Since we're not using suspense yet we need to manually set a stale time in
 * order to prevent a double fetch.
 */
export const LOADER_STALE_TIME = 1000 * 5;

export function queryOptions<
  const TQueryKey extends RQQueryKey,
  TQueryFnData = unknown,
  TError = RequestError,
  TData = TQueryFnData
>(options: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { queryKey: TQueryKey }) {
  return options;
}

export function infiniteQueryOptions<
  const TQueryKey extends RQQueryKey,
  TQueryFnData = unknown,
  TError = RequestError,
  TData = TQueryFnData
>(
  options: FetchInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    queryKey: TQueryKey;
  }
) {
  return options;
}

export function useGraphQLMutation<
  TData,
  TVariables,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  TInputVariables = TVariables extends Api.Exact<{ [key: string]: never }> ? void : TVariables
>(
  document: TypedDocumentNode<TData, TVariables>,
  options?: UseMutationOptions<TData, RequestError, TInputVariables>
) {
  return useRQMutation<TData, RequestError, TInputVariables>({
    // @ts-expect-error
    mutationFn: variables => fetchDocument(document, variables),
    ...options
  });
}

type RootQueryKey<S extends string> = readonly [{ $$scope: S }];

type QueryKey<S extends string, Rest extends { [key: string]: unknown }> = readonly [
  Rest & RootQueryKey<S>[0]
];

export const rootQueryKey = <S extends string>(scope: S): RootQueryKey<S> => [{ $$scope: scope }];

export const queryKey = <
  S extends string,
  const R0 extends { [key: string]: unknown },
  const R1 extends { entity?: string; [key: string]: unknown }
>(
  key: QueryKey<S, R0>,
  rest: R1
): QueryKey<S, R0 & R1> => [{ ...key[0], ...rest }];

/**
 * @TODO Find a better location for these keys.
 */

export const userKeys = {
  all: rootQueryKey('admin/users'),
  list_all: () => queryKey(userKeys.all, { entity: 'list' }),
  list: (variables: GetUsersQueryVariables) => queryKey(userKeys.list_all(), { variables }),
  user_add: () => queryKey(userKeys.all, { entity: 'form/add' }),
  userById: (userId: string) => queryKey(userKeys.all, { userId }),
  userById_edit: (userId: string) => queryKey(userKeys.userById(userId), { entity: 'form/edit' })
};

export const userGroupKeys = {
  all: rootQueryKey('admin/user-groups'),
  list_all: () => queryKey(userGroupKeys.all, { entity: 'list' }),
  list: (variables: GetUserGroupsQueryVariables) => queryKey(userGroupKeys.list_all(), { variables }), // prettier-ignore
  group_add: () => queryKey(userGroupKeys.all, { entity: 'form/add' }),
  groupById: (userGroupId: string) => queryKey(userGroupKeys.all, { userGroupId }),
  groupById_edit: (userGroupId: string) => queryKey(userGroupKeys.groupById(userGroupId), { entity: 'form/edit' }) // prettier-ignore
};

export const userImportKeys = {
  all: rootQueryKey('admin/user-imports'),
  list_all: () => queryKey(userImportKeys.all, { entity: 'list' }),
  list: (variables: GetUserImportsQueryVariables) => queryKey(userImportKeys.list_all(), { variables }), // prettier-ignore
  userImport_add: (importId: string) => queryKey(userImportKeys.all, { entity: 'form/add', importId }) // prettier-ignore
};

export const userRemovalRequestKeys = {
  all: rootQueryKey('admin/user-removal-requests'),
  list_all: () => queryKey(userRemovalRequestKeys.all, { entity: 'list' }),
  list: (variables: GetUserRemovalRequestsQueryVariables) => queryKey(userRemovalRequestKeys.list_all(), { variables }), // prettier-ignore
  request_add: () => queryKey(userRemovalRequestKeys.all, { entity: 'form/add' }),
  requestById: (requestId: string) => queryKey(userRemovalRequestKeys.all, { requestId })
};

export const userInviteLinkKeys = {
  all: rootQueryKey('admin/user-invite-links'),
  list_all: () => queryKey(userInviteLinkKeys.all, { entity: 'list' }),
  list: (variables: GetUserInviteLinksQueryVariables) => queryKey(userInviteLinkKeys.list_all(), { variables }), // prettier-ignore
  userInviteLinkById: (inviteLinkId: string) => queryKey(userInviteLinkKeys.all, { inviteLinkId }),
  userInviteLinkById_users: (
    inviteLinkId: string,
    variables: Omit<GetUserInviteLinkUsersQueryVariables, 'inviteLinkId' | 'page'>
  ) => queryKey(userInviteLinkKeys.userInviteLinkById(inviteLinkId), { entity: 'users', variables })
};

export const userRegistrationSettingsKeys = {
  all: rootQueryKey('admin/user-registration')
};

export const userClaimKeys = {
  all: rootQueryKey('admin/user-claims'),
  list_all: () => queryKey(userClaimKeys.all, { entity: 'list' }),
  list: (variables: GetUserClaimSettingsQueryVariables) => queryKey(userClaimKeys.list_all(), { variables }), // prettier-ignore
  userClaim_add: () => queryKey(userClaimKeys.all, { entity: 'form/add' }),
  userClaimById: (userClaimId: string) => queryKey(userClaimKeys.all, { userClaimId }),
  userClaimById_edit: (userClaimId: string) => queryKey(userClaimKeys.userClaimById(userClaimId), { entity: 'form/edit' }), // prettier-ignore
  identityProviderById: (identityProviderId: string) => queryKey(userClaimKeys.all, { identityProviderId }), // prettier-ignore
  identityProviderById_edit: (identityProviderId: string) => queryKey(userClaimKeys.identityProviderById(identityProviderId), { entity: 'provider/form/edit' }) // prettier-ignore
};

export const roleKeys = {
  all: rootQueryKey('admin/roles'),
  list_all: () => queryKey(roleKeys.all, { entity: 'list' }),
  list: (variables: GetRolesQueryVariables) => queryKey(roleKeys.list_all(), { variables }),
  role_add: () => queryKey(roleKeys.all, { entity: 'form/add' }),
  roleById: (roleId: string) => queryKey(roleKeys.all, { roleId }),
  roleById_edit: (roleId: string) => queryKey(roleKeys.roleById(roleId), { entity: 'form/edit' })
};

export const communityKeys = {
  all: rootQueryKey('admin/communities'),
  list_all: () => queryKey(communityKeys.all, { entity: 'list' }),
  list: (variables: GetCommunitiesQueryVariables) => queryKey(communityKeys.list_all(), { variables }), // prettier-ignore
  community_add: () => queryKey(communityKeys.all, { entity: 'form/add' }),
  communityById: (communityId: string) => queryKey(communityKeys.all, { communityId }),
  communityById_edit: (communityId: string) => queryKey(communityKeys.communityById(communityId), { entity: 'form/edit' }), // prettier-ignore
  programsList_all: (communityId: string) => queryKey(communityKeys.all, { communityId, entity: 'programsList' }), // prettier-ignore
  programsList: (
    communityId: string,
    variables: Omit<GetCommunityProgramsQueryVariables, 'communityId'>
  ) => queryKey(communityKeys.programsList_all(communityId), { variables, entity: 'programsList' })
};

export const programKeys = {
  all: rootQueryKey('admin/programs'),
  program_add: () => queryKey(programKeys.all, { entity: 'form/add' })
};

export const fieldTypeKeys = {
  all: rootQueryKey('admin/field-types'),
  list_all: () => queryKey(fieldTypeKeys.all, { entity: 'list' }),
  list: (variables: GetFieldTypesQueryVariables) => queryKey(fieldTypeKeys.list_all(), { variables }), // prettier-ignore
  fieldType_add: () => queryKey(fieldTypeKeys.all, { entity: 'form/add' }),
  fieldTypeById: (fieldTypeId: string) => queryKey(fieldTypeKeys.all, { fieldTypeId }),
  fieldTypeById_edit: (fieldTypeId: string) => queryKey(fieldTypeKeys.fieldTypeById(fieldTypeId), { entity: 'form/edit' }) // prettier-ignore
};
