import { css, keyframes } from '@emotion/react';
import { Flex } from '@/components/primitives';
import { DonutSpinner, type DonutSpinnerProps } from './DonutSpinner';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const containerStyle = css({ animation: `${fadeIn} 0.25s ease-in` });

export const DonutLoader = ({
  chipColor = '#8385aa',
  size = '42px',
  width = '6px'
}: Partial<DonutSpinnerProps>) => (
  <Flex css={containerStyle} data-testid="loader" justifyContent="center">
    <DonutSpinner
      animationSpeed="1.2s"
      borderColor="rgba(0, 0, 0, 0.1)"
      chipColor={chipColor}
      size={size}
      width={width}
    />
  </Flex>
);
