import { type DependencyList, type EffectCallback, useEffect, useRef } from 'react';

/**
 * Taken from https://github.com/chancestrickland/hooks/blob/master/src/use-update-effect.ts
 */

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const mounted = useRef(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mounted.current) return effect();
    mounted.current = true;
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
