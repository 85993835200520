import { initReactI18next } from 'react-i18next';
import i18n, { type BackendModule } from 'i18next';
import { useAppConfigStore } from '@/components/AppConfig';
import type { Namespace } from '@/components/I18n/types';
import { isSupportedLanguage } from '@/components/I18n/utils';
import { DEFAULT_USER_LANGUAGE, IS_DEBUG } from '@/constants';
import SUPPORTED_LANGUAGES from '../locales/supported_languages.json';

// Loosely based on https://github.com/dotcore64/i18next-fetch-backend
const FetchBackendPlugin: BackendModule = {
  init: () => {},
  // TODO: Jargon variants should also be in the Namespace type
  // eslint-disable-next-line @typescript-eslint/ban-types
  read: async (lang, requestedNs: Namespace, callback) => {
    try {
      let ns: string = requestedNs;

      if (requestedNs === 'contribution') {
        const value = useAppConfigStore.getState().config.jargon.contribution;

        if (value === 'CONTRIBUTION') {
          ns = 'jargon/contribution';
        } else {
          ns = `jargon/contribution(${value.toLowerCase()})`;
        }
      } else if (requestedNs === 'challenge') {
        const value = useAppConfigStore.getState().config.jargon.ideationModule;

        if (value === 'CHALLENGE') {
          ns = 'jargon/challenge';
        } else {
          ns = `jargon/challenge(${value.toLowerCase()})`;
        }
      }

      const response = await fetch(`/locales/${lang}/${ns}.json`, { credentials: 'include' });

      if (!response.ok) {
        const retry = response.status >= 500 && response.status < 600; // don't retry for 4xx codes
        callback(`Error fetching language "${lang}", namespace "${requestedNs}"`, retry);
      }

      try {
        callback(null, await response.json());
      } catch (err) {
        callback(`Error parsing language "${lang}", namespace "${requestedNs}"`, false);
      }
    } catch (err) {
      if (err instanceof Error) {
        callback(err, false);
      } else {
        callback(`Error fetching language "${lang}", namespace "${requestedNs}"`, false);
      }
    }
  },
  type: 'backend'
};

const supportedLanguages = Object.keys(SUPPORTED_LANGUAGES);
const namespaces: Namespace[] = ['challenge', 'contribution', 'common', 'main', 'management'];

export async function createI18nClient(language: string) {
  await i18n
    .use(initReactI18next)
    .use(FetchBackendPlugin)
    .init(
      {
        appendNamespaceToMissingKey: true,
        compatibilityJSON: 'v4',
        debug: IS_DEBUG,
        fallbackLng: DEFAULT_USER_LANGUAGE,
        interpolation: {
          escapeValue: false,
          skipOnVariables: true
        },
        keySeparator: false,
        lng: isSupportedLanguage(language) ? language : 'en',
        ns: namespaces,
        react: {
          bindI18n: 'languageChanged',
          bindI18nStore: false
        },
        saveMissing: false,
        supportedLngs:
          DEFAULT_USER_LANGUAGE === '__' ? supportedLanguages.concat('__') : supportedLanguages
      },
      err => {
        if (err) console.error(err); // eslint-disable-line no-console
      }
    );

  return i18n;
}
