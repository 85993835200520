import type { I18n } from '@/components/I18n';
import { parseMemberOption } from '@/components/PeoplePicker/helpers';
import type { ChoiceOption } from '@/types';
import { exhaustiveCheck } from '@/types/utils';
import type { TaskSettingsFragment } from './graphql/fragments/TaskSettingsFragment.gql';
import type { CommonTaskValues, TaskValues } from './types';

export function getTaskAssignmentModeOptions(i18n: I18n): ChoiceOption<Api.TaskAssignmentMode>[] {
  return [
    {
      label: i18n.t(
        'contribution',
        'taskConfiguration.fields.assignmentMode.values.allContributions.label'
      ),
      value: 'ALL_CONTRIBUTIONS'
    },
    {
      label: i18n.t(
        'contribution',
        'taskConfiguration.fields.assignmentMode.values.ownedContributions.label'
      ),
      value: 'OWNED_CONTRIBUTIONS'
    },
    {
      label: i18n.t(
        'contribution',
        'taskConfiguration.fields.assignmentMode.values.unownedContributions.label'
      ),
      value: 'UNOWNED_CONTRIBUTIONS'
    }
  ];
}

export function getTaskModeLabelByTaskMode(i18n: I18n, mode: Api.TaskMode): string {
  let label: string;

  if (mode === 'CONTINUOUS') {
    label = i18n.t('management', 'taskConfiguration.fields.taskMode.values.continuous.label');
  } else if (mode === 'SPRINT') {
    label = i18n.t('management', 'taskConfiguration.fields.taskMode.values.sprint.label');
  } else {
    label = i18n.t('management', 'taskConfiguration.fields.taskMode.label');
  }

  return label;
}

export function getLabelByTaskType(i18n: I18n, type: Api.TaskType): string {
  let label: string;

  if (type === 'REVIEW') {
    label = i18n.t('main', 'task.type.text_review');
  } else if (type === 'ENRICHMENT') {
    label = i18n.t('main', 'task.type.text_enrichment');
  } else if (type === 'ENRICHMENT_APPROVAL') {
    label = i18n.t('main', 'task.type.text_enrichmentApproval');
  } else {
    label = i18n.t('main', 'task.type.text');
    exhaustiveCheck(type);
  }

  return label;
}

export function getTaskProgressText(
  i18n: I18n,
  completedCount: number,
  totalCount: number,
  type: Api.TaskType
): string {
  const i18nOpts = { completedCount, count: totalCount };
  let value: string;

  if (type === 'ENRICHMENT') {
    value = i18n.t('contribution', 'task.progress.text_enrichment', i18nOpts);
  } else if (type === 'ENRICHMENT_APPROVAL') {
    value = i18n.t('main', 'task.progress.text_enrichmentApproval', i18nOpts);
  } else if (type === 'REVIEW') {
    value = i18n.t('contribution', 'task.progress.text_review', i18nOpts);
  } else {
    value = i18n.t('main', 'task.progress.text', i18nOpts);
    exhaustiveCheck(type);
  }

  return value;
}

export function parseTaskSettingsFragment(
  data: TaskSettingsFragment,
  taskType: Api.TaskType
): TaskValues {
  const baseValues: CommonTaskValues = {
    assignees: data.assignees.map(member => parseMemberOption(member)),
    isEnabled: data.isEnabled,
    randomizeTasks: data.randomizeTasks,
    reminderPeriod: data.reminderPeriod,
    sendMail: data.sendMail,
    sendReminder: data.sendReminder,
    startDate: data.startDate,
    taskAssignmentMode: data.taskAssignmentMode,
    taskType
  };

  let taskValues: TaskValues;

  if (data.__typename === 'ContinuousTaskSettings') {
    taskValues = {
      ...baseValues,
      taskMode: 'CONTINUOUS',
      deadline: data.deadline,
      minimumWindow: data.minimumWindow,
      taskManagers: data.taskManagers.map(member => parseMemberOption(member))
    };
  } else if (data.__typename === 'SprintTaskSettings') {
    taskValues = {
      ...baseValues,
      taskMode: 'SPRINT',
      lastRunTime: data.lastRunTime,
      period: data.period
    };
  } else {
    exhaustiveCheck(data);
  }

  return taskValues;
}
