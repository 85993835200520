import { useI18n } from '@/components/I18n';
import { Icon } from '@/components/Icon';
import { NumberText } from '@/components/NumberText';
import { Flex, Spacer } from '@/components/primitives';
import { UserAvatarList } from '@/components/UserAvatarList';
import type { Contribution } from './ContributionCard';
import { ContributionCardMetaLayout } from './ContributionCardMetaLayout';
import { ContributionCardVoting, type ContributionVoteHandler } from './ContributionCardVoting';

export type { ContributionVoteHandler };

type Props = {
  contribution: Contribution;
  onVote: ContributionVoteHandler;
};

export const ContributionCardMeta = ({ contribution, onVote }: Props) => {
  const i18n = useI18n();

  const { creator, viewer } = contribution;
  const authors = contribution.inventors.length > 0 ? contribution.inventors : [creator];
  const allowVoting = viewer.canVote && contribution.isVotingAllowed;

  const inventors = (
    <UserAvatarList
      aria={{
        label:
          contribution.inventors.length > 0
            ? i18n.t('common', 'inventors.helpText')
            : i18n.t('common', 'creator.helpText')
      }}
      emptyText={i18n.t('main', 'contribution.inventorList.empty.text')}
      maxAvatars={2}
      size="sm"
      users={authors}
    />
  );

  const votes = (
    <ContributionCardVoting
      allowVoting={allowVoting}
      contributionId={contribution.id}
      downvoteCount={contribution.downvoteCount}
      // TODO: Remove the downvote functionality once we are really sure that we don't want it on the card
      // showDownvote={contribution.voteSettings.allowDownvotes}
      onVote={onVote}
      showDownvote={false}
      upvoteCount={contribution.upvoteCount}
      userVote={contribution.viewer.voteValue}
    />
  );

  const comments = (
    <Flex
      alignItems="center"
      title={i18n.t('main', 'contributionCard.commentCount.label', {
        count: contribution.commentCount
      })}
    >
      <Icon color="neutral400" size={20} type="chat-alt-2" />
      <Spacer mr="s01" />
      <NumberText
        aria-hidden
        color="text"
        fontSize="md"
        fontVariantNumeric="tabular-nums"
        fontWeight="semibold"
        lineHeight="none"
      >
        {contribution.commentCount}
      </NumberText>
    </Flex>
  );

  return (
    <ContributionCardMetaLayout
      aria-label={i18n.t('main', 'metaContent.helpText')}
      role="group"
      slots={{ comments, inventors, votes }}
    />
  );
};
