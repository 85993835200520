import { Badge, type BadgeProps } from '@/components/Badge';
import { useI18n } from '@/components/I18n';
import type { SwatchColor } from '@/css/types';
import { exhaustiveCheck } from '@/types/utils';

type Props = Pick<BadgeProps, 'fontSize'> & {
  level: Api.SimilarContentLevel;
};

export const SimilarContentBadge = ({ fontSize, level }: Props) => {
  const i18n = useI18n();

  let color: SwatchColor;
  let text: string;

  if (level === 'IDENTICAL') {
    color = 'neutral';
    text = i18n.t('main', 'similarContent.badge.text_identical');
  } else if (level === 'HIGH') {
    color = 'green';
    text = i18n.t('main', 'similarContent.badge.text_high');
  } else if (level === 'MEDIUM') {
    color = 'yellow';
    text = i18n.t('main', 'similarContent.badge.text_medium');
  } else if (level === 'LOW') {
    color = 'red';
    text = i18n.t('main', 'similarContent.badge.text_low');
  } else {
    text = level;
    exhaustiveCheck(level);
  }

  return (
    <Badge color={color} fontSize={fontSize}>
      {text}
    </Badge>
  );
};
