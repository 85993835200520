import type { MemberOption } from '@/components/PeoplePicker';
import type {
  FieldValueFragment,
  FieldValueFragment_BoolValue,
  FieldValueFragment_ChoiceListValue,
  FieldValueFragment_DateValue,
  FieldValueFragment_FileListValue,
  FieldValueFragment_MemberListValue,
  FieldValueFragment_NumberValue,
  FieldValueFragment_TextValue
} from '@/features/common/graphql/fragments/FieldValueFragment.gql';
import type { FileUnionFragment } from '@/features/common/graphql/fragments/FileUnionFragment.gql';
import type { MemberUnionFragment } from '@/features/common/graphql/fragments/MemberUnionFragment.gql';
import type { SliceFragment } from '@/features/common/graphql/fragments/SliceFragment.gql';
import type { SliceTemplateFieldFragment } from '@/features/common/graphql/fragments/SliceTemplateFieldFragment.gql';
import type { StreamingMediaFileFragment } from '@/features/common/graphql/fragments/StreamingMediaFileFragment.gql';
import type { PeoplePickerMode } from '@/features/forms/components/FormControl';
import type {
  FormField,
  FormFieldRule,
  FormSchema,
  FormValue,
  FormValues
} from '@/features/forms/types';
import {
  type BooleanFieldSettings,
  type BooleanSliceField,
  type ChoiceListFieldSettings,
  type ChoiceListSliceField,
  type ChoiceOption,
  type DateTimeFieldSettings,
  type DateTimeSliceField,
  type FieldData,
  type FieldDefinition,
  type FileListFieldSettings,
  type FileListSliceField,
  getValidInputType,
  type InputType,
  type MemberListFieldSettings,
  type MemberListSliceField,
  type MoneyFieldSettings,
  type MoneySliceField,
  type NumberFieldSettings,
  type NumberSliceField,
  type ParsedApiFile,
  type ParsedApiStreamingMediaFile,
  type ParsedFieldData,
  type ParsedFileUnion,
  type ParsedMember,
  type ParsedTextFieldData,
  type RatingFieldSettingsWithKeyValuePair,
  type RatingSliceField,
  type SliceField,
  type TextFieldData,
  type TextFieldSettings,
  type TextSliceField,
  type UnknownSliceField
} from '@/types';
import { exhaustiveCheck } from '@/types/utils';
import { hasNonEmptyStringValue, hasValue } from '@/utils/common';
import { getExtensionsByFileGroups } from '@/utils/file';
import { kBToBytes } from '@/utils/formatBytes';
import { parseNumberLike } from '@/utils/number';
import { getFullName } from '@/utils/user';

export const parseFile = (file: FileUnionFragment): ParsedApiFile => ({
  contentType: file.contentType ?? '',
  downloadUrl: file.downloadUrl,
  id: file.id,
  // isEncodable: getExtensionsByFileGroups(['VIDEO']).includes(file.contentType ?? ''),
  isError: false,
  isRejected: false,
  isUploaded: true,
  kind: 'file',
  lastModified:
    typeof file.dateCreated === 'string' ? new Date(file.dateCreated).getTime() / 1000 : null,
  lastModifiedDate: file.dateCreated,
  name: file.name ?? file.id,
  previewUrl: file.previewUrl || null,
  progress: -1,
  size: file.sizeInBytes,
  url: file.url
});

export const parseStreamingMediaFile = (
  file: StreamingMediaFileFragment
): ParsedApiStreamingMediaFile => ({
  ...parseFile(file),
  encoding: file.encoding,
  kind: 'streamingMediaFile'
});

export function parseFileUnionFragment(file: FileUnionFragment): ParsedFileUnion {
  return file.__typename === 'StreamingMediaFile' ? parseStreamingMediaFile(file) : parseFile(file);
}

/**
 * Transforms an `Api.MemberUnion` object to a `ParsedMember`.
 *
 * @TODO
 * BE needs to change this! MemberListValue should return a Member type
 * that wraps User/UserGroup/... and also contains a `type` field. Once we have
 * more group types we can't deduce the member type anymore based on the __typename.
 */
export function parseMember(member: MemberUnionFragment): ParsedMember {
  if (member.__typename === 'BaseUser') {
    return {
      id: member.id,
      avatar: member.avatar,
      firstName: member.firstName,
      isActive: member.isActive,
      lastName: member.lastName,
      name: getFullName(member),
      type: 'USER'
    };
  }

  if (member.__typename === 'BaseClientApplication') {
    return {
      description: member.description,
      id: member.id,
      name: member.name,
      type: 'CLIENT_APPLICATION'
    };
  }

  if (member.__typename === 'BaseUserGroup') {
    return {
      description: member.description,
      id: member.id,
      memberCount: member.memberCount,
      name: member.name,
      type: 'STATIC_USER_GROUP'
    };
  }

  return exhaustiveCheck(member, true);
}

/** @private */
export function getInputType(controlType: Api.ControlType): InputType {
  if (controlType === 'AUTO_COMPLETE') return 'MultiSelect';
  if (controlType === 'CHECKBOX_LIST') return 'CheckboxGroup';
  if (controlType === 'DATE_TIME_PICKER') return 'DateTimePicker';
  if (controlType === 'DROPDOWN_LIST') return 'Select';
  if (controlType === 'FILE_UPLOAD') return 'FileUpload';
  if (controlType === 'MONEY_INPUT') return 'MoneyInput';
  if (controlType === 'NUMBER_INPUT') return 'NumberInput';
  if (controlType === 'PEOPLE_PICKER') return 'PeoplePicker';
  if (controlType === 'RADIOBUTTON_LIST') return 'RadioButtonGroup';
  if (controlType === 'RATING') return 'Rating';
  if (controlType === 'RICH_TEXT_INPUT') return 'RichTextInput';
  if (controlType === 'SWITCH') return 'Switch';
  if (controlType === 'TEXT_INPUT') return 'TextInput';

  return exhaustiveCheck(controlType, true);
}

/** @private */
export function getFieldSchemaRules(def: FieldDefinition) {
  const rules: FormFieldRule[] = [];

  if (def.isRequired) {
    rules.push({ type: 'required' });
  }

  if (def.kind === 'Text' && hasValue(def.settings.limit) && def.settings.limit > 0) {
    rules.push({ limit: def.settings.limit, type: 'max' });
  }

  // TODO: This can be improved. MemberList & FileList cannot have imputType Select/RadioButtonGroup
  // for example.
  if (def.kind === 'MemberList' || def.kind === 'FileList' || def.kind === 'ChoiceList') {
    if (
      hasValue(def.settings.maxCount) &&
      def.settings.maxCount > 0 &&
      def.inputType !== 'Select' &&
      def.inputType !== 'RadioButtonGroup'
    ) {
      // TODO: Figure out why the hasValue is not doing its work here.
      rules.push({ limit: def.settings.maxCount, type: 'max' });
    }

    // In case of "Select" and "RadioButtonGroup" the data type is parsed as "string" and not
    // "array", since these are single value fields. Because of this the "min" rule used is wrong
    // since it's using the string validation instead of the array one. Since we can only select one
    // value anyway we're skipping the min rule here for now. Might change this in the future.
    if (
      def.inputType !== 'Select' &&
      def.inputType !== 'RadioButtonGroup' &&
      hasValue(def.settings.minCount)
    ) {
      rules.push({ limit: def.settings.minCount, type: 'min' });
    }
  }

  if (def.kind === 'Number' || def.kind === 'Money') {
    rules.push({
      limit: hasValue(def.settings.maxValue) ? def.settings.maxValue : Number.MAX_SAFE_INTEGER + 1,
      type: 'max'
    });

    rules.push({
      limit: hasValue(def.settings.minValue) ? def.settings.minValue : Number.MIN_SAFE_INTEGER + 1,
      type: 'min'
    });
  } else if (def.kind === 'DateTime') {
    if (def.settings.isFuture) {
      rules.push({ limit: new Date().toISOString(), type: 'min' });
    }

    if (hasValue(def.settings.maxDate)) {
      rules.push({ limit: def.settings.maxDate, type: 'max' });
    }

    if (hasValue(def.settings.minDate)) {
      rules.push({ limit: def.settings.minDate, type: 'min' });
    }
  } else if (def.kind === 'FileList') {
    const fileRules: FormFieldRule[] = [];

    if (hasValue(def.settings.maxFileSizeInKB) && def.settings.maxFileSizeInKB > 0) {
      fileRules.push({ limit: kBToBytes(def.settings.maxFileSizeInKB), type: 'maxSize' });
    }

    if (def.settings.extensions.length > 0) {
      fileRules.push({
        type: 'extension',
        values: getExtensionsByFileGroups(def.settings.extensions)
      });
    }

    rules.push({
      schema: { rules: fileRules, type: 'file' },
      type: 'of'
    });
  }

  return rules;
}

/** @private */
export function getFieldSchemaFromDefinition(definition: FieldDefinition): FormField {
  const schema: Partial<FormField> = {
    description: definition.description,
    inputType: definition.inputType,
    label: definition.title,
    rules: getFieldSchemaRules(definition)
  };

  if (definition.kind === 'ChoiceList') {
    schema.type =
      definition.inputType === 'Select' || definition.inputType === 'RadioButtonGroup'
        ? 'string'
        : 'array';
    schema.inputProps = {
      options: definition.settings.options,
      placeholder: definition.settings.placeholder ?? undefined
    };
  } else if (definition.kind === 'Boolean') {
    schema.type = 'boolean';
  } else if (definition.kind === 'DateTime') {
    schema.type = 'date';
    schema.inputProps = definition.settings;
  } else if (definition.kind === 'FileList') {
    schema.type = 'array';
  } else if (definition.kind === 'MemberList') {
    const { allowedMemberTypes } = definition.settings;
    let mode: PeoplePickerMode;

    if (allowedMemberTypes.includes('STATIC_USER_GROUP') && allowedMemberTypes.includes('USER')) {
      mode = 'USERS_AND_GROUPS';
    } else if (allowedMemberTypes.includes('STATIC_USER_GROUP')) {
      mode = 'ONLY_GROUPS';
    } else {
      mode = 'ONLY_USERS';
    }

    schema.type = 'array';
    schema.inputProps = { mode };
  } else if (definition.kind === 'Money') {
    schema.type = 'number';

    if (hasValue(definition.settings.currency)) {
      schema.inputProps = {
        currency: definition.settings.currency,
        type: 'text'
      };
    } else {
      schema.inputProps = { type: 'text' };
    }
  } else if (definition.kind === 'Number') {
    schema.type = 'number';
    schema.inputProps = {
      type: 'text'
    };
  } else if (definition.kind === 'Rating') {
    schema.type = 'number';
    schema.inputProps = {
      legend: definition.settings.legend.map(({ value }) => value)
    };
  } else if (definition.kind === 'Text') {
    // Look up any configured "max" rule
    const rule = schema.rules?.find(r => r.type === 'max');
    const max = rule?.type === 'max' && typeof rule.limit === 'number' ? rule.limit : null;

    schema.type = 'string';
    schema.inputProps = {
      // We don't want to forward "limit" because we already mapped it to a validation rule. If we
      // also pass it here we'll cut off text input at <limit> chars which is not desirable. We want
      // to allow "overflow", but validate and show an error instead.
      limit: max ? { max } : undefined,
      mode: definition.settings.mode,
      placeholder: definition.settings.placeholder ?? undefined
    };
  } else if (definition.kind === 'Unknown') {
    throw new Error('ERROR: Received an invalid field definition.');
  } else {
    exhaustiveCheck(definition, true);
  }

  return schema as FormField;
}

export function getFormSchemaFromFields(fields: SliceField[]): FormSchema {
  const schema: FormSchema = {};

  fields.forEach(({ definition }) => {
    schema[definition.id] = getFieldSchemaFromDefinition(definition);
  });

  return schema;
}

/** @private */
export const isBooleanValue = (value?: FieldValueFragment): value is FieldValueFragment_BoolValue =>
  hasValue(value) && value.__typename === 'BoolValue';

/** @private */
export const isChoiceListValue = (
  value?: FieldValueFragment
): value is FieldValueFragment_ChoiceListValue =>
  hasValue(value) && value.__typename === 'ChoiceListValue';

/** @private */
export const isDateTimeValue = (
  value?: FieldValueFragment
): value is FieldValueFragment_DateValue => hasValue(value) && value.__typename === 'DateValue';

/** @private */
export const isFileListValue = (
  value?: FieldValueFragment
): value is FieldValueFragment_FileListValue =>
  hasValue(value) && value.__typename === 'FileListValue';

/** @private */
export const isMemberListValue = (
  value?: FieldValueFragment
): value is FieldValueFragment_MemberListValue =>
  hasValue(value) && value.__typename === 'MemberListValue';

/** @private */
export const isMoneyValue = (value?: FieldValueFragment): value is FieldValueFragment_NumberValue =>
  hasValue(value) && value.__typename === 'NumberValue';

/** @private */
export const isNumberValue = (
  value?: FieldValueFragment
): value is FieldValueFragment_NumberValue => hasValue(value) && value.__typename === 'NumberValue';

/** @private */
export const isRatingValue = (
  value?: FieldValueFragment
): value is FieldValueFragment_NumberValue => hasValue(value) && value.__typename === 'NumberValue';

/** @private */
export const isTextValue = (value?: FieldValueFragment): value is FieldValueFragment_TextValue =>
  hasValue(value) && value.__typename === 'TextValue';

/** @private */
export function getBooleanValue(value?: FieldValueFragment): BooleanSliceField['value'] {
  return isBooleanValue(value) ? value.bool : false;
}

/** @private */
export function getChoiceListValue(fieldValue?: FieldValueFragment): ChoiceListSliceField['value'] {
  return isChoiceListValue(fieldValue) ? fieldValue.selectedChoices.map(c => c.key) : [];
}

/** @private */
export function getChoiceListValueOptions(
  fieldValue?: FieldValueFragment
): ChoiceListSliceField['valueOptions'] {
  return isChoiceListValue(fieldValue)
    ? fieldValue.selectedChoices.map(o => ({ label: o.value, value: o.key }))
    : [];
}

/** @private */
export function getDateTimeValue(value?: FieldValueFragment): DateTimeSliceField['value'] {
  return isDateTimeValue(value) ? value.date : '';
}

/** @private */
export function getFileListValue(value?: FieldValueFragment): FileListSliceField['value'] {
  return isFileListValue(value) ? value.fileMetadata.map(parseFileUnionFragment) : [];
}

/** @private */
export function getMemberListValue(value?: FieldValueFragment): MemberListSliceField['value'] {
  return isMemberListValue(value)
    ? value.members.map(member => ({
        isDisabled: false,
        member: parseMember(member),
        statusText: null
      }))
    : [];
}

/** @private */
export function getMoneyValue(value?: FieldValueFragment): MoneySliceField['value'] {
  return isMoneyValue(value) ? value.number : '';
}

/** @private */
export function getNumberValue(value?: FieldValueFragment): NumberSliceField['value'] {
  return isNumberValue(value) ? value.number : '';
}

/** @private */
export function getRatingValue(value?: FieldValueFragment): RatingSliceField['value'] {
  return isRatingValue(value) ? value.number : null;
}

/** @private */
export function getTextValue(value?: FieldValueFragment): TextSliceField['value'] {
  return (isTextValue(value) && value.text) || '';
}

/** @private */
export function isChoiceOptionList(values: FormValue): values is string[] {
  return Array.isArray(values) && values.every(value => typeof value === 'string');
}

export function getFieldValue(dataType: Api.DataTypeId, inputValue: FormValue) {
  let value: Api.FieldValueInputMap;

  if (dataType === 'BINARY') {
    value = { bool: typeof inputValue === 'boolean' ? inputValue : null };
  } else if (dataType === 'CHOICE_LIST') {
    if (isChoiceOptionList(inputValue)) {
      value = { list: inputValue };
    } else if (typeof inputValue === 'string' && hasNonEmptyStringValue(inputValue)) {
      value = { list: [inputValue] };
    } else {
      value = { list: null };
    }
  } else if (dataType === 'DATE_TIME') {
    value = { date: (typeof inputValue === 'string' && inputValue) || null };
  } else if (dataType === 'FILE_LIST') {
    if (typeof inputValue === 'string') {
      value = { files: [inputValue] };
    } else if (Array.isArray(inputValue)) {
      value = { files: (inputValue as ParsedFileUnion[]).map(file => file.id) };
    } else {
      value = { files: null };
    }
  } else if (dataType === 'MEMBER_LIST') {
    value = {
      members: Array.isArray(inputValue)
        ? (inputValue as MemberOption[]).map(({ member }) => ({
            id: member.id,
            memberType: member.type
          }))
        : null
    };
  } else if (dataType === 'MONEY' || dataType === 'NUMBER') {
    value = {
      number:
        typeof inputValue === 'string' || typeof inputValue === 'number'
          ? parseNumberLike(inputValue)
          : null
    };
  } else if (dataType === 'RATING') {
    value = { number: inputValue === 0 || typeof inputValue !== 'number' ? null : inputValue };
  } else if (dataType === 'TEXT') {
    value = { text: typeof inputValue === 'string' ? inputValue : null };
  } else if (dataType === 'UNKNOWN') {
    value = {};
  } else {
    exhaustiveCheck(dataType);
  }

  return value;
}

export type FieldValueInput = {
  sliceTemplateFieldId: string;
  value: Api.FieldValueInputMap;
};

export function transformFormValueToFieldValueInput(
  id: string,
  kind: FieldDefinition['kind'],
  formValue: FormValue
): FieldValueInput {
  let value: Api.FieldValueInputMap;

  if (kind === 'Boolean') {
    value = getFieldValue('BINARY', formValue);
  } else if (kind === 'ChoiceList') {
    value = getFieldValue('CHOICE_LIST', formValue);
  } else if (kind === 'DateTime') {
    value = getFieldValue('DATE_TIME', formValue);
  } else if (kind === 'FileList') {
    value = getFieldValue('FILE_LIST', formValue);
  } else if (kind === 'MemberList') {
    value = getFieldValue('MEMBER_LIST', formValue);
  } else if (kind === 'Money') {
    value = getFieldValue('MONEY', formValue);
  } else if (kind === 'Number') {
    value = getFieldValue('NUMBER', formValue);
  } else if (kind === 'Rating') {
    value = getFieldValue('RATING', formValue);
  } else if (kind === 'Text') {
    value = getFieldValue('TEXT', formValue);
  } else {
    value = getFieldValue('UNKNOWN', formValue);
  }

  return {
    sliceTemplateFieldId: id,
    value
  };
}

export function transformFormValuesToFieldValueInputs(
  fields: SliceField[],
  formValues: FormValues
): FieldValueInput[] {
  const values: FieldValueInput[] = [];

  fields.forEach(field => {
    const def = field.definition;
    const formValue = formValues[def.id];

    values.push(transformFormValueToFieldValueInput(def.id, def.kind, formValue));
  });

  return values;
}

type ParsableSlice = Pick<SliceFragment, 'sliceTemplate'> & {
  fields?: SliceFragment['fields'];
};

const getBaseDefinition = (field: SliceTemplateFieldFragment) => ({
  description: field.description,
  id: field.id,
  isRequired: field.isRequired,
  name: field.name ?? field.id,
  presentationRoles: field.presentationRoles,
  title: field.title
});

function parseBooleanSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): BooleanSliceField {
  const settings: BooleanFieldSettings = {
    label: null
  };

  if (field.settings?.__typename === 'BinaryDataTypeSettings') {
    settings.label = field.settings.label;
  }

  const kind = 'Boolean';

  return {
    kind,
    id: field.id,
    value: getBooleanValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'Switch'),
      settings
    }
  };
}

function parseChoiceListSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): ChoiceListSliceField {
  const options: ChoiceOption[] =
    field.fieldType.settings?.__typename === 'ChoiceListFieldTypeSettings'
      ? field.fieldType.settings.options.map(option => ({ label: option.value, value: option.key }))
      : [];

  const settings: ChoiceListFieldSettings = {
    maxCount: null,
    minCount: null,
    options,
    placeholder: null
  };

  if (field.settings?.__typename === 'ChoiceListDataTypeSettings') {
    settings.maxCount = field.settings.max;
    settings.minCount = field.settings.min;
    settings.placeholder = field.settings.placeholder;
  }

  const kind = 'ChoiceList';

  return {
    kind,
    id: field.id,
    value: getChoiceListValue(value),
    valueOptions: getChoiceListValueOptions(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'MultiSelect'),
      settings
    }
  };
}

function parseDateTimeSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): DateTimeSliceField {
  const settings: DateTimeFieldSettings = {
    hasTimeZone: false,
    isFuture: false,
    maxDate: null,
    minDate: null
  };

  if (field.settings?.__typename === 'DateTimeDataTypeSettings') {
    settings.hasTimeZone = field.settings.hasTimeZone;
    settings.isFuture = field.settings.isFuture;
    settings.maxDate = field.settings.maxDate;
    settings.minDate = field.settings.minDate;
  }

  const kind = 'DateTime';

  return {
    kind,
    id: field.id,
    value: getDateTimeValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'DateTimePicker'),
      settings
    }
  };
}

function parseFileListSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): FileListSliceField {
  const settings: FileListFieldSettings = {
    extensions: [],
    maxCount: null,
    maxFileSizeInKB: null,
    minCount: null,
    showPreview: false
  };

  if (field.settings?.__typename === 'FileListDataTypeSettings') {
    settings.extensions = field.settings.extensions;
    settings.maxCount = field.settings.max;
    settings.maxFileSizeInKB = field.settings.maxFileSizeInKB;
    settings.minCount = field.settings.min;
    settings.showPreview = field.settings.showPreview;
  }

  const kind = 'FileList';

  return {
    kind,
    id: field.id,
    value: getFileListValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'FileUpload'),
      settings
    }
  };
}

function parseMemberListSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): MemberListSliceField {
  const settings: MemberListFieldSettings = {
    allowedMemberTypes: [],
    maxCount: null,
    minCount: null
  };

  if (field.settings?.__typename === 'MemberListDataTypeSettings') {
    settings.allowedMemberTypes = field.settings.allowedMemberTypes;
    settings.maxCount = field.settings.max;
    settings.minCount = field.settings.min;
  }

  const kind = 'MemberList';

  return {
    kind,
    id: field.id,
    value: getMemberListValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'PeoplePicker'),
      settings
    }
  };
}

function parseMoneySliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): MoneySliceField {
  const kind = 'Money';
  const settings: MoneyFieldSettings = {
    currency: null,
    maxValue: null,
    minValue: null,
    precision: 2
  };

  if (field.settings?.__typename === 'MoneyDataTypeSettings') {
    settings.currency = field.settings.currency;
    settings.maxValue = field.settings._max;
    settings.minValue = field.settings._min;
    settings.precision = field.settings.precision;
  }

  return {
    kind,
    id: field.id,
    value: getMoneyValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'MoneyInput'),
      settings
    }
  };
}

function parseNumberSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): NumberSliceField {
  const settings: NumberFieldSettings = {
    maxValue: null,
    minValue: null,
    precision: 0
  };

  if (field.settings?.__typename === 'NumberDataTypeSettings') {
    settings.maxValue = field.settings._max;
    settings.minValue = field.settings._min;
    settings.precision = field.settings.precision;
  }

  const kind = 'Number';

  return {
    kind,
    id: field.id,
    value: getNumberValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'NumberInput'),
      settings
    }
  };
}

function parseRatingSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): RatingSliceField {
  const settings: RatingFieldSettingsWithKeyValuePair = {
    legend: []
  };

  if (field.settings?.__typename === 'RatingDataTypeSettings') {
    settings.legend = field.settings.legend.map((entry, index) => ({
      key: (index + 1).toString(),
      value: entry
    }));
  }

  const kind = 'Rating';

  return {
    kind,
    id: field.id,
    value: getRatingValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'Rating'),
      settings
    }
  };
}

function parseTextSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): TextSliceField {
  const settings: TextFieldSettings = {
    limit: null,
    mode: 'SIMPLE',
    placeholder: null
  };

  if (field.settings?.__typename === 'TextDataTypeSettings') {
    settings.limit = field.settings.limit;
    settings.mode = field.settings.mode;
    settings.placeholder = field.settings.placeholder;
  }

  const kind = 'Text';

  return {
    kind,
    id: field.id,
    value: getTextValue(value),
    definition: {
      ...getBaseDefinition(field),
      kind,
      inputType: getValidInputType(kind, getInputType(field.controlType), 'TextInput'),
      settings
    }
  };
}

function parseUnknownSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): UnknownSliceField {
  let parsedValue = '';

  if (hasValue(value)) {
    try {
      parsedValue = JSON.stringify(value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing "Unknown" slice field.', field, value);
    }
  }

  return {
    kind: 'Unknown',
    id: field.id,
    value: parsedValue,
    definition: {
      ...getBaseDefinition(field),
      kind: 'Unknown',
      inputType: 'TextInput',
      settings: null
    }
  };
}

export function parseSliceField(
  field: SliceTemplateFieldFragment,
  value?: FieldValueFragment
): SliceField {
  const { dataTypeId } = field.fieldType;

  if (dataTypeId === 'BINARY') return parseBooleanSliceField(field, value);
  if (dataTypeId === 'CHOICE_LIST') return parseChoiceListSliceField(field, value);
  if (dataTypeId === 'DATE_TIME') return parseDateTimeSliceField(field, value);
  if (dataTypeId === 'FILE_LIST') return parseFileListSliceField(field, value);
  if (dataTypeId === 'MEMBER_LIST') return parseMemberListSliceField(field, value);
  if (dataTypeId === 'MONEY') return parseMoneySliceField(field, value);
  if (dataTypeId === 'NUMBER') return parseNumberSliceField(field, value);
  if (dataTypeId === 'RATING') return parseRatingSliceField(field, value);
  if (dataTypeId === 'TEXT') return parseTextSliceField(field, value);
  if (dataTypeId === 'UNKNOWN') return parseUnknownSliceField(field, value);

  return exhaustiveCheck(dataTypeId);
}

export function parseSlice({ fields = [], sliceTemplate }: ParsableSlice): SliceField[] {
  return sliceTemplate.fields.map(field =>
    parseSliceField(
      field,
      fields.find(f => f.fieldId === field.id)
    )
  );
}

export function parseFieldData(fieldData: FieldData): ParsedFieldData {
  const { creator, dateCreated, fieldValue } = fieldData;

  if (fieldValue.dataTypeId === 'BINARY') {
    return {
      kind: 'Boolean',
      creator,
      dateCreated,
      value: getBooleanValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'CHOICE_LIST') {
    return {
      kind: 'ChoiceList',
      creator,
      dateCreated,
      value: getChoiceListValue(fieldValue),
      valueOptions: getChoiceListValueOptions(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'DATE_TIME') {
    return {
      kind: 'DateTime',
      creator,
      dateCreated,
      value: getDateTimeValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'FILE_LIST') {
    return {
      kind: 'FileList',
      creator,
      dateCreated,
      value: getFileListValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'MEMBER_LIST') {
    return {
      kind: 'MemberList',
      creator,
      dateCreated,
      value: getMemberListValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'MONEY') {
    return {
      kind: 'Money',
      creator,
      dateCreated,
      value: getMoneyValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'NUMBER') {
    return {
      kind: 'Number',
      creator,
      dateCreated,
      value: getNumberValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'RATING') {
    return {
      kind: 'Rating',
      creator,
      dateCreated,
      value: getRatingValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'TEXT') {
    return {
      kind: 'Text',
      creator,
      dateCreated,
      value: getTextValue(fieldValue)
    };
  }

  if (fieldValue.dataTypeId === 'UNKNOWN') {
    return {
      kind: 'Unknown',
      creator,
      dateCreated,
      value: ''
    };
  }

  return exhaustiveCheck(fieldValue.dataTypeId, true);
}

export function parseTextFieldData(textFieldData: TextFieldData): ParsedTextFieldData {
  const { creator, dateCreated, fieldValue } = textFieldData;

  return {
    kind: 'Text',
    creator,
    dateCreated,
    value: getTextValue(fieldValue)
  };
}
