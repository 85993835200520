import { useCallback, useState } from 'react';

export const useValue = <T>(initialValue?: T | undefined) => {
  const [value, set] = useState<T | undefined>(initialValue);

  return {
    clear: useCallback(() => set(undefined), []),
    reset: useCallback(() => set(initialValue), [initialValue]),
    set,
    value
  };
};
