import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getEmptyArray } from '@/utils/array';
import { LOADER_STALE_TIME, queryOptions, userClaimKeys } from '../utils';
import {
  GetUserClaimSettings,
  type GetUserClaimSettingsQuery,
  type GetUserClaimSettingsQueryVariables
} from './graphql/GetUserClaimSettings.gql';

type QueryVariables = GetUserClaimSettingsQueryVariables;

export function getUserClaimSettingsQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: userClaimKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetUserClaimSettings, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  activeOnly: false
};

export function userClaimSettingsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getUserClaimSettingsQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetUserClaimSettingsQueryVariables {
  const outputVariables: GetUserClaimSettingsQueryVariables = {
    activeOnly: variables.activeOnly
  };

  if (variables.identityProviderIds?.length) {
    outputVariables.identityProviderIds = variables.identityProviderIds;
  }

  return outputVariables;
}

export function parseData(data: GetUserClaimSettingsQuery | undefined) {
  return {
    userClaims: (data?.configuration?.customUserClaims ?? getEmptyArray()).map(claim => ({
      ...claim,
      identityProvider: {
        ...claim.identityProvider,
        uniqueName: claim.identityProvider.alias
      }
    })),
    identityProviders: (data?.configuration?.identityProviders ?? getEmptyArray()).map(
      provider => ({
        ...provider,
        uniqueName: provider.uniqueName || provider.alias
      })
    )
  };
}
