import { type DependencyList, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from './useUpdateEffect';

/**
 *
 * @param factory Factory function that returns a value that needs to be
 * memoized and debounced. The factory will only be executed once per debounce
 * interval.
 * @param delayInMs Debounce delay in milliseconds.
 * @param deps The dependency array.
 * @returns Return value of the factory.
 */
export function useDebouncedMemo<T>(factory: () => T, deps: DependencyList, delayInMs: number): T {
  // The `delayInMs` argument is on position 2 instead of 1 since `deps` needs to be on position 1
  // in order to have the react-hooks/exhaustive-deps lint rule work.

  const timerRef = useRef<number>();

  // Whenever this value is set to a new empty object it receives a new object
  // reference, which in turn will trigger a recompute of the useMemo(factory)
  // below. Basically a "forceUpdate()".
  const [_ref, _setRef] = useState({});

  useUpdateEffect(() => {
    // Set a new timer that triggers a recompute after the given delay.
    timerRef.current = window.setTimeout(() => _setRef({}), delayInMs);

    return () => clearTimeout(timerRef.current);
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
  return useMemo(factory, [_ref]); // eslint-disable-line react-hooks/exhaustive-deps
}
