import type { ReactNode } from 'react';
import { useAppConfigValue } from '@/components/AppConfig';
import { Image } from '@/components/Image';
import { Card, Flex, Grid, Spacer } from '@/components/primitives';
import { appendOptionsToImageUrl } from '@/utils/getImageUrl';
import { useId } from '@/utils/hooks/useId';
import { join } from '@/utils/url';
import { useI18n } from '../I18n';
import { Icon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';
import { MutedLanguageSelect } from './MutedLanguageSelect';

export const StatusPageLayout = ({ children }: { children: ReactNode }) => {
  const i18n = useI18n();
  const selectId = useId('select');
  const appUrl = useAppConfigValue('application_url');
  const logoSrc = join(appUrl, '/api/branding/logo');
  const logoUrl = appendOptionsToImageUrl(logoSrc, { mode: 'max', h: 42 });

  let label: string;

  // When you need this dropdown it means that you do not speak the current
  // language, making it a bit weird/useless to use the current language for the
  // translation of the label. As an improvement we'll do the following:
  //   - When English, show the label "Language"
  //   - When not English, show the label "{{LanguageInCurrentLocale}} (Language)"
  //     i.e. "Sprache (Language)"", or "Taal (Language)"
  if (i18n.language.startsWith('en')) {
    label = 'Language';
  } else {
    label = `${i18n.t('main', 'language.title')} (Language)`;
  }

  return (
    <Grid gridGap="s12" gridTemplateRows="auto 1fr auto" height="100vh">
      <Flex as="header" justifyContent="center" pt={{ _: 's08', md: 's16', xl: 's24' }}>
        <Image alt={i18n.t('common', 'logo.label')} maxHeight={42} src={logoUrl} />
      </Flex>
      <Flex alignItems="start" as="main" justifyContent="center" px={{ _: 's04', md: 's00' }}>
        {children}
      </Flex>
      <Card
        alignSelf="stretch"
        as="footer"
        bgColor="white"
        borderTop={1}
        borderTopColor="grey200"
        py="s04"
      >
        <Flex alignItems="center" justifyContent="center">
          <label htmlFor={selectId} title={label}>
            <VisuallyHidden>{label}</VisuallyHidden>
            <Icon size={18} type="language" />
          </label>
          <Spacer mr="s01" />
          <MutedLanguageSelect
            id={selectId}
            languages={i18n.supportedLanguages}
            onChange={event => i18n.setLanguage(event.target.value || 'en')}
            value={i18n.language}
          />
        </Flex>
      </Card>
    </Grid>
  );
};
