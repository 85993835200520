import { useCallback, useMemo, useRef, useState } from 'react';
import type { HorizontalBoundary, HorizontalScrollState } from './types';
import { useHorizontalScrollBoundaryRef } from './useHorizontalScrollBoundaryRef';

/**
 * Calculates the horizontal offset of an element to its parent.
 * If element.offsetParent equals the element's parent, then the offset simply
 * equals element.offsetLeft. However, this is only the case when the parent has
 * explicit positioning (relative/absolute/fixed). In any other case the
 * offsetParent will equal the first explicitly positioned ancestor in the DOM
 * tree, meaning we also have to substract the immediate parent's offset to this
 * element in order to find the child's offset. The approach below makes it work
 * for both positioned and non-positioned scroll containers.
 * @param element Element to calculate the offset for.
 * @returns The horizontal offset of the element to its parent.
 */
function getOffset(element: HTMLElement) {
  return element.offsetParent === element.parentElement
    ? element.offsetLeft
    : element.offsetLeft - (element.parentElement?.offsetLeft ?? 0);
}

function isHTMLElement(node: Node): node is HTMLElement {
  return node instanceof HTMLElement;
}

function scrollToNextChildElement(element: HTMLElement) {
  const childNodes = Array.from(element.children).filter(isHTMLElement);

  // The scroll container might have an inset (padding, scroll-padding, etc.)
  // that impacts the snap coordinates.
  const snapOffset = childNodes[0] ? getOffset(childNodes[0]) : 0;

  // Look up the first node that is _after_ the snap point.
  // Since scrollLeft might return a decimal number on high DPI or systems using
  // display scaling, we need to round up its value.
  const nextNode = childNodes.find(
    node => getOffset(node) > Math.ceil(element.scrollLeft) + snapOffset
  );

  if (nextNode) {
    element.scrollTo({
      left: getOffset(nextNode) - snapOffset,
      behavior: 'smooth'
    });
  }
}

function scrollToPreviousChildElement(element: HTMLElement) {
  const childNodes = Array.from(element.children).filter(isHTMLElement);

  // The scroll container might have an inset (padding, scroll-padding, etc.)
  // that impacts the snap coordinates.
  const snapOffset = childNodes[0] ? getOffset(childNodes[0]) : 0;

  // Look up the first node that is _before_ the snap point. We need to reverse
  // the list as we need to perform the lookup backwards.
  // Since scrollLeft might return a decimal number on high DPI or systems using
  // display scaling, we need to round up its value.
  const nextNode = childNodes
    .reverse()
    .find(node => getOffset(node) < Math.ceil(element.scrollLeft) + snapOffset);

  if (nextNode) {
    element.scrollTo({
      left: getOffset(nextNode) - snapOffset,
      behavior: 'smooth'
    });
  }
}

export function useHorizontalScroll(): HorizontalScrollState {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isStartBoundary, setIsStartBoundary] = useState(false);
  const [isEndBoundary, setIsEndBoundary] = useState(false);

  const startBoundaryRef = useHorizontalScrollBoundaryRef(
    containerRef.current,
    0.95,
    setIsStartBoundary
  );

  const endBoundaryRef = useHorizontalScrollBoundaryRef(
    containerRef.current,
    0.95,
    setIsEndBoundary
  );

  const scrollForward = useCallback(() => {
    if (containerRef.current) {
      scrollToNextChildElement(containerRef.current);
    }
  }, []);

  const scrollBackward = useCallback(() => {
    if (containerRef.current) {
      scrollToPreviousChildElement(containerRef.current);
    }
  }, []);

  /**
   * Indicates which boundary is currently visible. Possible options:
   * - `both`: Both start and end boundaries are visible
   * - `end`: Only the end boundary is visible
   * - `none`: Neither start nor end boundary is visible
   * - `start`: Only the start boundary is visible
   */
  let visibleBoundary: HorizontalBoundary = 'none';

  if (isStartBoundary && isEndBoundary) {
    visibleBoundary = 'both';
  } else if (isStartBoundary) {
    visibleBoundary = 'start';
  } else if (isEndBoundary) {
    visibleBoundary = 'end';
  }

  return useMemo(
    () => ({
      containerRef,
      endBoundaryRef,
      scrollForward,
      scrollBackward,
      startBoundaryRef,
      visibleBoundary
    }),
    [containerRef, endBoundaryRef, scrollForward, scrollBackward, startBoundaryRef, visibleBoundary]
  );
}
