import { type ButtonHTMLAttributes, forwardRef } from 'react';
import { Icon, type IconType } from '@/components/Icon';
import type { ColorToken, SwatchColor } from '@/css/types';
import { exhaustiveCheck } from '@/types/utils';
import { hasValue } from '@/utils/common';
import { IconButtonBase, type IconButtonSize } from './IconButtonBase';

export type { IconButtonSize };

export type IconButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'color' | 'size' | 'type'
> & {
  color?: ColorToken;
  hasBorder?: boolean;
  hoverBgColor?: ColorToken;
  hoverColor?: ColorToken;
  icon: IconType;
  iconFillColor?: ColorToken;
  size?: IconButtonSize; // TODO: ResponsiveValue
  type?: 'button' | 'reset' | 'submit';
  swatchColor?: SwatchColor;
};

function getSize(size: IconButtonSize): number {
  if (size === 'xs') return 14;
  if (size === 'sm') return 16;
  if (size === 'md') return 20;
  if (size === 'lg') return 24;
  if (size === 'xl') return 28;

  return exhaustiveCheck(size);
}

function getColors(swatchColor: SwatchColor): [ColorToken, ColorToken] {
  return [`${swatchColor}500`, `${swatchColor}800`];
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      color,
      hasBorder,
      hoverBgColor = 'neutral100',
      hoverColor,
      icon,
      iconFillColor,
      size = 'md',
      swatchColor,
      type = 'button',
      ...props
    },
    ref
  ) => {
    if (
      process.env.NODE_ENV !== 'production' &&
      !props['aria-label'] &&
      !props['aria-labelledby']
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        '[IconButton] No accessible text found. Please provide an "aria-label" or "aria-labelledby" attribute.'
      );
    }

    if (hasValue(swatchColor) && hasValue(color)) {
      throw new Error(
        'ERROR: Received both a `swatchColor` and `color` prop. When providing a `swatchColor`, both the `color` and `hoverColor` prop will be calculated automatically so they should not be provided as prop.'
      );
    }

    if (hasValue(swatchColor) && hasValue(hoverColor)) {
      throw new Error(
        'ERROR: Received both a `swatchColor` and `hoverColor` prop. When providing a `swatchColor`, both the `color` and `hoverColor` prop will be calculated automatically so they should not be provided as prop.'
      );
    }

    const iconSize = getSize(size);

    let _color: ColorToken = 'neutral500';
    let _hoverColor: ColorToken = 'neutral800';

    if (swatchColor) {
      [_color, _hoverColor] = getColors(swatchColor);
    } else {
      if (color) {
        _color = color;
        _hoverColor = color;
      }

      if (hoverColor) {
        _hoverColor = hoverColor;
      }
    }

    return (
      <IconButtonBase
        {...props}
        ref={ref}
        color={_color}
        hasBorder={hasBorder}
        hoverBgColor={hoverBgColor}
        hoverColor={_hoverColor}
        size={size}
        type={type}
      >
        <Icon fillColor={iconFillColor} size={iconSize} type={icon} />
      </IconButtonBase>
    );
  }
);
