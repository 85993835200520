import { join } from '@/utils/url';
import { getBoolean } from './utils/store';

export const CSRF_HEADER = 'X-CSRF-Token';

const API_URL = window.location.origin.replace('/local.', '/');

export const API_INFO_URL = join(API_URL, 'api/application/info');

export const DEFAULT_USER_LANGUAGE = getBoolean('useSourceTextForUntranslatedTerms', false)
  ? '__'
  : 'en';

export const IS_DEBUG = getBoolean('isDebugEnabled', false);
