import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetEditIdentityProviderFormDataQueryVariables = Types.Exact<{
  identityProviderId: Types.Scalars['GUID']['input'];
}>;


export type GetEditIdentityProviderFormDataQuery = { configuration: { __typename: 'ConfigurationContainer', identityProvider: { __typename: 'IdentityProvider', id: Types.Scalars['GUID']['output'], alias: Types.Scalars['String']['output'], isEnabled: boolean, uniqueName: Types.Scalars['String']['output'] | null } | null } | null };


export const GetEditIdentityProviderFormData = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEditIdentityProviderFormData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"identityProviderId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"configuration"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"identityProvider"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"identityProviderId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"IdentityProviderFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"IdentityProviderFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"IdentityProvider"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"alias"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"uniqueName"}}]}}]} as unknown as DocumentNode<GetEditIdentityProviderFormDataQuery, GetEditIdentityProviderFormDataQueryVariables>;