import {
  lazy,
  type ReactNode,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import type { To } from 'react-router-dom';
import { useJargon } from '@/components/AppConfig';
import { Button } from '@/components/Button';
import type { WrappedError } from '@/components/ErrorAlertList';
import {
  getNextHeadingLevel,
  Heading,
  HeadingContext,
  HeadingContextProvider
} from '@/components/Heading';
import { type I18n, type JargonMap, lookupJargonTranslation, useI18n } from '@/components/I18n';
import { Icon } from '@/components/Icon';
import { Absolute, Box, Card, Flex, Spacer, Text } from '@/components/primitives';
import { RichTextView } from '@/components/RichTextView';
import { Section } from '@/components/SectioningContentElement';
import { SessionUserContext } from '@/components/SessionUserContext';
import { breakpoints } from '@/css/breakpoints';
import { getToken } from '@/css/utils';
import { CommunityDropdownMenu } from '@/features/communities/common/components/CommunityDropdownMenu';
import { EditCommunityFormModal } from '@/features/communities/common/views/EditCommunityFormModal';
import { AddContributionFormWizardModal } from '@/features/contributions/views/AddContributionFormWizardModal';
import type { ParsedMember } from '@/types';
import { exhaustiveCheck } from '@/types/utils';
import { useDebouncedMemo } from '@/utils/hooks/useDebouncedMemo';
import { useGuidedFocusAfterLinkNavigation } from '@/utils/hooks/useGuidedFocusAfterLinkNavigation';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import { useMatchMedia } from '@/utils/hooks/useMatchMedia';
import { useModalURLSearchParam } from '@/utils/hooks/useModalURLSearchParam';
import { useToggle } from '@/utils/hooks/useToggle';
import { useUpdateEffect } from '@/utils/hooks/useUpdateEffect';
import {
  type CommunityData,
  DashboardFilter,
  type DashboardFilterValue
} from '../../components/DashboardFilter';
import {
  type CommunityFilter,
  DashboardContentView,
  type GetLocation as GetViewLocation,
  type View
} from './DashboardContentView';

const DashboardFilterModal = lazy(async () => ({
  default: (await import('./DashboardFilterModal')).DashboardFilterModal
}));

const CurrentUserBlockingUserAgreementsForSubjectModal = lazy(async () => ({
  default: (await import('@/features/user-agreements/main/components/CurrentUserBlockingUserAgreementsForSubjectModal')).CurrentUserBlockingUserAgreementsForSubjectModal // prettier-ignore
}));

export type { CommunityData, DashboardFilterValue, View };

export type FeaturedCommunity = {
  banner: { id: string; previewUrl: string | null } | null;
  description: string | null;
  id: string;
  name: string;
  viewer: {
    canDeleteCommunity: boolean;
    canEditCommunity: boolean;
  };
};

const ADD_CONTRIBUTION_MODAL_PARAM = 'add-contribution';

type ViewOptions = {
  view?: View;
  filter?: 'all' | 'favorites' | [type: 'community', value: string];
  dialog?: typeof ADD_CONTRIBUTION_MODAL_PARAM;
};

export type GetLocation = (options?: ViewOptions) => To;

type Props = {
  activeFilter: DashboardFilterValue;
  activeView: View;
  banner: { id: string; previewUrl: string | null } | null | undefined;
  communities: CommunityData[];
  communityCount: number;
  description: string | null | undefined;
  errors: WrappedError[];
  favoriteCommunities: CommunityData[];
  favoriteCommunityIds: string[];
  featuredCommunity: FeaturedCommunity | null | undefined;
  getLocation: GetLocation;
  hiddenCommunities: CommunityData[];
  onAddCommunityToFavorites: (value: string) => Promise<unknown>;
  onClearFavoriteCommunities: () => Promise<unknown>;
  onFeaturedCommunityUpdated?: () => void;
  onRemoveCommunityFromFavorites: (value: string) => Promise<unknown>;
  showAddContributionButton?: boolean;
  /** The number of pending tasks, taking into account the active filter. */
  taskCount: number | undefined;
  viewer: ParsedMember;
  /** The number of contributions created by the viewer, taking into account the active filter. */
  viewerContributionCount: number | undefined;
};

function getBadgeText(i18n: I18n, activeFilter: DashboardFilterValue, jargon: JargonMap) {
  if (Array.isArray(activeFilter)) {
    return lookupJargonTranslation<'community'>(jargon.community, {
      COMMUNITY: () => i18n.t('main', 'community.label')
    });
  }

  if (activeFilter === 'favorites') {
    return lookupJargonTranslation<'community'>(jargon.community, {
      COMMUNITY: () => i18n.t('main', 'dashboard.badge_favoriteCommunities')
    });
  }

  if (activeFilter === 'all') {
    return lookupJargonTranslation<'community'>(jargon.community, {
      COMMUNITY: () => i18n.t('main', 'dashboard.badge_allCommunities')
    });
  }

  return exhaustiveCheck(activeFilter);
}

export const DashboardView = ({
  activeFilter,
  activeView,
  banner: _banner,
  communities,
  communityCount,
  description: _description,
  errors,
  favoriteCommunities,
  favoriteCommunityIds,
  featuredCommunity,
  getLocation,
  hiddenCommunities,
  onAddCommunityToFavorites,
  onClearFavoriteCommunities,
  onFeaturedCommunityUpdated,
  onRemoveCommunityFromFavorites,
  showAddContributionButton,
  taskCount,
  viewer,
  viewerContributionCount
}: Props) => {
  const i18n = useI18n();
  const jargon = useJargon();
  const contentRef = useRef<HTMLDivElement>(null);
  const headingContext = useContext(HeadingContext);
  const sessionUser = useContext(SessionUserContext);
  const isXlOrHigher = useMatchMedia(`(min-width: ${breakpoints.xl})`);
  const focusAfterNavigation = useGuidedFocusAfterLinkNavigation(contentRef);
  const [id, aria] = useHeadingIdAriaPair();
  const [activeHiddenCommunityId, setActiveHiddenCommunityId] = useState<string | null>(null);

  /** Modals */

  const [isAddContributionModalVisible, showAddContributionModal, closeAddContributionModal] =
    useModalURLSearchParam(ADD_CONTRIBUTION_MODAL_PARAM);

  const [isFilterModalVisible, { on: showFilterModal, off: hideFilterModal }] = useToggle();
  const [isEditCommunityModalVisible, { on: showEditCommunityModal, off: hideEditCommunityModal }] =
    useToggle();

  let banner = _banner ?? null;
  let heading = i18n.t('main', 'dashboard.label');

  useUpdateEffect(() => {
    if (isXlOrHigher && isFilterModalVisible) {
      hideFilterModal();
    }
  }, [hideFilterModal, isXlOrHigher, isFilterModalVisible]);

  const descriptions: string[] = [];

  if (_description && (activeFilter === 'all' || activeFilter === 'favorites')) {
    descriptions.push(_description);
  }

  // Check if we need to set up the dashboard in "featured community" mode,
  // meaning we might have to display the featured community's banner and/or
  // description.
  if (featuredCommunity) {
    // Use featured community banner if set, otherwise use dashboard banner.
    banner = featuredCommunity.banner ?? banner;
    heading = featuredCommunity.name;

    if (featuredCommunity.description) {
      descriptions.push(featuredCommunity.description);
    }
  }

  let addContributionButton: ReactNode = null;

  if (showAddContributionButton) {
    addContributionButton = (
      <Button
        css={{ paddingLeft: getToken('space', 's10'), paddingRight: getToken('space', 's10') }}
        onClick={showAddContributionModal}
        variant="branded-primary"
      >
        {i18n.t('contribution', 'callToActions.showAddContributionFormButton.text')}
      </Button>
    );
  }

  const description = descriptions.join('') || null;

  let filterContent: ReactNode = null;

  if (isXlOrHigher || isFilterModalVisible) {
    filterContent = (
      <DashboardFilter
        activeFilter={activeFilter}
        communities={communities}
        favoriteCommunities={favoriteCommunities}
        hiddenCommunities={hiddenCommunities}
        onAddCommunityToFavorites={onAddCommunityToFavorites}
        onClearFavoriteCommunities={onClearFavoriteCommunities}
        onFilterClick={event => {
          if (isFilterModalVisible) hideFilterModal();
          focusAfterNavigation(event);
        }}
        onHiddenCommunityClick={setActiveHiddenCommunityId}
        onRemoveCommunityFromFavorites={onRemoveCommunityFromFavorites}
      />
    );
  }

  const headerContent = (
    <>
      <Flex ref={contentRef} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" flexWrap="wrap">
          <Flex alignItems="center">
            <Heading variant="heading.800">
              {featuredCommunity ? heading : i18n.t('main', 'dashboard.label')}
            </Heading>
            {communityCount > 1 && (
              <>
                <Spacer mr="s06" />
                <Card bgColor="rgba(0, 0, 0, 0.05)" css={{ backdropFilter: 'blur(3px)' }}>
                  <Flex alignItems="center" px="s03" py="s02">
                    <Icon
                      color="textLight"
                      size={18}
                      type={activeFilter === 'favorites' ? 'star' : 'user-group'}
                    />
                    <Spacer mr="s02" />
                    <Text fontSize="md">{getBadgeText(i18n, activeFilter, jargon)}</Text>
                  </Flex>
                </Card>
              </>
            )}
            {featuredCommunity?.viewer.canEditCommunity && (
              <>
                <Spacer mr="s03" />
                <CommunityDropdownMenu
                  communityId={featuredCommunity.id}
                  onEditCommunity={showEditCommunityModal}
                  showConfigurationPageLink={sessionUser.canManageConfiguration}
                  toggleButtonBgHoverColor="transparent"
                  toggleButtonSize="xl"
                  viewer={{
                    canEditCommunity: featuredCommunity.viewer.canEditCommunity,
                    canDeleteCommunity: false
                  }}
                />
              </>
            )}
          </Flex>
        </Flex>
        {isXlOrHigher && addContributionButton}
      </Flex>
      {description && (
        <Box maxWidth="80ch" mt={{ _: 's06', md: 's08' }}>
          <Text fontSize={{ _: 'md', md: 'base' }} lineHeight="snug">
            <RichTextView content={description} />
          </Text>
        </Box>
      )}
      {!isXlOrHigher && addContributionButton && (
        <Flex justifyContent={{ _: 'center', md: 'flex-start' }} mt={{ _: 's06', md: 's08' }}>
          {addContributionButton}
        </Flex>
      )}
    </>
  );

  const getViewLocation: GetViewLocation = useCallback(
    view => {
      if (
        (activeFilter === 'favorites' && favoriteCommunities.length > 0) ||
        (activeFilter === 'all' && favoriteCommunities.length === 0)
      ) {
        return getLocation({ view });
      }

      return getLocation({ view, filter: activeFilter });
    },
    [activeFilter, getLocation, favoriteCommunities.length]
  );

  // We're using a debounce here to calculate the communityIds. This puts the
  // computation in a separate microtask, and as a result also schedules the
  // underlying query refetch in a separate tick, which is needed to keep the
  // dashboard filter itself snappy and responsive. If we didn't delay the query
  // trigger, a shed load of variable induced render changes would simultenously
  // occur below the DashboardContentView component, taking up valuable
  // resources and resulting in a slightly laggy filter UI.
  // This approach is very similar to what React is planning with
  // useDeferredValue() in v18.
  // Worth noting is that we _are_ kind of misusing useDebouncedMemo (and
  // debounce in general) since not every debounce implementation behaves the
  // same way. Some debounces have an "immediate" mode causing them to already
  // trigger once in the beginning, which would make the code below useless. Our
  // debounce does not support immediate (right now) so it's not an issue. To
  // be on the safe side we could consider something like a useDelayedValue()
  // hook. Ideally you'd want to use useDeferredValue() as name, but this will
  // clash with react v18 and I'm not sure if the new react hook will behave
  // exactly as you'd expect here, will need to investigate. If it does, we
  // could just use that one instead.
  // TODO: Reevaluate come react 18
  const communityIds = useDebouncedMemo(
    () => {
      if (activeFilter === 'favorites') return favoriteCommunityIds;
      if (activeFilter !== 'all') return [activeFilter[1]];
      return [];
    },
    [activeFilter, favoriteCommunityIds],
    0
  );

  const communityFilter: CommunityFilter = useMemo(() => {
    // We have to pass down a CommunityFilter to the underlying module &
    // contribution modals so that it can be prefilled in the modal filter list.
    // Sadly, these filters require a community name in addition to the
    // community id, so we can't just use the `communityIds` we compute above.
    // We can make one assumption here that makes the lookup easier: When a
    // featured community is set, we *know* that the active filter will be
    // ['community', featuredCommunityId], so we can just pass down the featured
    // community as filter.
    if (featuredCommunity) return [featuredCommunity];
    if (activeFilter === 'favorites') return favoriteCommunities;
    return [];
  }, [activeFilter, favoriteCommunities, featuredCommunity]);

  const label = lookupJargonTranslation<'community'>(jargon.community, {
    COMMUNITY: () => i18n.t('main', 'community.label_multiple')
  });

  return (
    <>
      {activeHiddenCommunityId && (
        <Suspense fallback={null}>
          <CurrentUserBlockingUserAgreementsForSubjectModal
            onRequestClose={() => setActiveHiddenCommunityId(null)}
            onSubmissionCompleted={() => setActiveHiddenCommunityId(null)}
            subjectId={activeHiddenCommunityId}
          />
        </Suspense>
      )}
      {isAddContributionModalVisible && (
        <AddContributionFormWizardModal
          communityId={featuredCommunity?.id}
          onRequestClose={closeAddContributionModal}
        />
      )}
      {featuredCommunity && isEditCommunityModalVisible && (
        <EditCommunityFormModal
          communityId={featuredCommunity.id}
          onRequestClose={hideEditCommunityModal}
          onSubmitted={() => {
            onFeaturedCommunityUpdated?.();
            hideEditCommunityModal();
          }}
        />
      )}
      <Flex flex={1}>
        <Box flex={1} overflowX="auto" position="relative">
          {!isXlOrHigher && communityCount > 1 && (
            <Absolute right={0} top={{ _: '1rem', md: '2rem' }}>
              <Button
                // Button/v2's "secondary" variant has been made transparent by default. This was
                // done intentionally by us and is expected for some use-cases and not a problem for
                // most others, but in this particular use-case we need a white background as the
                // community banner might interfere with the legibility of the button text. For now
                // we can override it with css, can find a better solution later.
                // We could make them solid (non-transparent) by default (as is the case for the
                // actual motif button) and provide an additional "secondary-transparent" variant.
                // ... Or we could use the "ghost" variant for this, as motif is using "ghost"
                // rather incorrectly. Their ghost button is a feature-less button, while normally
                // a ghost button is transparent with a border. Then we diverge from motif's variant
                // naming but I'm not sure I care.
                css={{ borderRight: 0, backgroundColor: 'white' }}
                iconBefore="menu"
                onClick={showFilterModal}
                variant="secondary"
              >
                {label}
              </Button>
            </Absolute>
          )}
          <DashboardContentView
            activeView={activeView}
            bannerUrl={banner?.previewUrl}
            communityFilter={communityFilter}
            communityIds={communityIds}
            errors={errors}
            getLocation={getViewLocation}
            headerContent={headerContent}
            taskCount={taskCount}
            viewer={viewer}
            viewerContributionCount={viewerContributionCount}
          />
        </Box>
        {communityCount > 1 && (
          <>
            {isXlOrHigher && (
              <Section aria={aria} bgColor="white" display="flex">
                <Card
                  borderLeft={1}
                  borderLeftColor="neutral200"
                  p="s06"
                  width={{ _: '18rem', xxl: '24rem' }}
                >
                  <Heading id={id} mb="s06" variant="heading.500">
                    {label}
                  </Heading>
                  <HeadingContextProvider previousContext={getNextHeadingLevel(headingContext)}>
                    {filterContent}
                  </HeadingContextProvider>
                </Card>
              </Section>
            )}
            {isFilterModalVisible && (
              <Suspense fallback={null}>
                <DashboardFilterModal onRequestClose={hideFilterModal}>
                  {filterContent}
                </DashboardFilterModal>
              </Suspense>
            )}
          </>
        )}
      </Flex>
    </>
  );
};
