export function isNumeric<T>(value: T | number | string): value is number | string {
  return (
    (typeof value === 'number' && !Number.isNaN(value)) ||
    (typeof value === 'string' && value !== '' && !Number.isNaN(Number(value)))
  );
}

// Why do we need the !isNaN() if we already do a typeof, you ask? Well...
// Because `typeof NaN === 'number'`
export function isNumber<T>(value: T | number): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}

const DELIMITERS = [
  32, // SPACE          \x20
  39, // APOSTROPHE     \x27
  44, // COMMA          \x2c
  46, // DOT            \x2e
  8201 // THIN SPACE    \u2009
] as const;

// The regex below is not a 100% bulletproof, but it whitelists all integers,
// decimals, all thousand/decimal separators and scientific notation. Both
// signed and unsigned. It's a lot wider than it could be, but making it 100%
// correct is pretty crazy.
// Slightly based on https://stackoverflow.com/questions/2811031/decimal-or-numeric-values-in-regular-expression-validation
const NUMBER_LIKE_REGEX = /^-?\d+?((\x2e|\x2c|\x27|\x20|\u2009)?\d+)*?(e-?\d+)?$/i;

type Delimiter = (typeof DELIMITERS)[number];

function isDelimiter(charCode: number): charCode is Delimiter {
  return DELIMITERS.includes(charCode);
}

export function parseNumberLike(input: number | string): number | null {
  if (typeof input === 'number') return input;
  if (!NUMBER_LIKE_REGEX.test(input)) return null;

  let value = input;
  let lastDelimiter: Delimiter | null = null;
  let lastDelimiterCount = 0;

  // Checking every character in the string to see if it's a delimiter. Starting
  // from the back since the right-most delimiter is the only candidate for a
  // decimal delimiter, given it only occurs once.
  for (let i = value.length; i > 0; i -= 1) {
    const charCode = value.charCodeAt(i);

    if (!lastDelimiter && isDelimiter(charCode)) {
      // We found the right-most delimiter, store it for later reference.
      lastDelimiter = charCode;
    }

    if (lastDelimiter === charCode) {
      lastDelimiterCount += 1;
    }
  }

  // Store a list of all delimiters that need to be removed from the number. If
  // we have a right-most delimiter that only occurs once, we know this is the
  // decimal delimiter and we need to exclude it from ehh... exclusion. If it
  // occurs more than once it means this number is not a decimal and instead
  // just contains digit delimiters.
  const filterList =
    lastDelimiter && lastDelimiterCount === 1
      ? DELIMITERS.filter(v => v !== lastDelimiter)
      : DELIMITERS.concat();

  // Build a regex out of the delimiters that need to be filtered out. Each
  // delimiter value needs to be escaped since some of these characters might
  // require escaping (i.e. dot).
  value = value.replace(
    new RegExp(filterList.map(code => `\\${String.fromCharCode(code)}`).join('|'), 'g'),
    ''
  );

  // Dots reliably get parsed as decimal delimiter so it's safest to just
  // replace any existing decimal delimiter with it.
  if (lastDelimiter && lastDelimiterCount === 1) {
    value = value.replace(String.fromCharCode(lastDelimiter), '.');
  }

  const parsedValue = parseFloat(value);

  return isNumber(parsedValue) ? parsedValue : null;
}
