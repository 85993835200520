import { forwardRef, type ReactNode } from 'react';
import { Box, Flex } from '@/components/primitives';
import type { SpaceProps } from '@/components/primitives/types';
import type { ColorToken } from '@/css/types';
import { exhaustiveCheck } from '@/types/utils';

export type LayoutWidth = 'lg' | 'md' | 'sm' | 'xl' | 'xxl' | 'xxxl';

function getWidthUnit(width: LayoutWidth): string {
  // Inspired by https://tailwindcss.com/docs/max-width
  let unit: string;

  if (width === 'sm') {
    unit = '40rem'; // 640 @ 16px
  } else if (width === 'md') {
    unit = '48rem'; // 768 @ 16px
  } else if (width === 'lg') {
    unit = '64rem'; // 1024 @ 16px
  } else if (width === 'xl') {
    unit = '75rem'; // 1200 @ 16px
  } else if (width === 'xxl') {
    unit = '90rem'; // 1440 @ 16px
  } else if (width === 'xxxl') {
    unit = '100rem'; // 1600 @ 16px
  } else {
    exhaustiveCheck(width);
  }

  return unit;
}
type Props = {
  bgColor?: ColorToken;
  children?: ReactNode;
  minHeight?: number | string;
  px?: SpaceProps['px'];
  py?: SpaceProps['py'];
};

type Component = (props: Props) => JSX.Element;

type StaticComponents = {
  Constrained: typeof ConstrainedViewLayout;
};

const ConstrainedViewLayout = forwardRef<HTMLDivElement, Props & { maxWidth?: LayoutWidth }>(
  ({ bgColor, children, maxWidth = 'xl', minHeight, px = 's04', py = 's08' }, ref) => (
    // Initially we used max-width with a flex=1 but that breaks in IE11 in
    // conjunction with justify-content. The workaround is inverting the flex
    // logic from "1 0 0%" + max-width to "0 1 {{max-width}}" + min-width=0. So
    // instead of having it start small and stretch you have it start large and
    // shrink. This bug is documented here:
    // https://github.com/philipwalton/flexbugs#flexbug-17.
    <Flex ref={ref} bgColor={bgColor} justifyContent="center">
      <Box
        flex={`0 1 ${getWidthUnit(maxWidth)}`}
        maxWidth={getWidthUnit(maxWidth)}
        minHeight={minHeight}
        minWidth={0}
        px={px}
        py={py}
      >
        {children}
      </Box>
    </Flex>
  )
);

export const ViewLayout: Component & StaticComponents = ({
  bgColor,
  children,
  minHeight,
  px = 's04',
  py = 's08'
}) => (
  <Box bgColor={bgColor} minHeight={minHeight} px={px} py={py}>
    {children}
  </Box>
);

ViewLayout.Constrained = ConstrainedViewLayout;
