import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import type { I18n } from '@/components/I18n';
import { exhaustiveCheck } from '@/types/utils';
import { createContributionVotingService } from './ContributionVotingService';

export function getAccessibleVotingStatusText(
  i18n: I18n,
  vote: Api.VoteType | null,
  prevVote: Api.VoteType | null
) {
  if (vote === null) {
    return i18n.t('main', 'voteResults.userUndoVote.status.text');
  }

  if (vote === 'NEGATIVE') {
    return prevVote === 'POSITIVE'
      ? i18n.t('main', 'voteResults.userUpvoteReplacedWithDownvote.status.text')
      : i18n.t('main', 'voteResults.userDownvote.status.text');
  }

  if (vote === 'POSITIVE') {
    return prevVote === 'NEGATIVE'
      ? i18n.t('main', 'voteResults.userDownvoteReplacedWithUpvote.status.text')
      : i18n.t('main', 'voteResults.userUpvote.status.text');
  }

  return exhaustiveCheck(vote);
}

type VoteAction = 'downvote' | 'undo' | 'upvote';

export type ContributionVoteHandler = (
  contributionId: string,
  action: VoteAction
) => Promise<Api.VoteType | null>;

export function useContributionVoting(): ContributionVoteHandler {
  const client = useApolloClient();

  return useCallback(
    async (contributionId, action) => {
      const service = createContributionVotingService(client);

      if (action === 'upvote') {
        await service.addUpvote(contributionId);
      } else if (action === 'downvote') {
        await service.addDownvote(contributionId);
      } else if (action === 'undo') {
        await service.undoVote(contributionId);
      } else {
        exhaustiveCheck(action);
      }

      return import('./GetVotingData.gql')
        .then(({ GetVotingData }) =>
          client.query({
            query: GetVotingData,
            fetchPolicy: 'network-only',
            variables: {
              contributionId
            }
          })
        )
        .then(result => result.data.contribution?.viewer.voteValue ?? null);
    },
    [client]
  );
}
