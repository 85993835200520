import type { ReactChild, ReactNode } from 'react';
import { Flex, Spacer } from '@/components/primitives';

type Slots = {
  content: ReactChild;
  contentBefore?: ReactNode;
  contentAfter?: ReactNode;
};

type Props = {
  slots: Slots;
};

export const DashboardFilterListItemLayout = ({ slots }: Props) => (
  <Flex alignItems="center" as="li" py="s01">
    {slots.contentBefore && (
      <>
        {slots.contentBefore}
        <Spacer mr="s03" />
      </>
    )}
    {slots.content}
    {slots.contentAfter && (
      <>
        <Spacer mr="s03" />
        {slots.contentAfter}
      </>
    )}
  </Flex>
);
