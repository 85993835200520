import type { To } from 'react-router-dom';
// Exported/imported from a direct file due to bundle issues.
import {
  getNewUserAgreementFormUrl,
  NEW_USER_AGREEMENT_PATH
} from '@/features/user-agreements/config/views/UserAgreementListView/routing';
// Exported/imported from a direct file due to bundle issues.
import {
  getUserAgreementUrl,
  USER_AGREEMENT_PATH
} from '@/features/user-agreements/config/views/UserAgreementModal/routing';
// Exported/imported from a direct file due to bundle issues.
import { join } from '@/utils/url';
import type { RouteConfig } from './config';

type ProgramPageOptions = {
  ideationModuleRequestId?: string;
};

type SliceTemplatePageOptions = {
  view?: 'weighted-review-scores';
};

type AdminLocationMap = {
  getAdminPage(): To;
  getCommunityListPage(): To;
  getCommunityPage(id: string): To;
  getCommunityProgramPage(communityId: string, programId: string): To;
  getDataManagementSettingsPage(): To;
  getDataSetListPage(): To;
  getFieldTypeListPage(): To;
  getIntegrationsPage(): To;
  getKpiListPage(): To;
  getProgramListPage(): To;
  getProgramPage(id: string, options?: ProgramPageOptions): To;
  getProgramTemplatePage(id: string): To;
  getProgramTemplateListPage(): To;
  getRoleAssignmentListPage(): To;
  getRoleListPage(): To;
  getRolePage(id: string): To;
  getSliceTemplateListPage(): To;
  getSliceTemplatePage(id: string, options?: SliceTemplatePageOptions): To;
  getUserClaimSettingsPage(): To;
  getUserGroupListPage(): To;
  getUserRegistrationSettingsPage(): To;

  // General settings page
  getGeneralSettingsPage(): To;
  getBrandingSettingsPage(): To;
  getDashboardSettingsPage(): To;
  getProfileCompletionSettingsPage(): To;
  getLanguageSettingsPage(): To;

  getUserListPage(): To;
  getUserImportListPage(): To;
  getUserRemovalRequestListPage(): To;

  // User agreements
  getNewUserAgreementFormUrl(): string;
  getUserAgreementListPage(): string;
  getUserAgreementUrl: typeof getUserAgreementUrl;
};

const adminPaths = {
  admin: '/admin',
  community: '/admin/communities/:communityId',
  communityList: '/admin/communities',
  communityProgram: '/admin/communities/:communityId/programs/:programId',
  dataManagementSettings: '/admin/data-management',
  dataSetList: '/admin/reports/data-sets',
  fieldTypeList: '/admin/field-types',
  integrations: '/admin/integrations',
  kpiList: '/admin/reports/kpis',
  program: '/admin/programs/:programId',
  programList: '/admin/programs',
  programTemplate: '/admin/program-templates/:programTemplateId',
  programTemplateList: '/admin/program-templates',
  role: '/admin/roles/:roleId',
  roleAssignmentList: '/admin/role-assignments',
  roleList: '/admin/roles',
  sliceTemplate: '/admin/slice-templates/:sliceTemplateId',
  sliceTemplateList: '/admin/slice-templates',
  userClaimSettings: '/admin/user-claims',
  userGroupList: '/admin/user-groups',
  userRegistrationSettings: '/admin/user-registration',

  generalSettings: '/admin/general-settings',
  userManagement: '/admin/users',

  // User agreements
  userAgreementList: '/admin/user-agreements',
  newUserAgreement: `/admin/user-agreements/${NEW_USER_AGREEMENT_PATH}`,
  userAgreement: `/admin/user-agreements/${USER_AGREEMENT_PATH}`
} as const;

export type AdminPathMap = typeof adminPaths;

export const adminRouteConfig: RouteConfig<AdminPathMap, AdminLocationMap> = {
  paths: adminPaths,
  locations: {
    getAdminPage: () => '/admin',
    getCommunityListPage: () => '/admin/communities',
    getCommunityPage: id => `/admin/communities/${id}`,
    getCommunityProgramPage: (communityId, programId) =>
      `/admin/communities/${communityId}/programs/${programId}`,
    getDataManagementSettingsPage: () => '/admin/data-management',
    getDataSetListPage: () => '/admin/reports/data-sets',
    getFieldTypeListPage: () => '/admin/field-types',
    getIntegrationsPage: () => '/admin/integrations',
    getKpiListPage: () => '/admin/reports/kpis',
    getProgramListPage: () => '/admin/programs',
    getProgramPage: (id, options?: ProgramPageOptions) => {
      let path = `/admin/programs/${id}`;

      if (options?.ideationModuleRequestId) {
        path = join(path, 'challenge-requests', options.ideationModuleRequestId);
      }

      return path;
    },
    getProgramTemplateListPage: () => '/admin/program-templates',
    getProgramTemplatePage: id => `/admin/program-templates/${id}`,
    getRoleAssignmentListPage: () => '/admin/role-assignments',
    getRoleListPage: () => '/admin/roles',
    getRolePage: id => `/admin/roles/${id}`,
    getSliceTemplateListPage: () => '/admin/slice-templates',
    getSliceTemplatePage: (id, options) => {
      let path = `/admin/slice-templates/${id}`;

      if (options?.view === 'weighted-review-scores') {
        path = join(path, 'weighted-review-scores');
      }

      return path;
    },
    getUserClaimSettingsPage: () => '/admin/user-claims',
    getUserGroupListPage: () => '/admin/user-groups',
    getUserRegistrationSettingsPage: () => '/admin/user-registration',

    /**
     * @TODO Find a better approach to make the nested routes below type-safe(r).
     * We could in theory use generatePath() with params/wildcards that could be
     * imported from underlying pages (or views), but this might get unwieldy
     * and/or hard to read, but it would mean that all relative routes are
     * defined bottom-up and are fully type-safe.
     * See https://github.com/remix-run/react-router/blob/2e342cea7dadaa3418c828ce8cc5342d2c868bf4/packages/react-router/__tests__/generatePath-test.tsx
     * for generatePath()'s behavior.
     */

    // General settings page
    getGeneralSettingsPage: () => adminPaths.generalSettings,
    getBrandingSettingsPage: () => adminPaths.generalSettings,
    getDashboardSettingsPage: () => join(adminPaths.generalSettings, 'dashboard'),
    getLanguageSettingsPage: () => join(adminPaths.generalSettings, 'language'),
    getProfileCompletionSettingsPage: () => join(adminPaths.generalSettings, 'profile-completion'),

    getUserListPage: () => '/admin/users',
    getUserImportListPage: () => '/admin/user-imports',
    getUserRemovalRequestListPage: () => '/admin/user-removal-requests',

    // User agreements
    getNewUserAgreementFormUrl: () => join('/admin/user-agreements', getNewUserAgreementFormUrl()),
    getUserAgreementListPage: () => '/admin/user-agreements',
    getUserAgreementUrl: (id, options) => join('/admin/user-agreements', getUserAgreementUrl(id, options)) // prettier-ignore
  }
};
