import { memo, type ReactNode } from 'react';
import { type Location, NavLink, type To, useLocation } from 'react-router-dom';
import { hasValue } from '@/utils/common';
import { Link } from '../Link';
import * as css from './MenuLink.styles';

/** @public */
export { ACTIVE_CLASS_NAME } from './MenuLink.styles';

type Props = {
  children: ReactNode;
  isActive?: boolean | ((location: Location) => boolean);
  'aria-label'?: string;
  text: string;
  to: To;
};

export const MenuLink = memo(({ children, isActive: _isActive, text, ...props }: Props) => {
  const location = useLocation();

  if (hasValue(_isActive)) {
    const isActive = typeof _isActive === 'function' ? _isActive(location) : _isActive;

    return (
      <Link
        aria-current={isActive ? 'page' : undefined}
        css={isActive ? css.activeLink : css.link}
        data-text={text}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <NavLink {...props} css={css.menuLink} data-text={text}>
      {children}
    </NavLink>
  );
});
