import type { To } from 'react-router-dom';
import { useAppConfigValue } from '@/components/AppConfig';
import { Badge } from '@/components/Badge';
import { DropdownMenu } from '@/components/DropdownMenu';
import { FauxLinkButton } from '@/components/FauxLinkButton';
import { useI18n } from '@/components/I18n';
import { Icon } from '@/components/Icon';
import { Box, Flex } from '@/components/primitives';
import { ShowAt } from '@/components/Responsive';
import { UserAvatar } from '@/components/UserAvatar';
import type { UserWithAvatar } from '@/types';
import { getCsrfToken } from '@/utils/csrf';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';

export type User = UserWithAvatar & {
  draftContributionCount: number;
  ideationModuleRequestCount: number;
};

type Props = {
  ideationModuleRequestsRoute: To;
  logoutUrl: string;
  onLanguageSettingsClick: () => void;
  profileRoute: To;
  user: User;
};

export const AppHeaderUserDropdownMenu = ({
  ideationModuleRequestsRoute,
  logoutUrl,
  onLanguageSettingsClick,
  profileRoute,
  user
}: Props) => {
  const i18n = useI18n();
  const { getDraftsModal, getSubmittedUserAgreementsModal } = useSharedLocations('main');
  // When silent login is enabled users are unable to logout so we should hide
  // the logout button.
  // TODO: Putting this logic here is probably not the best approach since it
  // might affect other logout triggers too, but for now it will to.
  const isSilentLoginEnabled = useAppConfigValue('auth_silentLogin_enabled');

  return (
    <DropdownMenu
      menuButton={
        <DropdownMenu.MenuButton
          aria-label={i18n.t('common', 'userDropdownMenu.toggleMenuButton.helpText')}
          render={
            <FauxLinkButton
              color="textLight"
              fontSize="md"
              fontWeight="normal"
              hoverColor="text"
              lineHeight="tight"
              variant="no-underline"
            />
          }
        >
          <Flex alignItems="center" flexWrap="nowrap">
            <UserAvatar imageUrl={user.avatar?.previewUrl} user={user} />
            <ShowAt breakpoint="lg">
              <Box px="s02">
                {user.firstName}
                {user.lastName && <Box>{user.lastName}</Box>}
              </Box>
            </ShowAt>
            <Icon size={16} type="chevron-down" />
          </Flex>
        </DropdownMenu.MenuButton>
      }
    >
      <DropdownMenu.Item to={profileRoute}>
        {i18n.t('main', 'appNav.currentUserProfileLink.text')}
      </DropdownMenu.Item>
      <DropdownMenu.Item to={getDraftsModal()}>
        <Flex alignItems="center" justifyContent="space-between">
          {i18n.t('main', 'appNav.draftsLink.text')}
          {user.draftContributionCount > 0 && (
            <Badge aria-hidden color="blue" noMarginX noMarginY>
              {user.draftContributionCount}
            </Badge>
          )}
        </Flex>
      </DropdownMenu.Item>
      {user.ideationModuleRequestCount > 0 && (
        <DropdownMenu.Item to={ideationModuleRequestsRoute}>
          {i18n.t('challenge', 'appNav.ideationModuleRequestsLink.text')}
        </DropdownMenu.Item>
      )}
      <DropdownMenu.Divider />
      <DropdownMenu.Item onClick={onLanguageSettingsClick}>
        <Flex alignItems="center" justifyContent="space-between">
          <span>{i18n.t('main', 'appNav.languageSettingsLink.text')}</span>
          <Icon size={16} type="language" />
        </Flex>
      </DropdownMenu.Item>
      <DropdownMenu.Divider />
      <DropdownMenu.Item to={getSubmittedUserAgreementsModal()}>
        {i18n.t('main', 'userAgreement.label_multiple')}
      </DropdownMenu.Item>
      {!isSilentLoginEnabled && (
        <form action={logoutUrl} data-testid="logoutForm" method="post">
          <input name="csrf_token" type="hidden" value={getCsrfToken() ?? undefined} />
          {/**
           * Without `hideOnClick` the dropdown menu will be hidden (and
           * unmounted) before the event is handled (I think), which leads to
           * the form not getting submitted and a "Form submission canceled
           * because the form is not connected" to be printed to the console.
           */}
          <DropdownMenu.Item hideOnClick={false} render={<button type="submit" />}>
            {i18n.t('common', 'authentication.logoutLink.text')}
          </DropdownMenu.Item>
        </form>
      )}
    </DropdownMenu>
  );
};
