import type { I18n } from '@/components/I18n';
import { exhaustiveCheck } from '@/types/utils';

const ModuleSortOrders = [
  'CONTRIBUTION_COUNT_DESC',
  'DATE_CREATED_ASC',
  'DATE_CREATED_DESC',
  'TIMING_ASC'
] as const;

export type ModuleSortOrder = (typeof ModuleSortOrders)[number];

type ModuleSortOrderOption = {
  label: string;
  value: ModuleSortOrder;
};

function getSortOrderLabel(type: ModuleSortOrder, i18n: I18n): string {
  let label: string = type;

  if (type === 'TIMING_ASC') {
    label = i18n.t('common', 'sortOrder.sortByTiming.text');
  } else if (type === 'DATE_CREATED_ASC') {
    label = i18n.t('common', 'sortOrder.sortByOldest.text');
  } else if (type === 'DATE_CREATED_DESC') {
    label = i18n.t('common', 'sortOrder.sortByNewest.text');
  } else if (type === 'CONTRIBUTION_COUNT_DESC') {
    label = i18n.t('contribution', 'sortOrder.sortByMostContributions.text');
  } else {
    exhaustiveCheck(type);
  }

  return label;
}

export function getModuleSortOrderOptions(i18n: I18n): ModuleSortOrderOption[] {
  return Object.values(ModuleSortOrders).map(value => ({
    value,
    label: getSortOrderLabel(value, i18n)
  }));
}
