import { Fragment, type ReactNode } from 'react';
import { useI18n } from '@/components/I18n';
import { Card, Flex, Text } from '@/components/primitives';
import { Tooltip } from '@/components/Tooltip';
import {
  mapSizeToFontSize,
  mapSizeToRem,
  UserAvatar,
  type UserAvatarSize
} from '@/components/UserAvatar';
import type { Aria, UserWithAvatar } from '@/types';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { getFullName } from '@/utils/user';

type Props = {
  align?: 'center' | 'end' | 'start';
  aria?: Aria;
  emptyText?: string;
  maxAvatars?: number;
  showTooltip?: boolean;
  size?: UserAvatarSize;
  userCount?: number;
  users: UserWithAvatar[];
};

type WrapperProps = {
  children?: ReactNode;
  label?: string;
};

const UserAvatarWrapper = ({ children, label }: WrapperProps) => (
  <Flex aria-label={label} role="listitem">
    {children}
  </Flex>
);

export const UserAvatarList = ({
  align = 'start',
  aria,
  emptyText: _emptyText,
  maxAvatars,
  showTooltip = true,
  size = 'md',
  userCount,
  users = []
}: Props) => {
  const i18n = useI18n();
  const { getUserProfilePage } = useSharedLocations('main');
  const emptyText = _emptyText ?? i18n.t('common', 'compactUserList.empty.text');

  if (!users.length) return <>{emptyText}</>;

  const numUsers = userCount || users.length;
  const numVisibleUsers = maxAvatars ? Math.min(maxAvatars, users.length) : users.length;
  const avatars = [];

  for (let i = 0; i < numVisibleUsers; i += 1) {
    const user = users[i];
    const profileRoute = getUserProfilePage(user.id);
    const label = getFullName(user);

    avatars.push(
      <UserAvatarWrapper key={user.id}>
        <UserAvatar
          imageUrl={user.avatar?.previewUrl}
          route={profileRoute}
          size={size}
          tooltip={showTooltip ? label : undefined}
          user={user}
        />
      </UserAvatarWrapper>
    );
  }

  const overflowCount = numUsers - numVisibleUsers;
  const overflowUsers = maxAvatars ? users.slice(maxAvatars) : [];

  if (overflowCount > 0) {
    const label = `And ${overflowCount} more users`;

    let overflowContent = (
      <Card
        bg="neutral700"
        border={1}
        borderColor="white"
        css={{
          alignItems: 'center',
          borderRadius: `calc(${mapSizeToRem(size)} / 2)`,
          cursor: 'default',
          justifyContent: 'center'
        }}
        display="flex"
        height={mapSizeToRem(size)}
        minWidth={mapSizeToRem(size)}
        overflow="hidden"
        px={3}
      >
        <Text as="span" color="neutral100" fontSize={mapSizeToFontSize(size)} fontWeight="bold">
          +{overflowCount}
        </Text>
      </Card>
    );

    if (overflowUsers.length > 0) {
      const tooltipContent = overflowUsers.map(user => (
        <Fragment key={user.id}>
          {getFullName(user)}
          <br />
        </Fragment>
      ));

      overflowContent = <Tooltip content={tooltipContent}>{overflowContent}</Tooltip>;
    }

    avatars.push(
      <UserAvatarWrapper key="overflow" label={label}>
        {overflowContent}
      </UserAvatarWrapper>
    );
  }

  return (
    <Flex
      alignItems="center"
      aria-describedby={aria?.describedby}
      aria-label={aria?.label ?? i18n.t('common', 'user.label_multiple')}
      aria-labelledby={aria?.labelledby}
      flexWrap="wrap"
      justifyContent={align}
      role="list"
    >
      {avatars}
    </Flex>
  );
};
