import type { ComponentProps } from 'react';
import { Icon, type IconType } from '@/components/Icon';
import { Box } from '@/components/primitives';
import { Tooltip } from '@/components/Tooltip';
import type { ColorToken } from '@/css/types';
import { exhaustiveCheck } from '@/types/utils';

type IconProps = ComponentProps<typeof Icon>;

type Props = Omit<IconProps, 'color' | 'type'> & {
  helpText: string;
  isTabbable?: boolean;
  type?: 'error' | 'info' | 'warning';
};

export const StatusIcon = ({ helpText, isTabbable = true, type = 'info', ...props }: Props) => {
  let icon: IconType;
  let color: ColorToken;

  if (type === 'info') {
    icon = 'info';
    color = 'blue500';
  } else if (type === 'warning') {
    icon = 'alert-circle';
    color = 'yellow500';
  } else if (type === 'error') {
    icon = 'alert-circle';
    color = 'danger';
  } else {
    exhaustiveCheck(type, true);
  }

  return (
    <Tooltip content={helpText}>
      <Box display="inline-block" tabIndex={isTabbable ? 0 : undefined}>
        {/*
          The icon needs to be wrapped in a Flex because of Tooltip issues in IE11. No idea why.
          Probably something to do with the underlying SVG element which IE11 seems to dislike. Carry on.
        */}
        <Icon {...props} color={color} type={icon} />
      </Box>
    </Tooltip>
  );
};
