import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const spin = keyframes`
    0% { transform: rotate(0deg);   }
  100% { transform: rotate(360deg); }
`;

export type DonutSpinnerProps = {
  animationSpeed?: string;
  borderColor?: string;
  chipColor?: string;
  size: string;
  width: string;
};

export const DonutSpinner = styled.div<DonutSpinnerProps>(
  ({
    animationSpeed = '.75s',
    borderColor = 'currentColor',
    chipColor = 'transparent',
    size,
    width
  }) => ({
    animation: `${spin} ${animationSpeed} linear infinite`,
    borderColor,
    borderLeftColor: chipColor,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: width,
    display: 'inline-block',
    height: size,
    width: size
  })
);
