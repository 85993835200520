import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import type { Color } from '@/css/types';
import { getToken } from '@/css/utils';
import { variant } from '@/theme/utils';
import type { ResponsiveValue } from '../primitives/types';

export type ButtonSize = 'lg' | 'md' | 'sm';

export type ButtonTheme = {
  bgColor?: Color;
  bgColorHover?: Color;
  borderColor?: Color;
  borderColorHover?: Color;
  disabledBgColor?: Color;
  disabledBorderColor?: Color;
  disabledTextColor?: Color;
  textColor?: Color;
  textColorHover?: Color;
};

export type ButtonBaseProps = {
  isIconOnly?: boolean;
  isLoading?: boolean;
  size?: ResponsiveValue<ButtonSize>;
  stretch?: ResponsiveValue<boolean>;
  buttonTheme?: ButtonTheme;
};

export const ButtonBase = styled('button', { shouldForwardProp })<ButtonBaseProps>(
  ({ buttonTheme = {}, isLoading }) => ({
    alignItems: 'center',
    backgroundColor: buttonTheme.bgColor,
    borderColor: buttonTheme.borderColor,
    // Modern Edge has an implicit radius on buttons which we want to reset.
    borderRadius: 0,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: buttonTheme.textColor,
    cursor: 'pointer',
    display: 'flex',
    fontSize: getToken('fontSizes', 'md'),
    fontWeight: getToken('fontWeights', 'bold'),
    lineHeight: getToken('lineHeights', 'normal'),
    justifyContent: 'center',
    textAlign: 'center',
    textDecoration: 'none',
    transition: `
      color 150ms ease-in-out,
      background 150ms ease-in-out,
      box-shadow 150ms ease-in-out,
      border-color 150ms ease-in-out
    `,
    ':not([disabled]):hover': !isLoading
      ? {
          backgroundColor: buttonTheme.bgColorHover,
          borderColor: buttonTheme.borderColorHover,
          color: buttonTheme.textColorHover
        }
      : undefined,
    ':disabled': {
      backgroundColor: buttonTheme.disabledBgColor,
      borderColor: buttonTheme.disabledBorderColor,
      color: buttonTheme.disabledTextColor,
      cursor: 'not-allowed'
    }
  }),
  props =>
    variant<ButtonSize>({
      prop: 'size',
      variants: {
        sm: {
          fontSize: 'sm',
          height: '1.75rem',
          lineHeight: 'none',
          paddingLeft: getToken('space', props.isIconOnly ? 's01' : 's03'),
          paddingRight: getToken('space', props.isIconOnly ? 's01' : 's03')
        },
        md: {
          fontSize: 'md',
          height: '2.25rem',
          lineHeight: 'none',
          paddingLeft: getToken('space', props.isIconOnly ? 's02' : 's04'),
          paddingRight: getToken('space', props.isIconOnly ? 's02' : 's04')
        },
        lg: {
          fontSize: 'base',
          height: '2.625rem',
          lineHeight: 'none',
          paddingLeft: getToken('space', props.isIconOnly ? 's03' : 's05'),
          paddingRight: getToken('space', props.isIconOnly ? 's03' : 's05')
        }
      }
    }),
  variant<boolean>({
    prop: 'stretch',
    variants: {
      false: {
        display: 'flex',
        width: 'auto'
      },
      true: {
        display: 'block',
        width: '100%'
      }
    }
  }),
  variant<boolean>({
    prop: 'isLoading',
    variants: {
      true: {
        position: 'relative'
      }
    }
  })
);

ButtonBase.defaultProps = {
  isLoading: false,
  size: 'md',
  stretch: false
};
