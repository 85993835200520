import type { CSSProperties } from 'react';
import { exhaustiveCheck } from '@/types/utils';
import type { DropdownAlign } from './types';

function getCoordinates(align: DropdownAlign, offsetX = 0): CSSProperties {
  const coords: CSSProperties = {
    bottom: undefined,
    left: undefined,
    right: undefined,
    top: '100%'
  };

  if (align === 'bottom-end') {
    coords.right = offsetX;
  } else if (align === 'bottom-start') {
    coords.left = offsetX;
  } else {
    exhaustiveCheck(align, true);
  }

  return coords;
}

export function getContainerStyles(
  align: DropdownAlign,
  offsetX?: number,
  width = '250px',
  maxWidth?: string
): CSSProperties {
  return {
    ...getCoordinates(align, offsetX),
    maxWidth,
    position: 'absolute',
    width,
    zIndex: 3
  };
}
