/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Simple debounce implementation. Will call the given
 * function once after the time given has passed since
 * it was last called.
 * @param {Function} fn the function to call after the time
 * @param {Number} time the time to wait
 * @return {Function} the debounced function
 */
export function debounce(fn: (...args: any[]) => any, time: number) {
  let timeoutId: number | null = null;

  function cancel() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  function wrapper(...args: any[]) {
    cancel();
    timeoutId = window.setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }

  wrapper.cancel = cancel;

  return wrapper;
}
