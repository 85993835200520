import type { ReactElement } from 'react';
import { BlockListBuilder } from '@/components/BlockList';
import { Box, type BoxProps, Text } from '@/components/primitives';
import { useId } from '@/utils/hooks/useId';

type Props = Omit<BoxProps, 'aria-labelledby' | 'role'> & {
  items: ReactElement[];
  title: string;
};

export const SidebarNavGroup = ({ items, title, ...props }: Props) => {
  const id = useId('heading');

  return (
    <Box {...props} aria-labelledby={id} role="group">
      <Box mb="s03" pl="s08">
        <Text color="neutral200" fontWeight="bold" id={id}>
          {title}
        </Text>
      </Box>
      <BlockListBuilder aria={{ labelledby: id }} items={items} />
    </Box>
  );
};
