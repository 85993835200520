import { css, keyframes, type SerializedStyles } from '@emotion/react';
import { parseToHsl } from 'polished';
import { breakpoints } from '@/css/breakpoints';
import type { Breakpoint } from '@/css/types';
import { getToken } from '@/css/utils';
import { exhaustiveCheck } from '@/types/utils';
import { useAppConfigStore } from '../AppConfig';
import type { ModalMode, ModalSize } from './Modal';

function getHue() {
  return Math.round(
    parseToHsl(useAppConfigStore.getState().config.application_branding_primaryColor).hue
  );
}

export const fadeAnimation = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const bounceAnimation = keyframes`
    0% { opacity: 0; }
  100% { transform: none; }
`;

export const slideAnimation = keyframes`
    0% { transform: translate3d(100%, 0, 0); }
  100% { transform: translate3d(   0, 0, 0); }
`;

/**
 * All of these styles are a dynamic implementation from the static css version
 * that can be found here: https://codesandbox.io/s/crimson-cherry-bhtmi
 */

export const html = css({ overflow: 'hidden' });

export const body = css({
  // This overflow was added as some point to fix a double scrollbar issue in the task modal.
  // However, the introduction of this overflow causes a bug where the page scrolls to the top
  // whenever a modal is opened, rather than maintaining the current scroll position and overlaying
  // the modal on top. I removed the overflow again for testing purposes and was unable to reproduce
  // the initial double scrollbar bug, so perhaps another CSS change is preventing that one from
  // occurring, meaning this overflow can be safely removed.
  // overflow: 'hidden',
  position: 'relative',
  paddingLeft: 0,
  paddingTop: 0,
  paddingRight: 0,
  marginLeft: 0,
  marginTop: 0
});

export const portal = (zIndex: number) =>
  css({
    animation: `${fadeAnimation} 200ms ease-in-out`,
    backgroundColor: `hsla(${getHue()}, 20%, 15%, 0.65)`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex
  });

/**
 * The styles below used to be implemented in a slightly more modern way, but
 * this was causing various issues in IE. This can be reverted and simplified
 * again once we *completely* removed IE support. See commit with SHA
 * bd86cd8274020aa0954e8b26e4fc0816187dcfd7 for the diff.
 */

export function overlay(
  mode: ModalMode,
  centerY: boolean,
  fullscreenOnMobile: Breakpoint | boolean
): SerializedStyles {
  // Styles that should always be applied, regardless of mode.
  const baseStyle = css({
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto'
  });

  const fullscreenStyle = css({
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'stretch',
    flexDirection: 'column'
  });

  let modeStyle: SerializedStyles;

  if (mode === 'fullscreen') {
    modeStyle = fullscreenStyle;
  } else {
    if (mode === 'dialog') {
      modeStyle = css(
        {
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: getToken('space', 's05'),
          paddingRight: getToken('space', 's05')
        },
        centerY
          ? {
              alignItems: 'center',
              justifyContent: 'center'
            }
          : {
              paddingBottom: getToken('space', 's32'),
              paddingTop: getToken('space', 's32')
            }
      );
    } else if (mode === 'fit') {
      modeStyle = css(fullscreenStyle, {
        paddingBottom: getToken('space', 's08'),
        paddingLeft: getToken('space', 's08'),
        paddingRight: getToken('space', 's08'),
        paddingTop: getToken('space', 's08')
      });
    } else if (mode === 'sidesheet') {
      modeStyle = css({
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'stretch',
        flexDirection: 'column'
      });
    } else {
      exhaustiveCheck(mode);
    }

    if (fullscreenOnMobile) {
      // If mode is not "fullscreen" we have to juggle 2 styles: the fullscreen
      // style on anything below the mobile breakpoint, and the normal style on
      // anything above. In order to achieve this while also not having any
      // overlap or conflicts between the styles we can make use of an inverted
      // breakpoint which will basically toggle between the 2 styles seamlessly.
      const bp = typeof fullscreenOnMobile === 'string' ? fullscreenOnMobile : 'md';

      modeStyle = css({
        // Use an inverted media query to apply the fullscreen styles when the
        // breakpoint *has not* been matched.
        [`@media not all and (min-width: ${breakpoints[bp]})`]: fullscreenStyle,
        // Use a normal media query to apply the size-specific styles when the
        // breakpoint *has* been matched.
        [`@media (min-width: ${breakpoints[bp]})`]: modeStyle
      });
    }
  }

  return css(baseStyle, modeStyle);
}

function getDialogSize(size: ModalSize): number | string {
  let value: number | string;

  if (size === 'sm') {
    value = '28rem';
  } else if (size === 'md') {
    value = '36rem';
  } else if (size === 'lg') {
    value = '48rem';
  } else if (size === 'xl') {
    value = '62rem';
  } else {
    exhaustiveCheck(size);
  }

  return value;
}

export function dialog(
  mode: ModalMode,
  size: ModalSize,
  centerY: boolean,
  fullscreenOnMobile: Breakpoint | boolean
): SerializedStyles {
  const bp = typeof fullscreenOnMobile === 'string' ? fullscreenOnMobile : 'md';
  // No `baseStyle` here since it's already applied through the StyledModal.

  const fullscreenStyle = css({ flex: 1 });
  const scrollContentStyle = css({
    overflowX: 'hidden',
    overflowY: 'auto'
  });

  let style: SerializedStyles;

  if (mode === 'fullscreen') {
    style = css(fullscreenStyle, {
      // We *always* want the header to stick on mobile.
      [`@media not all and (min-width: ${breakpoints[bp]})`]: scrollContentStyle
    });
  } else {
    if (mode === 'dialog') {
      style = css({
        maxWidth: getDialogSize(size),
        width: '100%'
      });

      if (centerY) {
        style = css(style, {
          maxHeight: '75%',
          position: 'relative'
        });
      } else {
        style = css(style, {
          marginLeft: 'auto',
          marginRight: 'auto'
        });
      }
    } else if (mode === 'fit') {
      style = css({ flex: 1 });
    } else if (mode === 'sidesheet') {
      style = css({
        flex: 1,
        maxWidth: getDialogSize(size),
        width: '100%'
      });
    } else {
      exhaustiveCheck(mode);
    }

    if (fullscreenOnMobile) {
      // If mode is not "fullscreen" we have to juggle 2 styles: the fullscreen
      // style on anything below the mobile breakpoint, and the normal style on
      // anything above. In order to achieve this while also not having any
      // overlap or conflicts between the styles we can make use of an inverted
      // breakpoint which will basically toggle between the 2 styles seamlessly.
      // Once again we also want to force a sticky header on mobile so we apply
      // the overflow styles.
      style = css({
        // Use an inverted media query to apply the fullscreen styles when the
        // breakpoint *has not* been matched.
        [`@media not all and (min-width: ${breakpoints[bp]})`]: css(
          fullscreenStyle,
          scrollContentStyle
        ),
        // Use a normal media query to apply the size-specific styles when the
        // breakpoint *has* been matched.
        [`@media (min-width: ${breakpoints[bp]})`]: style
      });
    }
  }

  return style;
}
