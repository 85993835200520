import { forwardRef } from 'react';
import type { Color, ColorToken } from '@/css/types';
import { getColorToken } from '@/css/utils';
import { icons } from './icons';

export type IconType = keyof typeof icons;

type Props = {
  children?: never;
  color?: ColorToken;
  fillColor?: ColorToken;
  size?: number;
  type: IconType;
};

const style = {
  display: 'inline-block',
  flexShrink: 0,
  transition: 'stroke 150ms ease-out, fill 150ms ease-out',
  verticalAlign: 'middle'
};

export const Icon = forwardRef<SVGSVGElement, Props>(
  ({ color, fillColor, size = 20, type }, ref) => {
    let colorValue: Color = 'currentColor';
    let fillValue: Color = 'none';

    if (color) {
      colorValue = getColorToken(color);
    }

    if (fillColor) {
      fillValue = getColorToken(fillColor);
    }

    return (
      <svg
        ref={ref}
        aria-hidden
        data-icon-type={type}
        data-testid={`icon--${type}`}
        fill={fillValue}
        height={size}
        stroke={colorValue}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        style={style}
        viewBox="0 0 24 24"
        width={size}
      >
        {icons[type]}
      </svg>
    );
  }
);
