import { type FC, forwardRef, type ReactNode } from 'react';
import type { GetMenuPropsOptions } from 'downshift';
import { Dropdown } from '@/components/Dropdown';
import { Flex, Relative } from '@/components/primitives';
import { ContentComboboxOption } from './ContentComboboxOption';

type Props = {
  children?: ReactNode;
  isOpen: boolean;
};

type Component = (props: Props) => JSX.Element;

type StaticComponents = {
  Container: typeof ContentComboboxListContainer;
  Listbox: typeof ContentComboboxListbox;
  Option: typeof ContentComboboxOption;
};

export const ContentComboboxList: Component & StaticComponents = ({ children, isOpen }) => (
  <Dropdown isVisible={isOpen} showCaret={false} width="100%">
    {children}
  </Dropdown>
);

const ContentComboboxListContainer: FC = ({ children }) => <Relative>{children}</Relative>;

type ListboxProps = {
  getMenuProps: (options?: GetMenuPropsOptions) => Record<string, unknown>;
};

const ContentComboboxListbox = forwardRef<HTMLDivElement, ListboxProps>(
  ({ children, ...props }, ref) => (
    <Flex flexDirection="column" maxHeight="15rem" overflowY="auto" {...props} ref={ref}>
      {children}
    </Flex>
  )
);

ContentComboboxList.Container = ContentComboboxListContainer;
ContentComboboxList.Listbox = ContentComboboxListbox;
ContentComboboxList.Option = ContentComboboxOption;
