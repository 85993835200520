import { GraphQLError } from '@0no-co/graphql.web';
import type { WrappedError } from '@/components/ErrorAlertList';
import { getEmptyArray } from '../array';
import { removeEmptyElementsFromArray } from '../common';
import { UserNotAuthorizedError } from '../errors';

/**
 * RequestError code is based on the implementation of urql's CombinedError.
 * https://github.com/urql-graphql/urql/blob/main/packages/core/src/utils/error.ts
 */

function generateErrorMessage(networkErr?: Error, graphQlErrs?: GraphQLError[]) {
  let error = '';
  if (networkErr) return `[Network] ${networkErr.message}`;
  if (graphQlErrs) {
    for (const err of graphQlErrs) {
      if (error) error += '\n';
      error += `[GraphQL] ${err.message}`;
    }
  }
  return error;
}

function rehydrateGraphQlError(error: any): GraphQLError {
  if (error && error.message && (error.extensions || error.name === 'GraphQLError')) {
    return error;
  }

  if (typeof error === 'object' && error.message) {
    return new GraphQLError(
      error.message,
      error.nodes,
      error.source,
      error.positions,
      error.path,
      error.originalError,
      error.extensions
    );
  }

  return new GraphQLError(error);
}

export class RequestError extends Error {
  public name: string;
  public message: string;

  /** A list of GraphQL errors rehydrated from an `ExecutionResult`. */
  public graphQLErrors: GraphQLError[];

  /** Set to an error, if a GraphQL request has failed outright. */
  public networkError?: Error;

  /** Set to the {@link Response} object a fetch exchange received. */
  public response?: any;

  constructor(input: {
    networkError?: Error;
    graphQLErrors?: Array<Error | Partial<GraphQLError> | string>;
    response?: any;
  }) {
    const normalizedGraphQLErrors = (input.graphQLErrors || []).map(rehydrateGraphQlError);
    const message = generateErrorMessage(input.networkError, normalizedGraphQLErrors);

    super(message);

    this.name = 'RequestError';
    this.message = message;
    this.graphQLErrors = normalizedGraphQLErrors;
    this.networkError = input.networkError;
    this.response = input.response;
  }

  toString() {
    return this.message;
  }
}

function unwrapRequestError({ graphQLErrors, networkError }: RequestError): WrappedError[] {
  let errors: WrappedError[] = [];

  if (graphQLErrors.length > 0) {
    errors = graphQLErrors.map(error => {
      const obj: WrappedError = {
        message: error.message,
        error
      };

      // "extensions" can in fact be undefined depending on the GraphQL server implementation.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (typeof error.extensions?.code === 'string') {
        obj.code = error.extensions.code;
      }

      return obj;
    });
  }

  if (networkError) {
    if (networkError instanceof UserNotAuthorizedError && networkError.operationType === 'query') {
      throw networkError;
    }

    errors.push({
      message: networkError.message,
      error: networkError
    });
  }

  return errors;
}

export function parseRequestErrors(
  ...args: (Error | RequestError | null | undefined)[]
): WrappedError[] {
  const errors = removeEmptyElementsFromArray(args);

  return errors.length === 0
    ? getEmptyArray()
    : errors.reduce<WrappedError[]>(
        (result, error) =>
          result.concat(
            error instanceof RequestError
              ? unwrapRequestError(error)
              : [{ message: error.message, error }]
          ),
        []
      );
}
