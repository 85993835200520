import { type ChangeEventHandler, type SelectHTMLAttributes, useState } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { cssVar, tint } from 'polished';
import { padding } from 'styled-system';
import { useI18n } from '@/components/I18n';
import { Relative } from '@/components/primitives';
import caret from '@/components/Select/caret.svg';
import type { SpaceToken } from '@/css/types';
import { getColorToken, getToken, stripVar } from '@/css/utils';
import { variant } from '@/theme';
import type { ChoiceOption } from '@/types';
import { hasNonEmptyStringValue } from '@/utils/common';
import { useId } from '@/utils/hooks/useId';

export type MotifSelectChangeHandler<TOption extends ChoiceOption> = (
  value: TOption['value'] | null
) => void;

type Props<TOption extends ChoiceOption> = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  'aria-label' | 'aria-labelledby' | 'as' | 'children' | 'onChange' | 'placeholder'
> & {
  /** Allows the placeholder option to be selected again, effectively clearing the value. */
  isClearable?: boolean;
  onChange?: MotifSelectChangeHandler<TOption>;
  /** Options of the select. */
  options: TOption[];
  /** Text that will be shown in a placeholder option when no option is selected. */
  placeholder?: string;
  /** The (floating) label text. */
  label: string;
};

type MotifSelectBaseProps = {
  hasValue: boolean;
};

export const MotifSelectBase = styled('select', {
  shouldForwardProp
})<MotifSelectBaseProps>(
  {
    appearance: 'none',
    backgroundClip: 'padding-box',
    backgroundImage: `url(${caret})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1rem 1rem', // IE11 needs 2 units. Found out the hard way.
    border: 0,
    cursor: 'pointer',
    paddingTop: '1.375rem', // Arbitrary value to make the label fit properly.
    transition: 'background-color 150ms ease-out',
    ':disabled': {
      cursor: 'not-allowed'
    },
    '&::-ms-expand': {
      display: 'none'
    },
    backgroundColor: tint(0.2, cssVar(stripVar(getColorToken('grey200'))).toString()),
    backgroundPosition: `right ${getToken('space', 's03')} center`,
    color: getColorToken('textDark'),
    fontSize: getToken('fontSizes', 'md'),
    fontWeight: getToken('fontWeights', 'semibold'),
    paddingBottom: getToken('space', 's02'),
    paddingLeft: getToken('space', 's03'),
    paddingRight: getToken('space', 's10'),
    ':hover': {
      backgroundColor: getColorToken('grey200')
    }
  },
  variant<boolean>({
    prop: 'hasValue',
    variants: {
      false: {
        color: 'transparent',
        userSelect: 'none'
      },
      true: {
        // Putting the transition in this variant because we only want the color
        // to transition when fading into the value text, *not* when fading into
        // the placeholder text. We also need to copy over the initial bgColor
        // transition.
        transition: `
          background-color 150ms ease-out,
          color 200ms cubic-bezier(0.4, 0, 0.2, 1)
        `
      }
    }
  })
);

type MotifSelectLabelProps = {
  compact: boolean;
  left?: SpaceToken;
};

export const MotifSelectLabel = styled('label', {
  shouldForwardProp
})<MotifSelectLabelProps>(
  ({ left }) => ({
    color: getColorToken('text'),
    display: 'block',
    fontSize: getToken('fontSizes', 'md'),
    position: 'absolute',
    left: left ? getToken('space', left) : getToken('space', 's03'),
    lineHeight: getToken('lineHeights', 'none'),
    overflow: 'hidden',
    pointerEvents: 'none',
    right: getToken('space', 's06'),
    textOverflow: 'ellipsis',
    top: '50%',
    transform: 'translateY(-50%)',
    transformOrigin: 'left top',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
    userSelect: 'none',
    whiteSpace: 'nowrap'
  }),
  padding,
  variant<boolean>({
    prop: 'compact',
    variants: {
      true: {
        right: 0,
        transform: 'translateY(-100%) scale(0.85)'
      }
    }
  })
);

export const MotifSelect = <TOption extends ChoiceOption>({
  isClearable,
  label,
  onChange,
  options,
  placeholder: _placeholder,
  value,
  ...props
}: Props<TOption>) => {
  const i18n = useI18n();
  const labelId = useId('label');
  const placeholder = _placeholder || i18n.t('common', 'optionList.placeholder');
  const [hasValueOrDefaultValue, setHasValueOrDefaultValue] = useState(() =>
    hasNonEmptyStringValue(value || props.defaultValue)
  );
  const handleChange: ChangeEventHandler<HTMLSelectElement> = e => {
    setHasValueOrDefaultValue(hasNonEmptyStringValue(e.target.value));
    onChange?.(e.target.value || null);
  };

  return (
    <Relative display="inline-flex">
      <MotifSelectBase
        {...props}
        aria-labelledby={labelId}
        defaultValue={value === undefined ? props.defaultValue || '' : undefined}
        hasValue={hasValueOrDefaultValue}
        onChange={handleChange}
        value={value === null ? '' : value}
      >
        <option disabled={!isClearable} value="">
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </MotifSelectBase>
      <MotifSelectLabel compact={hasValueOrDefaultValue} id={labelId}>
        {label}
      </MotifSelectLabel>
    </Relative>
  );
};
