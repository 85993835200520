import { createBrowserRouter, Navigate } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import type { i18n } from 'i18next';
import { AuthenticationGuard } from '@/components/AuthenticationGuard';
import { RouteErrorAlert } from '@/components/ErrorAlert/RouteErrorAlert';
import type { AuthenticationClient } from '@/libs/cs-core-auth-client';
import { DashboardPage } from '@/pages/main/DashboardPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { userImportsLoader } from './routes/admin._user-management.user-imports/queries';
import { userInviteLinkLoader } from './routes/admin._user-management.user-invite-links.$inviteLinkId._index/queries';
import { userInviteLinkUsersLoader } from './routes/admin._user-management.user-invite-links.$inviteLinkId.users/queries';
import { userInviteLinksLoader } from './routes/admin._user-management.user-invite-links/queries';
import { userRemovalRequestLoader } from './routes/admin._user-management.user-removal-requests.$requestId/queries';
import { userRemovalRequestsLoader } from './routes/admin._user-management.user-removal-requests/queries';
import { editUserFormDataLoader } from './routes/admin._user-management.users.$userId.edit/queries';
import { usersLoader } from './routes/admin._user-management.users/queries';
import { communitiesLoader } from './routes/admin.communities/queries';
import { communityDataLoader } from './routes/admin.communities_.$communityId/queries';
import { editFieldTypeFormDataLoader } from './routes/admin.field-types.$fieldTypeId.edit/queries';
import { fieldTypesLoader } from './routes/admin.field-types/queries';
import { rolesLoader } from './routes/admin.roles/queries';
import { editUserClaimFormDataLoader } from './routes/admin.user-claims.$userClaimId.edit/queries';
import { editIdentityProviderFormDataLoader } from './routes/admin.user-claims.identity-providers.$identityProviderId.edit/queries';
import { userClaimSettingsLoader } from './routes/admin.user-claims/queries';
import { editUserGroupFormDataLoader } from './routes/admin.user-groups.$userGroupId.edit/queries';
import { userGroupsLoader } from './routes/admin.user-groups/queries';
import { userRegistrationSettingsLoader } from './routes/admin.user-registration/queries';
import { AppRoot } from './AppRoot';
import { AuthenticatedRoot } from './AuthenticatedRoot';
import { Root } from './Root';

export function createRouter(authClient: AuthenticationClient, i18nClient: i18n, debug: boolean) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  return createBrowserRouter([
    {
      path: '*',
      /** <Root> wraps the tree with an i18n & theming provider */
      element: <Root i18nClient={i18nClient} />,
      children: [
        {
          path: 'status/*',
          errorElement: <RouteErrorAlert />,
          lazy: async () => ({
            Component: (await import('@/pages/StatusPage')).StatusPage
          })
        },
        {
          path: 'invite/:invitationToken',
          errorElement: <RouteErrorAlert />,
          lazy: async () => ({
            Component: (await import('@/pages/AcceptInvitationPage')).AcceptInvitationPage
          })
        },
        {
          /**
           * <AuthenticatedRoot> wraps the tree with an Apollo, File API & DateFns
           * provider. Additionally, it short-circuits the tree if any pending global user
           * agreements are present.
           */
          element: (
            <AuthenticationGuard authenticationClient={authClient}>
              <AuthenticatedRoot authClient={authClient} debug={debug} queryClient={queryClient} />
            </AuthenticationGuard>
          ),
          errorElement: <RouteErrorAlert />,
          children: [
            {
              /**
               * <AppRoot> fetches the session user, renders the app shell, sets up focus
               * management, triggers an onboarding flow if necessary and mounts globally available
               * modals.
               */
              element: <AppRoot logoutUrl={authClient.config.logoutUrl} />,
              path: '*',
              children: [
                { index: true, element: <DashboardPage /> },
                { path: 'feed', element: <DashboardPage activeView="feed" /> },
                { path: 'programs', element: <DashboardPage activeView="programs" /> },
                { path: 'tasks', element: <DashboardPage activeView="tasks" /> },
                { path: '*', element: <NotFoundPage /> },
                {
                  path: 'communities/:communityId',
                  children: [
                    { index: true, element: <DashboardPage /> },
                    { path: 'feed', element: <DashboardPage activeView="feed" /> },
                    { path: 'programs', element: <DashboardPage activeView="programs" /> },
                    { path: 'tasks', element: <DashboardPage activeView="tasks" /> }
                  ]
                },
                {
                  path: 'contributions/:contributionId/*',
                  lazy: () => import('./routes/contributions.$contributionId/route')
                },
                {
                  path: 'modules/:moduleId',
                  lazy: () => import('./routes/modules.$moduleId/route')
                },
                {
                  path: 'programs/:programId/*',
                  lazy: () => import('./routes/programs.$programId/route')
                },
                {
                  path: 'search',
                  lazy: () => import('./routes/search/route')
                },
                {
                  path: 'profiles',
                  children: [
                    {
                      path: 'me/*',
                      lazy: () => import('./routes/profiles.me/route')
                    },
                    {
                      path: ':userId/*',
                      lazy: () => import('./routes/profiles.$userId/route')
                    }
                  ]
                },
                {
                  path: 'tasks',
                  children: [
                    {
                      path: 'enrichments/:moduleId/:contributionId?',
                      lazy: () => import('./routes/tasks.enrichments.$moduleId.($contributionId)/route') // prettier-ignore
                    },
                    {
                      path: 'reviews/:moduleId/:contributionId?',
                      lazy: () => import('./routes/tasks.reviews.$moduleId.($contributionId)/route')
                    }
                  ]
                },
                {
                  path: 'reports',
                  children: [
                    {
                      index: true,
                      lazy: () => import('./routes/reports._index/route')
                    },
                    {
                      path: ':reportId',
                      lazy: () => import('./routes/reports.$reportId/route')
                    }
                  ]
                },
                {
                  path: 'challenge-requests/*',
                  lazy: () => import('./routes/challenge-requests/route')
                },
                {
                  errorElement: <RouteErrorAlert />,
                  lazy: async () => ({
                    Component: (await import('@/features/configuration/containers/ConfigurationGuardContainer')).ConfigurationGuardContainer // prettier-ignore
                  }),
                  children: [
                    {
                      path: 'admin',
                      errorElement: <RouteErrorAlert />,
                      children: [
                        {
                          index: true,
                          element: <Navigate replace to="users" />
                        },
                        /** Field type routes. */
                        {
                          path: 'field-types',
                          loader: fieldTypesLoader(queryClient),
                          lazy: () => import('./routes/admin.field-types/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.field-types.new/route')
                            },
                            {
                              path: ':fieldTypeId/edit',
                              loader: editFieldTypeFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.field-types.$fieldTypeId.edit/route') // prettier-ignore
                            }
                          ]
                        },
                        /** Community routes. */
                        {
                          path: 'communities',
                          lazy: () => import('./routes/admin.communities/route'),
                          loader: communitiesLoader(queryClient),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.communities.new/route')
                            }
                          ]
                        },
                        {
                          path: 'communities/:communityId',
                          loader: communityDataLoader(queryClient),
                          lazy: () => import('./routes/admin.communities_.$communityId/route'), // prettier-ignore
                          children: [
                            {
                              path: '*',
                              lazy: () => import('./routes/admin.communities_.$communityId.$/route'), // prettier-ignore
                              children: [
                                {
                                  path: 'programs/new',
                                  lazy: () => import('./routes/admin.communities_.$communityId.$.programs.new/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'role-assignments',
                              lazy: () => import('./routes/admin.communities_.$communityId.role-assignments/route') // prettier-ignore
                            },
                            {
                              path: 'user-agreements/*',
                              lazy: () => import('./routes/admin.communities_.$communityId.user-agreements/route') // prettier-ignore
                            }
                          ]
                        },
                        {
                          path: 'communities/:communityId/programs/:programId/*',
                          lazy: () => import('./routes/admin.communities_.$communityId.programs.$programId/route') // prettier-ignore
                        },
                        /** Data management routes. */
                        {
                          path: 'data-management',
                          lazy: () => import('./routes/admin.data-management/route')
                        },
                        /** General settings routes. */
                        {
                          path: 'general-settings/*',
                          lazy: () => import('./routes/admin.general-settings/route')
                        },
                        /** User registration settings routes. */
                        {
                          path: 'user-registration',
                          loader: userRegistrationSettingsLoader(queryClient),
                          lazy: () => import('./routes/admin.user-registration/route')
                        },
                        /** User agreement routes. */ {
                          path: 'user-agreements/*',
                          lazy: () => import('./routes/admin.user-agreements/route')
                        },
                        /** User claim routes. */
                        {
                          path: 'user-claims',
                          loader: userClaimSettingsLoader(queryClient),
                          lazy: () => import('./routes/admin.user-claims/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.user-claims.new/route')
                            },
                            {
                              path: ':userClaimId/edit',
                              loader: editUserClaimFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.user-claims.$userClaimId.edit/route') // prettier-ignore
                            },
                            {
                              path: 'identity-providers/:identityProviderId/edit',
                              loader: editIdentityProviderFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.user-claims.identity-providers.$identityProviderId.edit/route') // prettier-ignore
                            }
                          ]
                        },
                        /** Integrations routes. */
                        {
                          path: 'integrations/*',
                          lazy: () => import('./routes/admin.integrations/route')
                        },
                        /** Role & role assignment routes. */
                        {
                          path: 'role-assignments',
                          lazy: () => import('./routes/admin.role-assignments/route')
                        },
                        {
                          path: 'roles',
                          loader: rolesLoader(queryClient),
                          lazy: () => import('./routes/admin.roles/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.roles.new/route')
                            }
                          ]
                        },
                        {
                          path: 'roles/:roleId',
                          lazy: () => import('./routes/admin.roles_.$roleId/route')
                        },
                        /** Report routes. */
                        {
                          path: 'reports',
                          children: [
                            {
                              path: 'data-sets',
                              lazy: () => import('./routes/admin.reports.data-sets/route')
                            },
                            {
                              path: 'kpis',
                              lazy: () => import('./routes/admin.reports.kpis/route')
                            }
                          ]
                        },
                        /** Slice template routes. */
                        {
                          path: 'slice-templates',
                          children: [
                            {
                              index: true,
                              lazy: () => import('./routes/admin.slice-templates._index/route')
                            },
                            {
                              path: ':sliceTemplateId/*',
                              lazy: () => import('./routes/admin.slice-templates.$sliceTemplateId/route') // prettier-ignore
                            }
                          ]
                        },
                        /** User management routes. */
                        {
                          lazy: () => import('./routes/admin._user-management/route'),
                          children: [
                            {
                              path: 'users',
                              loader: usersLoader(queryClient),
                              lazy: () => import('./routes/admin._user-management.users/route'),
                              children: [
                                {
                                  path: ':userId/edit',
                                  loader: editUserFormDataLoader(queryClient),
                                  lazy: () => import('./routes/admin._user-management.users.$userId.edit/route') // prettier-ignore
                                },
                                {
                                  path: 'new',
                                  lazy: () => import('./routes/admin._user-management.users.new/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'user-invite-links',
                              loader: userInviteLinksLoader(queryClient),
                              lazy: () => import('./routes/admin._user-management.user-invite-links/route'), // prettier-ignore
                              children: [
                                {
                                  path: ':inviteLinkId',
                                  loader: userInviteLinkLoader(queryClient),
                                  lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId/route'), // prettier-ignore
                                  children: [
                                    {
                                      index: true,
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId._index/route') // prettier-ignore
                                    },
                                    {
                                      path: 'edit',
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId.edit/route') // prettier-ignore
                                    },
                                    {
                                      path: 'logs',
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId.logs/route') // prettier-ignore
                                    },
                                    {
                                      path: 'users',
                                      loader: userInviteLinkUsersLoader(queryClient),
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId.users/route') // prettier-ignore
                                    }
                                  ]
                                },
                                {
                                  path: 'new',
                                  lazy: () =>
                                    import(
                                      './routes/admin._user-management.user-invite-links.new/route'
                                    )
                                }
                              ]
                            },
                            {
                              path: 'user-imports',
                              loader: userImportsLoader(queryClient),
                              lazy: () =>
                                import('./routes/admin._user-management.user-imports/route'),
                              children: [
                                {
                                  path: 'new',
                                  lazy: () => import('./routes/admin._user-management.user-imports.new/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'user-removal-requests',
                              loader: userRemovalRequestsLoader(queryClient),
                              lazy: () =>
                                import(
                                  './routes/admin._user-management.user-removal-requests/route'
                                ),
                              children: [
                                {
                                  path: ':requestId',
                                  loader: userRemovalRequestLoader(queryClient),
                                  lazy: () => import('./routes/admin._user-management.user-removal-requests.$requestId/route') // prettier-ignore
                                },
                                {
                                  path: 'new',
                                  lazy: () => import('./routes/admin._user-management.user-removal-requests.new/route') // prettier-ignore
                                },
                                {
                                  path: ':requestId/decline',
                                  lazy: () => import('./routes/admin._user-management.user-removal-requests.$requestId.decline/route') // prettier-ignore
                                }
                              ]
                            }
                          ]
                        },
                        {
                          path: 'user-groups',
                          loader: userGroupsLoader(queryClient),
                          lazy: () => import('./routes/admin.user-groups/route'),
                          children: [
                            {
                              path: ':userGroupId/edit',
                              loader: editUserGroupFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.user-groups.$userGroupId.edit/route') // prettier-ignore
                            },
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.user-groups.new/route')
                            }
                          ]
                        },
                        /** Program routes. */
                        {
                          path: 'programs',
                          children: [
                            {
                              index: true,
                              lazy: () => import('./routes/admin.programs._index/route')
                            },
                            {
                              path: ':programId/*',
                              lazy: () => import('./routes/admin.programs.$programId/route')
                            }
                          ]
                        },
                        /** Program template routes. */
                        {
                          path: 'program-templates',
                          children: [
                            {
                              index: true,
                              lazy: () => import('./routes/admin.program-templates._index/route')
                            },
                            {
                              path: ':programTemplateId/*',
                              lazy: () => import('./routes/admin.program-templates.$programTemplateId/route') // prettier-ignore
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'debug',
                      errorElement: <RouteErrorAlert />,
                      children: [
                        {
                          path: 'sandbox',
                          lazy: () => import('./routes/debug.sandbox/route')
                        },
                        {
                          path: 'graphql',
                          lazy: () => import('./routes/debug.graphql/route')
                        },
                        {
                          path: 'settings',
                          lazy: () => import('./routes/debug.settings/route')
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]);
}
