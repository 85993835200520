import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { queryOptions, userClaimKeys } from '../utils';
import { GetEditUserClaimFormData } from './graphql/GetEditUserClaimFormData.gql';

export const getEditUserClaimFormDataQueryOptions = (params: Params<'userClaimId'>) =>
  queryOptions({
    queryKey: userClaimKeys.userClaimById_edit(getRouteParam(params.userClaimId)),
    queryFn: ({ queryKey:[{userClaimId}] }) => fetchDocument(GetEditUserClaimFormData, { userClaimId }) // prettier-ignore
  });

export function editUserClaimFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getEditUserClaimFormDataQueryOptions(params));
}
