import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { UserInviteLinkFilterValues } from '@/features/user-invites/core/ui/components/UserInviteLinkListHeader';
import { fetchDocument } from '@/utils/api/fetcher';
import { parseMember } from '@/utils/api/parsers';
import { hasValue } from '@/utils/common';
import { LOADER_STALE_TIME, queryOptions, userInviteLinkKeys } from '../utils';
import {
  GetUserInviteLinks,
  type GetUserInviteLinksQuery,
  type GetUserInviteLinksQueryVariables
} from './graphql/GetUserInviteLinks.gql';

type QueryVariables = Pick<GetUserInviteLinksQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<UserInviteLinkFilterValues>;
};

export function getUserInviteLinksQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: userInviteLinkKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetUserInviteLinks, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'NAME_ASC',
  filter: {}
};

export function userInviteLinksLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getUserInviteLinksQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetUserInviteLinksQueryVariables {
  const {
    filter: { isEnabled },
    pagination: { page, size },
    sortOrder
  } = variables;

  return {
    pagination: { page, size },
    sortOrder,
    filter: hasValue(isEnabled) ? { isEnabled } : undefined
  };
}

export function parseData(data: GetUserInviteLinksQuery | undefined) {
  const userInviteLinks = data?.userInviteLinks.items ?? [];
  const viewer = data?.sessionUser;

  return {
    userInviteLinks: userInviteLinks.map(d => ({ ...d, groups: d.groups.map(parseMember) })),
    viewer: {
      canInviteUsers: Boolean(viewer?.canInviteUsers)
    }
  };
}
