import { memo, useCallback, useEffect, useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useAppConfig, useAppConfigStore } from '@/components/AppConfig';
import { useI18n } from '@/components/I18n';
import { SessionUserContext, type SessionUserContextValue } from '@/components/SessionUserContext';
import { useEventEmitter } from '@/utils/hooks/useEventEmitter';
import { get } from '@/utils/store';
import { App } from './App';
import { GetPermissions } from './GetPermissions.gql';
import { GetSessionUser } from './GetSessionUser.gql';

type Props = {
  logoutUrl: string;
};

function loadUpdateSessionUserLocaleMutation() {
  return import('./UpdateSessionUserLocaleId.gql').then(mod => mod.UpdateSessionUserLocaleId);
}

export const AppRoot = memo<Props>(({ logoutUrl }) => {
  const config = useAppConfig();
  const emitter = useEventEmitter();
  const apolloClient = useApolloClient();
  const { i18nClient } = useI18n();
  const { reloadConfig } = useAppConfigStore();

  const updateLocaleOnServer = useCallback(
    async (localeId: string) => {
      try {
        const mutation = await loadUpdateSessionUserLocaleMutation();
        await apolloClient.mutate({ mutation, variables: { input: { localeId } } });
        reloadConfig();
      } catch (e) {
        // TODO: Add some sort of toast message.
        console.error('Error while updating locale:', e); // eslint-disable-line no-console
      }
    },
    [apolloClient, reloadConfig]
  );

  const sessionUserResult = useQuery(GetSessionUser, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const cachedLocaleId = get('localeId');
      if (data.sessionUser?.localeId === null && cachedLocaleId) {
        updateLocaleOnServer(cachedLocaleId);
      }
    }
  });

  // Had to split this one up for now because it's a slow boy, and we want our
  // session user data as soon as possible.
  const permissionsResult = useQuery(GetPermissions, { fetchPolicy: 'network-only' });

  const { refetch } = sessionUserResult;
  const refetchSessionUser = useCallback(() => refetch(), [refetch]);

  /**
   * Set up listeners for draft changes. Any time a draft is deleted or
   * published the session user data will be refetched, including the current
   * draft count.
   */
  useEffect(() => {
    emitter.on('draftDeleted', refetchSessionUser);
    emitter.on('draftPublished', refetchSessionUser);
    emitter.on('ideationModuleRequestAdded', refetchSessionUser);

    return () => {
      emitter.off('draftDeleted', refetchSessionUser);
      emitter.off('draftPublished', refetchSessionUser);
      emitter.off('ideationModuleRequestAdded', refetchSessionUser);
    };
  }, [emitter, refetchSessionUser]);

  /**
   * Set up listeners for locale changes. Any time the user changes their locale
   * it will synchronize the new locale with the server and subsequently reload
   * the app config, while also updating the cached locale.
   */
  useEffect(() => {
    async function onLanguageChanged(locale: string) {
      await updateLocaleOnServer(locale);
    }

    i18nClient?.on('languageChanged', onLanguageChanged);
    return () => i18nClient?.off('languageChanged', onLanguageChanged);
  }, [i18nClient, updateLocaleOnServer]);

  // Combine session user & permission data into the session user context value.
  const sessionUser = sessionUserResult.data?.sessionUser;
  const permissions = permissionsResult.data?.sessionUser;
  const parsedSessionUser: SessionUserContextValue = useMemo(
    () => ({
      __hasLoadedPermissionData: Boolean(permissions),
      __hasLoadedUserData: Boolean(sessionUser),
      avatar: sessionUser?.avatar ?? null,
      canEditProfile: Boolean(permissions?.canEditProfile),
      canManageConfiguration: Boolean(permissions?.canManageConfiguration),
      canManageDataProtectionSettings: Boolean(permissions?.canManageDataProtectionSettings),
      canManageRolesAndPermissions: Boolean(permissions?.canManageRolesAndPermissions),
      canViewDataSets: Boolean(permissions?.canViewDataSets),
      canViewKpis: Boolean(permissions?.canViewKpis),
      canViewReports: Boolean(
        permissions?.canViewCommunityReports ||
          permissions?.canViewGlobalReports ||
          permissions?.canViewModuleReports ||
          permissions?.canViewProgramReports
      ),
      contributionCount: sessionUser?.contributionCount ?? 0,
      draftContributionCount: sessionUser?.draftContributionCount ?? 0,
      email: sessionUser?.email ?? null,
      firstName: sessionUser?.firstName ?? null,
      hasToCompleteProfile: Boolean(sessionUser?.hasToCompleteProfile),
      ideationModuleRequestCount: sessionUser?.ideationModuleRequests.totalCount ?? 0,
      isDataProtectionOfficer: Boolean(permissions?.isDataProtectionOfficer),
      lastName: sessionUser?.lastName ?? null,
      localeId: sessionUser?.localeId ?? null,
      taskCount: sessionUser?.taskCount ?? 0,

      // We need to get the id from the initially fetched config in order to
      // avoid executing queries with an empty string id (coming from
      // DEFAULT_SESSION_USER) while the session user query is fetching.
      id: config.viewer_userId
    }),
    [config, permissions, sessionUser]
  );

  const officerCount =
    permissionsResult.data?.dataProtectionSettings?.dataProtectionOfficerCount ?? 0;

  return (
    <SessionUserContext.Provider value={parsedSessionUser}>
      <App
        hasDpoAssigned={officerCount > 0}
        logoutUrl={logoutUrl}
        onLanguageSelected={refetchSessionUser}
        onProfileCompleted={refetchSessionUser}
      />
    </SessionUserContext.Provider>
  );
});
