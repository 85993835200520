import type { Module } from '../../components/ModuleCard';
import type { ModuleCardFragment } from './ModuleCardFragment.gql';

export const parseModuleCardFragment = (module: ModuleCardFragment): Module => ({
  communityId: module.communityId,
  endDate: module.endDate,
  id: module.id,
  image: module.image,
  programId: module.programId,
  programTitle: module.programTitle,
  startDate: module.startDate,
  status: module.status,
  // Give precedence to the (plain text) summary if it exists, if not, fall
  // back to the (stripped) description.
  summary: module.summary || module.description,
  title: module.title,
  totalContributionCount:
    'totalContributionCount' in module ? module.totalContributionCount || 0 : 0,
  type: module.type,
  viewer: {
    canAddContribution:
      'viewer' in module && 'canAddContribution' in module.viewer
        ? module.viewer.canAddContribution
        : false
  }
});
