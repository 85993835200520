import type { ReactNode } from 'react';
import { MenuItem, type MenuItemProps } from '@ariakit/react';
import styled from '@emotion/styled';
import type { ColorToken } from '@/css/types';
import { getColorToken, getToken } from '@/css/utils';

type Props = MenuItemProps & {
  children: ReactNode;
  disabled?: boolean;
  textColor?: ColorToken;
  textHoverColor?: ColorToken;
};

export const StyledDropdownMenuItem = styled(MenuItem, {
  shouldForwardProp: props => props !== 'textColor' && props !== 'textHoverColor'
})<Props>(props => ({
  color: getColorToken(props.textColor ?? 'textLight'),
  background: 'none',
  border: 'none',
  borderRadius: getToken('radii', 'sm'),
  margin: 0,
  padding: 0,
  display: 'block',
  fontSize: getToken('fontSizes', 'md'),
  lineHeight: getToken('lineHeights', 'tight'),
  textAlign: 'left',
  textDecoration: 'none',
  width: '100%', // Mainly to fix the button's implicit "auto" width, even when display=block.
  ':hover, &[data-focus-visible]': {
    backgroundColor: props.disabled ? undefined : getColorToken('grey200'),
    color: getColorToken(props.textHoverColor ?? 'textDark'),
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    textDecoration: 'none'
  },
  ':focus': {
    outline: 0
  }
}));
