import { memo, useEffect, useState } from 'react';
import type { Locale } from 'date-fns';
import { exhaustiveCheck } from '@/types/utils';
import { DateFnsLocaleContext } from './DateFnsLocaleContext';
import type { SupportedLanguage } from './types';
import { useI18n } from './useI18n';
import { isSupportedLanguage } from './utils';

// Before date-fns v3 we managed the dynamic loading by using a dynamic import
// containing a dynamic expression (i.e. "import(`locale/${language}`)").
// However, as of v3 this does not appear to work properly anymore so we needed
// a (temporary) workaround. I might even prefer the approach below because it
// is still type-safe due to use of `SupportedLanguage`, and it requires less
// wiring up in the webpack config in order to filter out unused languages.
async function loadLocale(localeId: SupportedLanguage['code']): Promise<Locale | null> {
  if (localeId === 'en') return Promise.resolve(null);
  if (localeId === 'de') return import(/* webpackChunkName: "date-locales/de" */'date-fns/locale/de').then(res => res.de); // prettier-ignore
  if (localeId === 'es') return import(/* webpackChunkName: "date-locales/es" */'date-fns/locale/es').then(res => res.es); // prettier-ignore
  if (localeId === 'fr') return import(/* webpackChunkName: "date-locales/fr" */'date-fns/locale/fr').then(res => res.fr); // prettier-ignore
  if (localeId === 'it') return import(/* webpackChunkName: "date-locales/it" */'date-fns/locale/it').then(res => res.it); // prettier-ignore
  if (localeId === 'ja') return import(/* webpackChunkName: "date-locales/ja" */'date-fns/locale/ja').then(res => res.ja); // prettier-ignore
  if (localeId === 'nl') return import(/* webpackChunkName: "date-locales/nl" */'date-fns/locale/nl').then(res => res.nl); // prettier-ignore
  if (localeId === 'pl') return import(/* webpackChunkName: "date-locales/pl" */'date-fns/locale/pl').then(res => res.pl); // prettier-ignore

  exhaustiveCheck(localeId);
  // @ts-expect-error: In theory we could receive an unsupported language here
  // in which case we want to clear any active locale.
  return Promise.resolve(null);
}

export const DateFnsLocaleProvider = memo(({ children }) => {
  const { language } = useI18n();
  const [locale, setLocale] = useState<Locale | null>(null);

  useEffect(() => {
    if (language && isSupportedLanguage(language)) {
      loadLocale(language).then(setLocale);
    }
  }, [language]);

  return <DateFnsLocaleContext.Provider value={locale}>{children}</DateFnsLocaleContext.Provider>;
});
