import {
  createContext,
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useI18n } from '@/components/I18n';
import { exhaustiveCheck } from '@/types/utils';
import { isFocusableElement } from '@/utils/getFocusableElements';
import { useLocationTracker } from '@/utils/hooks/useLocationTracker';
import { type LogFunction, useLogger } from '@/utils/hooks/useLogger';
import { isElementInViewport } from '@/utils/isElementInViewport';
import { getBoolean } from '@/utils/store';
import { isJSDOM } from '@/utils/userAgent';
import { VisuallyHidden } from '../VisuallyHidden';

const DEFAULT_FOCUS_DELAY = 250;

type Props = {
  children: ReactNode;
  defaultFocusDelayInMs?: number;
};

// Not putting this in the userAgent helper just yet because I'm not entirely
// sure just how reliable this one is. Generally we also don't need a safari
// sniffer.
// https://stackoverflow.com/a/31732310
const isSafari =
  navigator.vendor.includes('Apple') &&
  navigator.userAgent &&
  !navigator.userAgent.includes('CriOS') &&
  !navigator.userAgent.includes('FxiOS') &&
  !isJSDOM();

type FocusScrollBehavior =
  | 'restoreScrollPosition'
  | 'scrollIntoView'
  | 'scrollIntoViewIfNeeded'
  | 'scrollToTop';

export interface RequestFocusOptions {
  /**
   * Delay the focus with an amount of milliseconds equal to the number given.
   * @default 250 See `DEFAULT_FOCUS_DELAY`.
   */
  delayInMs?: number;
  /**
   * Will look _inside_ the given element for a more suitable focus target. This
   * can be either a nav or heading (h1-h6) element.
   * @default true
   */
  lookupNestedTarget?: boolean;
  /**
   * The scroll behavior to be performed after focus has been handed out.
   * @default scrollIntoViewIfNeeded
   */
  scrollBehavior?: FocusScrollBehavior;
  /**
   * Stops propagation of the focus handler. Under normal conditions this means
   * that any parent focus handlers would be ignored if the current focus
   * handler triggers.
   * @default false
   */
  stopPropagation?: boolean;
}

type GuidedFocusContextValue = {
  /**
   * Announces the given title to a screen reader. If a focus has already been
   * requested and given out, the page navigation will immediately be announced.
   * If no focus has been requested or has been requested but has not yet been
   * given out, the announcement will be delayed until right after the focus
   * occurs.
   * @param title The page title to announce.
   */
  announcePageNavigation(title: string): void;
  /**
   * Requests a focus on a given element. A focus request will only be fulfilled
   * when no focus has been given out yet for the current location. If multiple
   * components request focus for the same location, focus will be given out
   * to the top-most element in the DOM.
   * @param element The element to receive focus.
   * @param options The focus request options.
   */
  requestFocus(element: HTMLElement, options?: RequestFocusOptions): void;
};

function noopContext() {
  if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.error('No context found, please put a <GuidedFocusManager> at the top of your app.');
  }
}

export const GuidedFocusContext = createContext<GuidedFocusContextValue>({
  announcePageNavigation: noopContext,
  requestFocus: noopContext
});

function elementPreceedsPreviousElement(elem: HTMLElement, prevElem: HTMLElement) {
  return (
    // eslint-disable-next-line no-bitwise
    prevElem.compareDocumentPosition(elem) &
    // eslint-disable-next-line no-bitwise
    (Node.DOCUMENT_POSITION_PRECEDING | Node.DOCUMENT_POSITION_CONTAINS)
  );
}

/**
 * Focuses an element and performs the requested scroll behavior.
 * @param logger A function to log the debug output to.
 * @param element The element to receive focus.
 * @param lookupNestedTarget If true, will look inside the given element for a
 * more suitable element to receive focus (nav or h1-h6).
 * @param scrollBehavior The desired scroll behavior to be performed after the focus.
 */
export function focusElement(
  logger: LogFunction,
  element: HTMLElement,
  lookupNestedTarget = true,
  scrollBehavior: FocusScrollBehavior = 'scrollIntoViewIfNeeded'
) {
  let target = element;

  if (lookupNestedTarget) {
    // It might seem sensible to include interactive targets here as well (a,
    // button, ...). The fact is that you generally always expect a heading to
    // be at the top of a dynamic content box, and if this is not the case
    // this probably means one of two things:
    //   a. The content box is badly structured and includes no heading.
    //   b. The content box is being lazy-loaded and not yet ready at the time
    //      of focus.
    // If we start allowing other interactive elements in this list we might end
    // up with focus jumping a lot "deeper" than it should (especially in case
    // b.), so for now limiting this list seems better. If no matching element
    // is found now it will instead focus the wrapper, which is a sensible
    // fallback (and often a problematic one for VO/Safari but at that point we
    // don't care).
    // There might be genuine use-cases later on where we do want something
    // other than a nav or h1-h6 to receive focus. If such a case presents
    // itself we can reevaluate this and possibly implement a solution that in
    // such cases allows us to provide a custom selector we would like this code
    // to use, as an escape hatch.
    const nestedTarget = element.querySelector('nav,[role="nav"],h1,h2,h3,h4,h5,h6,input');

    if (nestedTarget && nestedTarget instanceof HTMLElement) {
      logger('log', 'Moving focus to *nested* element:', nestedTarget);
      target = nestedTarget;
    } else {
      logger('log', 'Moving focus to element:', element);
    }
  }

  if (!isFocusableElement(target)) {
    target.setAttribute('tabIndex', '-1');
  }

  const { pageYOffset } = window;

  target.focus({ preventScroll: true });

  if (scrollBehavior === 'scrollIntoViewIfNeeded') {
    // Ideally you would like to use element.scrollIntoViewIfNeeded(), but that
    // is not on a standards track so is not a good idea.
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
    if (!isElementInViewport(target)) {
      target.scrollIntoView();
    }
  } else if (scrollBehavior === 'scrollToTop') {
    window.scrollTo(0, 0);

    // Safari has this weird behavior where in certain cases (not exactly sure
    // which) it performs a scroll to the target that was focused, *in a
    // separate task*. This effectively means that doing window.scrollTo(0, 0)
    // immediately after a focus() will be overridden by the implicit scroll
    // task that will be run in Safari after this code block executed.
    // Safari also does not support FocusOptions (preventScroll) so we can't
    // prevent this scroll from happening at all.
    // The best way around this I've found is by running our own setTimeout task
    // that checks if the scroll is where we expect it to be, and performing
    // another scrollTo(0, 0) if necessary.
    setTimeout(() => {
      if (window.pageYOffset > 0) {
        window.scrollTo(0, 0);
      }
    }, 0);
  } else if (scrollBehavior === 'restoreScrollPosition') {
    window.scrollTo(0, pageYOffset);
  } else if (scrollBehavior === 'scrollIntoView') {
    target.scrollIntoView();
  } else {
    exhaustiveCheck(scrollBehavior);
  }
}

type NavigationAnnouncerOptions = {
  logger: LogFunction;
  mode?: 'assertive' | 'polite';
};

function useNavigationAnnouncer({ logger, mode = 'polite' }: NavigationAnnouncerOptions) {
  const timerRef = useRef<number>();
  const statusRef = useRef<HTMLSpanElement>(null);
  const [status, setStatus] = useState<string | null>(null);
  const loggerRef = useRef(logger);

  const setStatusText = useCallback((text: string, immediate = false) => {
    // For reasons unknown, VO in Safari refuses to consistently announce the
    // title when it happens in conjunction with a focus(). It could be because
    // VO delays the focus announcement until the focus frame is correctly
    // positioned, coinciding with VO seemingly having a harder time to position
    // the focus frame in Safari. We also know that focus is announced as an alert
    // by a screen reader which is the reason we put the navigation announcement
    // _after_ the focus announcement so that the navigation can immediately
    // interrupt the focus, instead of the other way around.
    // However, if the focus is delayed for any reason, the focus can instead
    // interrupt the navigation again, which might be what is happening in Safari.
    // We're unable to reproduce this behavior in any other browser so we consider
    // this to be a Safari-only issue.
    // The workaround is simple: If we detect the current browser to be Safari,
    // we'll put an arbitrary delay (currently 250ms) on the navigation
    // announcement so that the focus event has the chance to properly be picked
    // up by VO. In any other browsers we'll just immediately announce the
    // navigation. This seems to mostly fix the issue, except in some rarer cases
    // where it's still not announced, but we'll ignore that for now.
    clearTimeout(timerRef.current);

    timerRef.current = window.setTimeout(
      () => {
        loggerRef.current('log', `Announcing page navigation with text "${text}".`);
        setStatus(text);
        timerRef.current = window.setTimeout(() => setStatus(null), 500);
      },
      isSafari && !immediate ? 250 : 0
    );
  }, []);

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const statusElement = (
    <VisuallyHidden
      ref={statusRef}
      aria-atomic
      aria-live={mode}
      aria-relevant="additions text"
      role="status"
    >
      {status}
    </VisuallyHidden>
  );

  return [statusElement, setStatusText] as const;
}

export const GuidedFocusManager = memo(
  ({ children, defaultFocusDelayInMs = DEFAULT_FOCUS_DELAY }: Props) => {
    const i18n = useI18n();
    const initialPageLoadRef = useRef(true);
    const logger = useLogger('GuidedFocusManager', getBoolean('isA11yDebugEnabled', false));
    const [status, setStatus] = useNavigationAnnouncer({ mode: 'assertive', logger });

    /**
     * The below refs can be considered transient "state" of the
     * GuidedFocusManager that gets reset on every history.location change. Why
     * refs and no state? Because the "requestFocus" context method can be
     * called multiple times in quick succession, each call possibly affecting
     * the next. if we were to use state instead of refs we would not be able
     * to update it in time.
     */

    /**
     * Keeps track of the current focus status.
     *  - `idle`: No focus has been requested yet.
     *  - `timerStarted`: Focus has been requested but not yet given out.
     *  - `done`: Focus has been requested and given out.
     */
    const focusStatusRef = useRef<'done' | 'idle' | 'timerStarted'>('idle');
    /** Most recent element passed to the requestFocus() call. */
    const focusTargetRef = useRef<HTMLElement | null>(null);
    /** Blocks any subsequent focus requests when set to `true`. */
    const stopPropagationRef = useRef(false);
    /** Stores a page title to be announced as soon as the focus is given out. */
    const delayedPageAnnouncementRef = useRef<string | null>(null);
    /** Whether or not a page has been announced for the current location. */
    const pageAnnouncedRef = useRef(false);
    /** Timer that gets populated when a focus is requested. */
    const timerRef = useRef<number>();

    const requestFocus: GuidedFocusContextValue['requestFocus'] = useCallback(
      (target, options = {}) =>
        // queueMicrotask is useful to fix various race conditions when focus
        // management is involved. In this particular case we often run into the
        // problem that the useLocationTracker() handler after a location change
        // only gets invoked _after_ `requestFocus()` gets invoked by a component,
        // which completely messes up the focus management and page announcement.
        // I think this problem got introduced in v6 of react-router, as I do
        // remember this working correctly when initially implemented. By
        // leveraging queueMicrotask we can ensure that the actual focus request
        // only gets executed after the location change has been fully processed.
        queueMicrotask(() => {
          const existingTarget = focusTargetRef.current;

          if (initialPageLoadRef.current) {
            logger('log', 'Received a focus request while focus is disallowed.', target);
          } else if (stopPropagationRef.current) {
            logger('log', 'Focus propagation has been disabled by an earlier focus request.');
          } else if (!existingTarget || elementPreceedsPreviousElement(target, existingTarget)) {
            stopPropagationRef.current = Boolean(options.stopPropagation);
            focusStatusRef.current = 'timerStarted';
            focusTargetRef.current = target;

            const delayInMs = options.delayInMs ?? defaultFocusDelayInMs;

            logger('log', 'Focus requested, starting focus timer.', target, options, delayInMs);

            clearTimeout(timerRef.current);

            timerRef.current = window.setTimeout(() => {
              focusStatusRef.current = 'done';

              focusElement(logger, target, options.lookupNestedTarget, options.scrollBehavior);

              if (delayedPageAnnouncementRef.current) {
                setStatus(
                  i18n.t('common', 'screenReader.navigatedToNewPage.text_withTitle', {
                    title: delayedPageAnnouncementRef.current
                  })
                );
                pageAnnouncedRef.current = true;
                delayedPageAnnouncementRef.current = null;
              }
            }, delayInMs);
          }
        }),
      [defaultFocusDelayInMs, i18n, logger, setStatus]
    );

    const announcePageNavigation: GuidedFocusContextValue['announcePageNavigation'] = useCallback(
      title => {
        if (
          !pageAnnouncedRef.current &&
          (focusStatusRef.current === 'done' || initialPageLoadRef.current)
        ) {
          const text = title
            ? i18n.t('common', 'screenReader.navigatedToNewPage.text_withTitle', { title })
            : i18n.t('common', 'screenReader.navigatedToNewPage.text');

          setStatus(text, true);
          pageAnnouncedRef.current = true;
          delayedPageAnnouncementRef.current = null;
        } else if (!pageAnnouncedRef.current) {
          logger('log', 'Delaying page navigation announcement until after focus.');
          delayedPageAnnouncementRef.current = title;
        } else {
          logger('log', 'Page navigation already announced for the current location, skipping...'); // prettier-ignore
        }
      },
      [i18n, logger, setStatus]
    );

    // We need to reinitialize all necessary refs when a user navigates to another
    // page.
    useLocationTracker(() => {
      delayedPageAnnouncementRef.current = null;
      pageAnnouncedRef.current = false;
      focusTargetRef.current = null;
      focusStatusRef.current = 'idle';
      stopPropagationRef.current = false;

      if (initialPageLoadRef.current) {
        initialPageLoadRef.current = false;
      }

      clearTimeout(timerRef.current);
    });

    useEffect(() => () => clearTimeout(timerRef.current), []);

    const contextValue: GuidedFocusContextValue = useMemo(
      () => ({ announcePageNavigation, requestFocus }),
      [announcePageNavigation, requestFocus]
    );

    return (
      <GuidedFocusContext.Provider value={contextValue}>
        {children}
        {status}
      </GuidedFocusContext.Provider>
    );
  }
);
