import { memo, type ReactNode, useCallback } from 'react';
import { useJargon } from '@/components/AppConfig';
import { AutoPlacementGrid, type GridProps } from '@/components/AutoPlacementGrid';
import { EmptyListMessage } from '@/components/EmptyListMessage';
import { ErrorAlertList, type WrappedError } from '@/components/ErrorAlertList';
import { lookupJargonTranslation, useI18n } from '@/components/I18n';
import { LoadingMessage } from '@/components/LoadingMessage';
import { Box } from '@/components/primitives';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { AddContributionFormWizardModal } from '@/features/contributions/views/AddContributionFormWizardModal';
import { useValue } from '@/utils/hooks/useValue';
import { type Module, ModuleCard, ModuleCardSkeleton } from '../ModuleCard';

export type { Module };

type Props = GridProps & {
  errors?: WrappedError[];
  isLoading: boolean;
  modules: Module[];
  placeholderCount: number;
  showProgram?: boolean;
};

type ValueProps = {
  communityId: string;
  moduleId: string;
  programId: string;
};

export const ModuleGrid = memo<Props>(
  ({ columnCount, errors, gap, isLoading, modules, placeholderCount, showProgram = true }) => {
    const i18n = useI18n();
    const { clear: clearValue, value, set: setValue } = useValue<ValueProps>();
    const programLabel = lookupJargonTranslation<'program'>(useJargon().program, {
      PROGRAM: () => i18n.t('main', 'program.label')
    });

    const hasErrors = Boolean(errors?.length);
    const onRequestAddContribution = useCallback(
      (communityId: string, programId: string, moduleId: string) => {
        setValue({ communityId, moduleId, programId });
      },
      [setValue]
    );

    let content: ReactNode;

    if (isLoading && modules.length === 0) {
      content = (
        <>
          <VisuallyHidden>
            <LoadingMessage />
          </VisuallyHidden>
          <AutoPlacementGrid columnCount={columnCount} gap={gap}>
            {Array.from({ length: placeholderCount }, (_, index) => (
              <ModuleCardSkeleton key={index} />
            ))}
          </AutoPlacementGrid>
        </>
      );
    } else if (modules.length > 0) {
      content = (
        <>
          {value && (
            <AddContributionFormWizardModal
              communityId={value.communityId}
              moduleId={value.moduleId}
              onRequestClose={clearValue}
              onSubmitted={clearValue}
              programId={value.programId}
            />
          )}
          <AutoPlacementGrid columnCount={columnCount} gap={gap}>
            {modules.map((module, index) => (
              <ModuleCard
                key={index}
                module={module}
                onRequestAddContribution={onRequestAddContribution}
                programLabel={programLabel}
                showProgram={showProgram}
              />
            ))}
          </AutoPlacementGrid>
        </>
      );
    } else {
      content = <EmptyListMessage />;
    }

    return (
      <>
        {hasErrors && (
          <Box mb="s04">
            <ErrorAlertList errors={errors} />
          </Box>
        )}
        {content}
      </>
    );
  }
);
