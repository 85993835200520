import type { ReactNode } from 'react';
import type { MemberOption } from '@/components/PeoplePicker';
import type { BaseUserFragment } from '@/features/common/graphql/fragments/BaseUser/BaseUserFragment.gql';
import type { FieldValueFragment } from '@/features/common/graphql/fragments/FieldValueFragment.gql';

// prettier-ignore
export type Aria = {
  describedby?: string;
} & (
  | { label: string; labelledby?: never }
  | { label?: never; labelledby: string }
);

export interface KeyValuePair {
  key: string;
  value: string;
}

interface BaseFile {
  contentType: string;
  downloadUrl: string;
  id: string;
  isError: false;
  isRejected: false;
  isUploaded: true;
  lastModified: number | null;
  lastModifiedDate: string | null;
  name: string | null;
  previewUrl: string | null;
  progress: number;
  size: number;
  url: string;
}

export interface ParsedApiFile extends BaseFile {
  kind: 'file';
}

type StreamingFileEncodingInfo = {
  error: Api.StreamingMediaFileEncodingError | null;
  id: string;
  progress: number;
  state: Api.StreamingMediaFileEncodingState;
};

export interface ParsedApiStreamingMediaFile extends BaseFile {
  encoding: StreamingFileEncodingInfo;
  kind: 'streamingMediaFile';
  previewUrl: string | null;
}

export type ParsedFileUnion = ParsedApiFile | ParsedApiStreamingMediaFile;

// type BaseFile<TParams = null> = {
//   contentType: string;
//   dateCreated: string | null;
//   name: string;
//   size: number;
//   params: TParams;
// };

// export type RemoteFileParams = {
//   id: string;
// };

// export type RemoteFile = BaseFile<RemoteFileParams> & {
//   type: 'remote';
// };

// type LocalFileParams = {
//   error?: string;
//   file: File;
//   isRejected: boolean;
//   preview?: string;
//   uploadId: number | null;
//   xhr: XMLHttpRequest | null;
// };

// export type LocalFile = BaseFile<LocalFileParams> & {
//   type: 'local';
// };

export type FileUploadResponseObject = {
  contentType: string | null;
  dateCreated: string | null;
  id: string;
  name: string | null;
  size: number;
  url: string;
};

export interface AbortableFile extends File {
  abort?: () => void;
}

export interface ParsedFile {
  abort?: () => void;
  contentType: string;
  downloadUrl: string | null;
  error?: ReactNode | string | null;
  id?: string;
  isError: boolean;
  isRejected: boolean;
  isUploaded: boolean;
  kind?: ParsedFileUnion['kind'] | null;
  lastModified: number | null;
  lastModifiedDate: string | null;
  name: string | null;
  previewUrl: string | null;
  progress: number;
  size: number;
  url: string | null;
}

export type ChoiceOption<T extends string = string> = {
  label: string;
  value: T;
};

interface BaseFieldDefinition {
  description: string | null;
  id: string;
  isRequired: boolean;
  name: string;
  presentationRoles: Api.PresentationRole[];
  title: string;
}

const ALLOWED_INPUT_TYPES = {
  Boolean: ['Switch'],
  ChoiceList: ['CheckboxGroup', 'MultiSelect', 'RadioButtonGroup', 'Select'],
  DateTime: ['DateTimePicker'],
  FileList: ['FileUpload'],
  KeyValue: ['KeyValuePair'],
  MemberList: ['PeoplePicker'],
  Money: ['MoneyInput'],
  Number: ['NumberInput'],
  Rating: ['Rating'],
  Text: ['RichTextInput', 'TextInput']
} as const;

type AllowedInputType<T extends keyof typeof ALLOWED_INPUT_TYPES> =
  (typeof ALLOWED_INPUT_TYPES)[T][number];

export function getValidInputType<T extends keyof typeof ALLOWED_INPUT_TYPES>(
  fieldType: T,
  inputType: InputType,
  defaultInputType: AllowedInputType<T>
): AllowedInputType<T> {
  if (ALLOWED_INPUT_TYPES[fieldType].includes(inputType)) {
    return inputType as AllowedInputType<T>;
  }

  return defaultInputType;
}

type GetAllowedInputTypes<T extends keyof typeof ALLOWED_INPUT_TYPES> =
  (typeof ALLOWED_INPUT_TYPES)[T][number];

export type BooleanSliceField = {
  kind: 'Boolean';
  id: string;
  value: boolean;
  definition: BaseFieldDefinition & {
    kind: BooleanSliceField['kind'];
    inputType: GetAllowedInputTypes<'Boolean'>;
    settings: {
      label: string | null;
    };
  };
};

export type ChoiceListSliceField = {
  kind: 'ChoiceList';
  id: string;
  value: string[];
  valueOptions: ChoiceOption[];
  definition: BaseFieldDefinition & {
    kind: ChoiceListSliceField['kind'];
    inputType: GetAllowedInputTypes<'ChoiceList'>;
    settings: {
      maxCount: number | null;
      minCount: number | null;
      placeholder: string | null;
      options: ChoiceOption[];
    };
  };
};

export type DateTimeSliceField = {
  kind: 'DateTime';
  id: string;
  value: string;
  definition: BaseFieldDefinition & {
    kind: DateTimeSliceField['kind'];
    inputType: GetAllowedInputTypes<'DateTime'>;
    settings: {
      hasTimeZone: boolean;
      isFuture: boolean;
      maxDate: string | null;
      minDate: string | null;
    };
  };
};

export type FileListSliceField = {
  kind: 'FileList';
  id: string;
  value: ParsedFileUnion[];
  definition: BaseFieldDefinition & {
    kind: FileListSliceField['kind'];
    inputType: GetAllowedInputTypes<'FileList'>;
    settings: {
      extensions: Api.FileExtensionGroup[];
      maxCount: number | null;
      minCount: number | null;
      maxFileSizeInKB: number | null;
      showPreview: boolean;
    };
  };
};

export type MemberListSliceField = {
  kind: 'MemberList';
  id: string;
  value: MemberOption[];
  definition: BaseFieldDefinition & {
    kind: MemberListSliceField['kind'];
    inputType: GetAllowedInputTypes<'MemberList'>;
    settings: {
      allowedMemberTypes: Api.MemberType[];
      maxCount: number | null;
      minCount: number | null;
    };
  };
};

export type MoneySliceField = {
  kind: 'Money';
  id: string;
  value: number | string;
  definition: BaseFieldDefinition & {
    kind: MoneySliceField['kind'];
    inputType: GetAllowedInputTypes<'Money'>;
    settings: {
      currency: string | null;
      maxValue: number | null;
      minValue: number | null;
      precision: number;
    };
  };
};

export type NumberSliceField = {
  kind: 'Number';
  id: string;
  value: number | string;
  definition: BaseFieldDefinition & {
    kind: NumberSliceField['kind'];
    inputType: GetAllowedInputTypes<'Number'>;
    settings: {
      maxValue: number | null;
      minValue: number | null;
      precision: number;
    };
  };
};

export type RatingSliceField = {
  kind: 'Rating';
  id: string;
  value: number | null;
  definition: BaseFieldDefinition & {
    kind: RatingSliceField['kind'];
    inputType: GetAllowedInputTypes<'Rating'>;
    settings: {
      legend: KeyValuePair[];
    };
  };
};

export type TextSliceField = {
  kind: 'Text';
  id: string;
  value: string;
  definition: BaseFieldDefinition & {
    inputType: GetAllowedInputTypes<'Text'>;
    settings: {
      limit: number | null;
      mode: 'MULTI_LINE' | 'RICH_TEXT' | 'SIMPLE';
      placeholder: string | null;
    };
  } & {
    kind: TextSliceField['kind'];
  };
};

export type UnknownSliceField = {
  kind: 'Unknown';
  id: string;
  value: string;
  definition: BaseFieldDefinition & {
    kind: 'Unknown';
    inputType: GetAllowedInputTypes<'Text'>;
    settings: null | undefined;
  };
};

export type SliceField =
  | BooleanSliceField
  | ChoiceListSliceField
  | DateTimeSliceField
  | FileListSliceField
  | MemberListSliceField
  | MoneySliceField
  | NumberSliceField
  | RatingSliceField
  | TextSliceField
  | UnknownSliceField;

type BooleanFieldDefinition = BooleanSliceField['definition'];
type ChoiceListFieldDefinition = ChoiceListSliceField['definition'];
type DateTimeFieldDefinition = DateTimeSliceField['definition'];
type FileListFieldDefinition = FileListSliceField['definition'];
type MemberListFieldDefinition = MemberListSliceField['definition'];
type MoneyFieldDefinition = MoneySliceField['definition'];
type NumberFieldDefinition = NumberSliceField['definition'];
type RatingFieldDefinition = RatingSliceField['definition'];
type TextFieldDefinition = TextSliceField['definition'];

export type FieldDefinition = SliceField['definition'];

export type BooleanFieldSettings = BooleanFieldDefinition['settings'];
export type ChoiceListFieldSettings = ChoiceListFieldDefinition['settings'];
export type DateTimeFieldSettings = DateTimeFieldDefinition['settings'];
export type FileListFieldSettings = FileListFieldDefinition['settings'];
export type MemberListFieldSettings = MemberListFieldDefinition['settings'];
export type MoneyFieldSettings = MoneyFieldDefinition['settings'];
export type NumberFieldSettings = NumberFieldDefinition['settings'];
export type RatingFieldSettings = RatingFieldDefinition['settings'];
export type RatingFieldSettingsWithKeyValuePair = Omit<RatingFieldSettings, 'legend'> & {
  legend: KeyValuePair[];
};
export type TextFieldSettings = TextFieldDefinition['settings'];

export type DataType = FieldDefinition['kind'];
export type InputType =
  | FieldDefinition['inputType']
  | 'AsyncSelect'
  | 'Checkbox'
  | 'ColorPicker'
  | 'ImageUpload'
  | 'KeyValueInputGroup'
  | 'TextInputGroup';

type ParsedMemberBase = {
  id: string;
  name: string;
};

type ParsedUserMember = ParsedMemberBase & {
  avatar: {
    id: string;
    previewUrl: string | null;
  } | null;
  firstName: string | null;
  isActive: boolean;
  lastName: string | null;
  type: 'USER';
};

type ParsedUserGroupMember = ParsedMemberBase & {
  description: string | null;
  memberCount: number;
  type: 'STATIC_USER_GROUP';
};

type ParsedClientApplicationMember = ParsedMemberBase & {
  description: string | null;
  type: 'CLIENT_APPLICATION';
};

export type ParsedMember = ParsedClientApplicationMember | ParsedUserGroupMember | ParsedUserMember;

export type User = Pick<BaseUserFragment, 'firstName' | 'id' | 'lastName'>;

export type UserWithAvatar = User & {
  avatar: Pick<Api.File, 'id' | 'previewUrl'> | null;
};

export type TextFieldData = Pick<Api.TextValueMetadata, '__typename' | 'dateCreated'> & {
  creator: BaseUserFragment;
  fieldValue: FieldValueFragment;
};

export type FieldData = Pick<Api.FieldValueMetadata, '__typename' | 'dateCreated'> & {
  creator: BaseUserFragment;
  fieldValue: FieldValueFragment;
};

interface BaseFieldData {
  creator: User | null;
  dateCreated: string;
}

type ParsedBooleanFieldData = BaseFieldData & {
  kind: 'Boolean';
  value: boolean;
};

type ParsedChoiceListFieldData = BaseFieldData & {
  kind: 'ChoiceList';
  value: string[];
  valueOptions: ChoiceOption[];
};

type ParsedDateTimeFieldData = BaseFieldData & {
  kind: 'DateTime';
  value: string;
};

type ParsedFileListFieldData = BaseFieldData & {
  kind: 'FileList';
  value: ParsedFileUnion[];
};

type ParsedMemberListFieldData = BaseFieldData & {
  kind: 'MemberList';
  value: MemberOption[];
};

type ParsedMoneyFieldData = BaseFieldData & {
  kind: 'Money';
  value: number | string;
};

type ParsedNumberFieldData = BaseFieldData & {
  kind: 'Number';
  value: number | string;
};

type ParsedRatingFieldData = BaseFieldData & {
  kind: 'Rating';
  value: number | null;
};

export type ParsedTextFieldData = BaseFieldData & {
  kind: 'Text';
  value: string;
};

type ParsedUnknownFieldData = BaseFieldData & {
  kind: 'Unknown';
  value: string;
};

export type ParsedFieldData =
  | ParsedBooleanFieldData
  | ParsedChoiceListFieldData
  | ParsedDateTimeFieldData
  | ParsedFileListFieldData
  | ParsedMemberListFieldData
  | ParsedMoneyFieldData
  | ParsedNumberFieldData
  | ParsedRatingFieldData
  | ParsedTextFieldData
  | ParsedUnknownFieldData;

export type Image = { previewUrl: string | null; alt?: string | null };
