import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, userGroupKeys } from '../utils';
import { GetEditUserGroupFormData } from './graphql/GetEditUserGroupFormData.gql';

export function getEditUserGroupFormDataQueryOptions(params: Params<'userGroupId'>) {
  return queryOptions({
    queryKey: userGroupKeys.groupById_edit(getRouteParam(params.userGroupId)),
    queryFn: ({ queryKey: [{userGroupId}] }) => fetchDocument(GetEditUserGroupFormData, { userGroupId }), // prettier-ignore
    staleTime: LOADER_STALE_TIME
  });
}

export function editUserGroupFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getEditUserGroupFormDataQueryOptions(params));
}
