import type { ReactNode } from 'react';
import { css } from '@emotion/react';
import type { ColorToken, RadiusToken } from '@/css/types';
import { Card } from '../primitives';

export type ThumbnailSize = 'lg' | 'md' | 'sm' | 'xl' | 'xs';

type Props = {
  aspectRatio?: number;
  autosize?: boolean;
  // StringLiteral | (string & {}) is a trick to enable intellisense for a string literal type, while
  // also allowing arbitrary string values outside of the literal.
  bgColor?: ColorToken | (string & {}); // eslint-disable-line @typescript-eslint/ban-types
  borderRadius?: RadiusToken;
  children?: ReactNode;
  className?: string;
  imageUrl?: string | null;
  label?: string;
  placeholderUrl?: string;
  size?: ThumbnailSize;
};

export function getHeightFromAspectRatio(aspectRatio: number) {
  return `${Math.round(100 / aspectRatio)}%`;
}

function getUnitForSize(size: ThumbnailSize) {
  if (size === 'xs') return '16px';
  if (size === 'sm') return '24px';
  if (size === 'md') return '40px';
  if (size === 'lg') return '48px';
  if (size === 'xl') return '64px';

  return '40px';
}

const thumbnailStyle = css({
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
});

export const Thumbnail = ({
  aspectRatio = 1.0,
  autosize = false,
  bgColor = '#e3e9ee',
  borderRadius = 'base',
  children,
  className,
  imageUrl,
  label,
  placeholderUrl,
  size = 'md'
}: Props) => (
  <Card
    aria-label={label}
    bgColor={bgColor}
    bgImage={imageUrl || placeholderUrl ? `url(${imageUrl || placeholderUrl})` : undefined}
    borderRadius={borderRadius}
    className={className}
    css={thumbnailStyle}
    data-testid="thumbnail"
    flexShrink={0}
    height={autosize ? 0 : getUnitForSize(size)}
    pb={autosize ? getHeightFromAspectRatio(aspectRatio) : undefined}
    role={imageUrl && label ? 'img' : undefined}
    width={autosize ? 'auto' : getUnitForSize(size)}
    // TODO: Force label/aria-labelledby, support aria-describedby
  >
    {children}
  </Card>
);
