import type { Image } from '@/types';
import { hasValue } from './common';
import { pxToDPR } from './image';

type ImageOptions =
  | { mode: 'crop'; w?: number; h: number } // A height is required when using "crop" mode.
  | { mode: 'max' | 'pad'; w?: number; h?: number };

export function appendOptionsToImageUrl(url: string, options: ImageOptions) {
  const parts = url.split('?');
  const params = new URLSearchParams(parts.at(1));

  if (hasValue(options.w)) params.set('width', pxToDPR(options.w).toString());
  if (hasValue(options.h)) params.set('height', pxToDPR(options.h).toString());
  params.set('mode', options.mode);

  return parts.at(0) + '?' + params.toString();
}

/**
 * Generates a URL for an image with scaling parameters.
 * @param url - The original URL of the image.
 * @param options - The options for image resizing.
 * @returns The URL of the image with the specified scaling parameters.
 */
export function getImageUrl(image: Image | null | undefined, options: ImageOptions) {
  return image?.previewUrl ? appendOptionsToImageUrl(image.previewUrl, options) : null;
}
