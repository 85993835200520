import { useCallback, useContext, useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useAppConfigValue } from '@/components/AppConfig';
import { SessionUserContext } from '@/components/SessionUserContext';
import type { ParsedMember } from '@/types';
import { getEmptyArray } from '@/utils/array';
import { getApolloErrors } from '@/utils/errors';
import { getFullName } from '@/utils/user';
import { useFavoriteCommunities } from '../../hooks/useFavoriteCommunities/useFavoriteCommunities';
import {
  type DashboardFilterValue,
  DashboardView,
  type GetLocation,
  type View
} from '../../views/DashboardView';
import {
  type FeaturedCommunityFragment,
  FeaturedCommunityFragmentDoc
} from './FeaturedCommunityFragment.gql';
import { GetDashboardCommunities } from './GetDashboardCommunities.gql';
import { GetDashboardData, type GetDashboardDataQueryVariables } from './GetDashboardData.gql';
import { GetHiddenCommunities } from './GetHiddenCommunities.gql';

export type { DashboardFilterValue as FilterValue, GetLocation, View };

type Props = {
  activeFilter?: DashboardFilterValue;
  activeView: View;
  getLocation: GetLocation;
};

function getVariables(
  activeFilter: DashboardFilterValue,
  favoriteCommunityIds: string[]
): GetDashboardDataQueryVariables {
  let communityIds: string[] | undefined;
  let featuredCommunityId = '00000000-0000-0000-0000-000000000000';
  let includeFeaturedCommunity = false;

  if (activeFilter === 'favorites') {
    communityIds = favoriteCommunityIds;
  } else if (activeFilter !== 'all') {
    communityIds = [activeFilter[1]];
    featuredCommunityId = activeFilter[1]; // eslint-disable-line prefer-destructuring
    includeFeaturedCommunity = true;
  }

  return {
    communityIds,
    featuredCommunityId,
    includeCounts: Boolean(communityIds?.length),
    includeFeaturedCommunity
  };
}

export const DashboardViewContainer = ({
  activeFilter: _activeFilter,
  activeView,
  getLocation
}: Props) => {
  const client = useApolloClient();
  const [favoriteCommunityIds, actions] = useFavoriteCommunities();
  const sessionUser = useContext(SessionUserContext);
  const intialCommunityCount = useAppConfigValue('viewer_communityCount');
  const activeFilter = _activeFilter ?? (favoriteCommunityIds.length > 0 ? 'favorites' : 'all');
  const queryResult = useQuery(GetDashboardData, {
    variables: useMemo(
      () => getVariables(activeFilter, favoriteCommunityIds),
      [activeFilter, favoriteCommunityIds]
    )
  });

  const communitiesResult = useQuery(GetDashboardCommunities);
  const communities = communitiesResult.data?.communities ?? getEmptyArray();
  const favoriteCommunities = communitiesResult.data?.favoriteCommunities ?? getEmptyArray();
  const hasIdeationModulesAvailableForContribution = Boolean(
    queryResult.data?.ideationModulesAvailableForContribution.totalCount
  );

  const hiddenCommunitiesResult = useQuery(GetHiddenCommunities);
  const hiddenCommunities =
    hiddenCommunitiesResult.data?.sessionUser?.userAgreementScopeSubjects.items ?? [];

  let showAddContributionButton = false;

  // We only want to use the appConfig count initially, since otherwise we'll
  // end up with weird behavior if the current user starts adding or removing
  // communities, as the appConfig value will always remain the same.
  const communityCount = communitiesResult.data
    ? communities.length + favoriteCommunities.length
    : intialCommunityCount;

  let featuredCommunity: FeaturedCommunityFragment | null | undefined;

  if (communityCount === 1) {
    // If the viewer only has access to one community we'll always display a
    // "featured community" header, containing the default community.
    featuredCommunity = queryResult.data?.defaultCommunity;
    showAddContributionButton = hasIdeationModulesAvailableForContribution;
  } else if (Array.isArray(activeFilter)) {
    showAddContributionButton = hasIdeationModulesAvailableForContribution;
    // If the dashboard is filtered on one community we'll always display a
    // "featured community" header, containing the filtered community.
    try {
      // In most cases the featured community fragment should already be
      // available in the cache since the GetCommunities query fetches the same
      // fragment, so we can attempt to read it from the cache first and speed
      // up the dashboard rendering since we don't need to wait on an API
      // round-trip. If for some reason no fragment match is found it will
      // throw, so we need to wrap it in a try/catch and subsequently fall back
      // to the API response.
      featuredCommunity = client.readFragment({
        id: `Community:${activeFilter[1]}`,
        fragment: FeaturedCommunityFragmentDoc
      });
    } catch (e) {
      featuredCommunity = queryResult.data?.featuredCommunity || null;
    }
  }

  const viewer: ParsedMember = useMemo(
    () => ({
      type: 'USER',
      avatar: sessionUser.avatar,
      firstName: sessionUser.firstName,
      id: sessionUser.id,
      isActive: true,
      lastName: sessionUser.lastName,
      name: getFullName(sessionUser)
    }),
    [sessionUser]
  );

  const dashboard = queryResult.data?.dashboard;

  let taskCount: number | undefined;
  let viewerContributionCount: number | undefined;

  if (activeFilter !== 'all') {
    taskCount = queryResult.data?.sessionUser?.userTaskCount;
    viewerContributionCount = queryResult.data?.sessionUser?.contributionCount;
  } else {
    taskCount = queryResult.data?.sessionUser?.userTaskCount ?? sessionUser.taskCount;
    viewerContributionCount =
      queryResult.data?.sessionUser?.contributionCount ?? sessionUser.contributionCount;
  }

  const refetchQuery = queryResult.refetch;
  const refetchCommunities = communitiesResult.refetch;
  const handleFeaturedCommunityUpdated = useCallback(() => refetchQuery(), [refetchQuery]);

  const onAddCommunityToFavorites = useCallback(
    (communityId: string) => actions.addFavorite(communityId).then(() => refetchCommunities()),
    [actions, refetchCommunities]
  );

  const onRemoveCommunityFromFavorites = useCallback(
    (communityId: string) => actions.removeFavorite(communityId).then(() => refetchCommunities()),
    [actions, refetchCommunities]
  );

  const onClearFavoriteCommunities = useCallback(
    () => actions.clearFavorites().then(() => refetchCommunities()),
    [actions, refetchCommunities]
  );

  return (
    <DashboardView
      activeFilter={activeFilter}
      activeView={activeView}
      banner={dashboard?.banner}
      communities={communities}
      communityCount={communityCount}
      description={dashboard?.description}
      errors={getApolloErrors(
        queryResult.error,
        communitiesResult.error,
        hiddenCommunitiesResult.error
      )}
      favoriteCommunities={favoriteCommunities}
      favoriteCommunityIds={favoriteCommunityIds}
      featuredCommunity={featuredCommunity}
      getLocation={getLocation}
      hiddenCommunities={hiddenCommunities}
      onAddCommunityToFavorites={onAddCommunityToFavorites}
      onClearFavoriteCommunities={onClearFavoriteCommunities}
      onFeaturedCommunityUpdated={handleFeaturedCommunityUpdated}
      onRemoveCommunityFromFavorites={onRemoveCommunityFromFavorites}
      showAddContributionButton={showAddContributionButton}
      taskCount={taskCount}
      viewer={viewer}
      viewerContributionCount={viewerContributionCount}
    />
  );
};
