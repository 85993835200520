import type { To } from 'react-router-dom';
import { AppLocations } from '@/pages/config';
import { exhaustiveCheck } from '@/types/utils';
import { isObject } from './common';

export type ActionMap = {
  EmailVerified: null;
  InvitationAccept: { invitationToken: string; signupUrl: string; hasMessage?: boolean };
  InvitationNotFound: null;
  LoginEmailNotYetVerified: null;
  LoginInternalError: null;
  LoginUserNotFound: null;
  LoginUserNotInEmailAllowlist: null;
  NavigateToCommunity: { communityId: string };
  NavigateToContribution: { contributionId: string };
  NavigateToCreateContribution: { moduleId?: string; programId?: string; communityId?: string };
  NavigateToEditContribution: { contributionId: string };
  NavigateToEnrichmentComment: { contributionId: string; moduleId: string };
  NavigateToIdeationModuleRequest: { ideationModuleRequestId: string; programId: string };
  NavigateToIdeationModuleRequestComment: {
    ideationModuleRequestCommentId: string;
    ideationModuleRequestId: string;
    programId: string;
  };
  NavigateToIdeationModuleRequestManagement: { ideationModuleRequestId: string; programId: string };
  NavigateToModule: { moduleId: string };
  NavigateToProfile: { profileId: string };
  NavigateToProgram: { programId: string };
  NavigateToRegularComment: { contributionId: string };
  NavigateToReviewComment: { contributionId: string; moduleId: string };
  NavigateToTasks: { communityId: string };
  NavigateToUserRemovalRequest: null;
  UserRegistrationDisabled: null;
};

type Merge<T, U> = {
  [K in keyof T | keyof U]: K extends keyof U ? U[K] : K extends keyof T ? T[K] : never;
};

type TAction<A extends keyof ActionMap> = ActionMap[A] extends null
  ? { action: A }
  : Merge<ActionMap[A], { action: A }>;

interface IAction {
  action: string;
  [key: string]: unknown;
}

function isActionOfType<T extends keyof ActionMap>(action: IAction, type: T) {
  return action.action === type;
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

function isUndefined(value: unknown): value is undefined {
  return typeof value === 'undefined';
}

function isStringOrUndefined(value: unknown): value is string | undefined {
  return isString(value) || isUndefined(value);
}

function isBooleanOrUndefined(value: unknown): value is boolean | undefined {
  return typeof value === 'boolean' || isUndefined(value);
}

function isEmailVerified(data: IAction): data is TAction<'EmailVerified'> {
  return data.action === 'EmailVerified';
}

function isInvitationAccept(data: IAction): data is TAction<'InvitationAccept'> {
  return (
    isActionOfType(data, 'InvitationAccept') &&
    isString(data.invitationToken) &&
    isString(data.signupUrl) &&
    isBooleanOrUndefined(data.hasMessage)
  );
}

function isInvitationNotFound(data: IAction): data is TAction<'InvitationNotFound'> {
  return isActionOfType(data, 'InvitationNotFound');
}

function isLoginEmailNotYetVerified(data: IAction): data is TAction<'LoginEmailNotYetVerified'> {
  return isActionOfType(data, 'LoginEmailNotYetVerified');
}

function isLoginInternalError(data: IAction): data is TAction<'LoginInternalError'> {
  return isActionOfType(data, 'LoginInternalError');
}

function isLoginUserNotFound(data: IAction): data is TAction<'LoginUserNotFound'> {
  return isActionOfType(data, 'LoginUserNotFound');
}

// prettier-ignore
function isLoginUserNotInEmailAllowlist(data: IAction): data is TAction<'LoginUserNotInEmailAllowlist'> {
  return isActionOfType(data, 'LoginUserNotInEmailAllowlist');
}

function isNavigateToCommunity(data: IAction): data is TAction<'NavigateToCommunity'> {
  return isActionOfType(data, 'NavigateToCommunity') && isString(data.communityId);
}

function isNavigateToContribution(data: IAction): data is TAction<'NavigateToContribution'> {
  return isActionOfType(data, 'NavigateToContribution') && isString(data.contributionId);
}

// prettier-ignore
function isNavigateToCreateContribution(data: IAction): data is TAction<'NavigateToCreateContribution'> {
  return (
    isActionOfType(data, 'NavigateToCreateContribution') &&
    (isStringOrUndefined(data.moduleId) ||
      isStringOrUndefined(data.programId) ||
      isStringOrUndefined(data.communityId))
  );
}

// prettier-ignore
function isNavigateToEditContribution(data: IAction): data is TAction<'NavigateToEditContribution'> {
  return isActionOfType(data, 'NavigateToEditContribution') && isString(data.contributionId);
}

// prettier-ignore
function isNavigateToEnrichmentComment(data: IAction): data is TAction<'NavigateToEnrichmentComment'> {
  return (
    isActionOfType(data, 'NavigateToEnrichmentComment') &&
    isString(data.moduleId) &&
    isString(data.contributionId)
  );
}

// prettier-ignore
function isNavigateToIdeationModuleRequest(data: IAction): data is TAction<'NavigateToIdeationModuleRequest'> {
  return (
    isActionOfType(data, 'NavigateToIdeationModuleRequest') &&
    isString(data.ideationModuleRequestId) &&
    isString(data.programId)
  );
}

// prettier-ignore
function isNavigateToIdeationModuleRequestComment(data: IAction): data is TAction<'NavigateToIdeationModuleRequestComment'> {
  return (
    isActionOfType(data, 'NavigateToIdeationModuleRequestComment') &&
    isString(data.ideationModuleRequestCommentId) &&
    isString(data.ideationModuleRequestId) &&
    isString(data.programId)
  );
}

// prettier-ignore
function isNavigateToIdeationModuleRequestManagement(data: IAction): data is TAction<'NavigateToIdeationModuleRequestManagement'> {
  return (
    isActionOfType(data, 'NavigateToIdeationModuleRequestManagement') &&
    isString(data.ideationModuleRequestId) &&
    isString(data.programId)
  );
}

function isNavigateToModule(data: IAction): data is TAction<'NavigateToModule'> {
  return isActionOfType(data, 'NavigateToModule') && isString(data.moduleId);
}

function isNavigateToProfile(data: IAction): data is TAction<'NavigateToProfile'> {
  return isActionOfType(data, 'NavigateToProfile') && isString(data.profileId);
}

function isNavigateToProgram(data: IAction): data is TAction<'NavigateToProgram'> {
  return isActionOfType(data, 'NavigateToProgram') && isString(data.programId);
}

function isNavigateToRegularComment(data: IAction): data is TAction<'NavigateToRegularComment'> {
  return isActionOfType(data, 'NavigateToRegularComment') && isString(data.contributionId);
}

function isNavigateToReviewComment(data: IAction): data is TAction<'NavigateToReviewComment'> {
  return (
    isActionOfType(data, 'NavigateToReviewComment') &&
    isString(data.moduleId) &&
    isString(data.contributionId)
  );
}

function isNavigateToTasks(data: IAction): data is TAction<'NavigateToTasks'> {
  return isActionOfType(data, 'NavigateToTasks') && isString(data.communityId);
}

// prettier-ignore
function isNavigateToUserRemovalRequest(data: IAction): data is TAction<'NavigateToUserRemovalRequest'> {
  return isActionOfType(data, 'NavigateToUserRemovalRequest');
}

function isUserRegistrationDisabled(data: IAction): data is TAction<'UserRegistrationDisabled'> {
  return isActionOfType(data, 'UserRegistrationDisabled');
}

function isAction(value: unknown): value is IAction {
  return isObject(value) && isString(value.action);
}

export function decodeBase64Action(encodedAction: string): IAction | null {
  let payload;

  try {
    payload = JSON.parse(window.atob(encodedAction));
  } catch {
    // Invalid action
  }

  if (isAction(payload)) {
    return payload;
  }

  return null;
}

export function getActionUrl(action: IAction): To | null {
  let to: To | null = null;

  if (
    isEmailVerified(action) ||
    isInvitationNotFound(action) ||
    isLoginEmailNotYetVerified(action) ||
    isLoginInternalError(action) ||
    isLoginUserNotFound(action) ||
    isLoginUserNotInEmailAllowlist(action) ||
    isUserRegistrationDisabled(action)
  ) {
    to = `/status?type=${action.action}`;
  } else if (isInvitationAccept(action)) {
    // The current way of passing the "signupUrl" property in the action fragment
    // is incredibly unsafe, as it could allow an attacker to generate a custom
    // action fragment with a bogus signupUrl, which would in turn redirect the
    // user off-site and allow a registration hijack. Rather than using the
    // "signupUrl" property as-is, we'll parse the URL and take the query params
    // off it that we need: invitee, given_name & family_name. These will be
    // forwarded to our internal invite route and then converted to a SAFE url.
    const signupUrl = new URL(action.signupUrl);
    const params = new URLSearchParams();
    const invitee = signupUrl.searchParams.get('invitee');
    const givenName = signupUrl.searchParams.get('given_name');
    const familyName = signupUrl.searchParams.get('family_name');

    if (invitee) params.set('invitee', invitee);
    if (givenName) params.set('givenName', givenName);
    if (familyName) params.set('familyName', familyName);
    if (action.hasMessage) params.set('hasMessage', 'true');

    to = `/invite/${action.invitationToken}?${params.toString()}`;
  } else if (isNavigateToCommunity(action)) {
    to = AppLocations.main.getHomePage({ filter: ['community', action.communityId] });
  } else if (isNavigateToContribution(action)) {
    to = AppLocations.main.getContributionPage(action.contributionId);
  } else if (isNavigateToCreateContribution(action)) {
    if (action.moduleId) {
      to = AppLocations.main.getModulePage(action.moduleId, 'add-contribution');
    } else if (action.programId) {
      to = AppLocations.main.getProgramPage(action.programId, 'add-contribution');
    } else if (action.communityId) {
      to = AppLocations.main.getHomePage({
        dialog: 'add-contribution',
        filter: ['community', action.communityId]
      });
    } else {
      to = AppLocations.main.getHomePage({ dialog: 'add-contribution' });
    }
  } else if (isNavigateToEditContribution(action)) {
    to = AppLocations.main.getContributionPage(action.contributionId, { dialog: 'edit' });
  } else if (isNavigateToEnrichmentComment(action)) {
    to = AppLocations.main.getContributionPage(action.contributionId, {
      view: ['timeline', { id: action.moduleId, type: 'ENRICHMENT_MODULE', view: 'comments' }]
    });
  } else if (isNavigateToReviewComment(action)) {
    to = AppLocations.main.getContributionPage(action.contributionId, {
      view: ['timeline', { id: action.moduleId, type: 'REVIEW_MODULE', view: 'comments' }]
    });
  } else if (isNavigateToIdeationModuleRequest(action)) {
    to = AppLocations.main.getCurrentUserIdeationModuleRequestPage(
      action.programId,
      action.ideationModuleRequestId
    );
  } else if (isNavigateToIdeationModuleRequestComment(action)) {
    to = AppLocations.main.getCurrentUserIdeationModuleRequestPage(
      action.programId,
      action.ideationModuleRequestId,
      'activity'
    );
  } else if (isNavigateToIdeationModuleRequestManagement(action)) {
    to = AppLocations.admin.getProgramPage(action.programId, {
      ideationModuleRequestId: action.ideationModuleRequestId
    });
  } else if (isNavigateToModule(action)) {
    to = AppLocations.main.getModulePage(action.moduleId);
  } else if (isNavigateToProfile(action)) {
    to = AppLocations.main.getUserProfilePage(action.profileId);
  } else if (isNavigateToProgram(action)) {
    to = AppLocations.main.getProgramPage(action.programId);
  } else if (isNavigateToRegularComment(action)) {
    to = AppLocations.main.getContributionPage(action.contributionId, { view: 'comments' });
  } else if (isNavigateToTasks(action)) {
    to = AppLocations.main.getHomePage({
      filter: ['community', action.communityId],
      view: 'tasks'
    });
  } else if (isNavigateToUserRemovalRequest(action)) {
    to = AppLocations.admin.getUserRemovalRequestListPage();
  } else if (isAction(action)) {
    to = null;
  } else {
    /* istanbul ignore next */ exhaustiveCheck(action);
  }

  return to;
}
