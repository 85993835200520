import { useMemo, useState } from 'react';
import type { To } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useI18n } from '@/components/I18n';
import { parseContributionCardFragment as parseContribution } from '@/features/contributions/utils/parseContributionCardFragment';
import { parseModuleCardFragment } from '@/features/modules/common/graphql/fragments/utils';
import {
  getModuleSortOrderOptions,
  type ModuleSortOrder
} from '@/features/modules/common/utils/sortOrder';
import type { ParsedMember } from '@/types';
import { hasValue } from '@/utils/common';
import { getApolloErrors } from '@/utils/errors';
import { useEventEmitter } from '@/utils/hooks/useEventEmitter';
import { useUpdateEffect } from '@/utils/hooks/useUpdateEffect';
import { useWeakTransformCache } from '@/utils/hooks/useWeakTransformCache';
import { type CommunityFilter, OverviewView } from '../../views/DashboardView/OverviewView';
import { GetContributions } from './GetContributions.gql';
import { GetModules } from './GetModules.gql';
import { GetViewerData, type GetViewerDataQuery } from './GetViewerData.gql';

type UserTask = GetViewerDataQuery['tasks']['items'][number];

function parseTask({ subject, queueCount, count, ...rest }: UserTask) {
  return {
    ...rest,
    completedCount: count, // TODO: Rename in API, this is so confusing.
    totalCount: queueCount, // TODO: Rename in API, this is so confusing.
    program: subject ? { id: subject.programId, title: subject.programTitle } : null,
    subject: subject ? { id: subject.id, title: subject.title } : null
  };
}

export type { CommunityFilter };

type Props = {
  communityFilter?: CommunityFilter;
  communityIds: string[];
  tasksRoute: To;
  /** The number of pending tasks assigned to the viewer, taking into account the active filter. */
  taskCount: number | undefined;
  viewer: ParsedMember;
  /** The number of contributions created by the viewer, taking into account the active filter. */
  viewerContributionCount: number | undefined;
};

export const OverviewViewContainer = ({
  communityFilter,
  communityIds,
  tasksRoute,
  taskCount: initialTaskCount,
  viewer,
  viewerContributionCount: initialViewerContributionCount
}: Props) => {
  const emitter = useEventEmitter();
  const i18n = useI18n();
  const moduleSortOrderOptions = useMemo(() => getModuleSortOrderOptions(i18n), [i18n]);
  const [moduleSortOrder, setModuleSortOrder] = useState<ModuleSortOrder>('TIMING_ASC');

  const viewerDataResult = useQuery(GetViewerData, {
    variables: {
      communityIds,
      viewerId: viewer.id
    }
  });

  const modulesResult = useQuery(GetModules, {
    variables: {
      communityIds,
      moduleSortOrder
    }
  });

  const contributionsResult = useQuery(GetContributions, {
    variables: { communityIds }
  });
  const contributionsResultRefetch = contributionsResult.refetch;
  const viewerDataResultRefetch = viewerDataResult.refetch;

  useUpdateEffect(() => {
    const refetchQueries = () => {
      contributionsResultRefetch();
      viewerDataResultRefetch();
    };

    emitter.on('contributionAdded', refetchQueries);
    emitter.on('contributionDeleted', refetchQueries);

    return () => {
      emitter.off('contributionAdded', refetchQueries);
      emitter.off('contributionDeleted', refetchQueries);
    };
  }, [emitter, contributionsResultRefetch, viewerDataResultRefetch]);

  const moduleCount = modulesResult.data?.modules.totalCount;
  const modules = useWeakTransformCache(modulesResult.data?.modules.items, parseModuleCardFragment);
  const tasks = useWeakTransformCache(viewerDataResult.data?.tasks.items, parseTask);

  const viewerContributions = useWeakTransformCache(
    viewerDataResult.data?.viewerContributions.items,
    parseContribution
  );

  const recentContributions = useWeakTransformCache(
    contributionsResult.data?.recentContributions.items,
    parseContribution
  );

  const trendingContributions = useWeakTransformCache(
    contributionsResult.data?.trendingContributions.items,
    parseContribution
  );

  // So we're in a bit of a pickle. We need to know _as soon as possible_
  // whether or not we have to display the "tasks" and "my recent contributions"
  // widgets, which, when we're not filtering the dashboard on one or more
  // communities, we (well, the parent container) can determine based on the
  // counts that are in the AppConfig.
  // However, when we *are* filtering the dashboard we're dependent on a query
  // to fetch these counts on-the-fly, and the query performance on the
  // dashboard can vary wildly based on various factors. The parent container is
  // looking up these values, but in order to not put all of our eggs in the
  // same basket we'll fetch the values in the local query too, just in case it
  // executes faster. If we have a local value we'll just use that, if we don't,
  // we'll fall back to the values provided by the parent, if any.
  // If we don't have any values we'll default to "no value", and let the
  // underlying view decide how to sort it out and what to show.
  // Speed is very important here because it determines how quickly the
  // dashboard will render and be interactive. Since it is (usually) the landing
  // page, we want it to be as fast as possible.

  const taskCount = viewerDataResult.data?.tasks.totalCount ?? initialTaskCount;
  const viewerContributionCount =
    viewerDataResult.data?.viewerContributions.totalCount ?? initialViewerContributionCount;

  const viewerHasTasks = hasValue(taskCount) ? Boolean(taskCount) : undefined;
  const viewerHasContributions = hasValue(viewerContributionCount)
    ? Boolean(viewerContributionCount)
    : undefined;

  return (
    <OverviewView
      communityFilter={communityFilter}
      errors={getApolloErrors(
        viewerDataResult.error,
        modulesResult.error,
        contributionsResult.error
      )}
      isLoading={viewerDataResult.networkStatus === 1 || modulesResult.networkStatus === 1}
      moduleCount={moduleCount}
      modules={modules}
      moduleSortOrder={moduleSortOrder}
      moduleSortOrderOptions={moduleSortOrderOptions}
      onModuleSortOrderChange={setModuleSortOrder}
      recentContributions={recentContributions}
      taskCount={taskCount}
      tasks={tasks}
      tasksRoute={tasksRoute}
      trendingContributions={trendingContributions}
      viewer={viewer}
      viewerContributionCount={viewerContributionCount}
      viewerContributions={viewerContributions}
      viewerHasContributions={viewerHasContributions}
      viewerHasTasks={viewerHasTasks}
    />
  );
};
