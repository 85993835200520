import { type ComponentProps, forwardRef, type ReactNode } from 'react';
import memoizeOne from 'memoize-one';
import { Box, Spacer } from '@/components/primitives';
import { Article } from '@/components/SectioningContentElement';
import { getToken } from '@/css/utils';

function getLineHeightWithUnit(value: number | string): string {
  // A unit-less line-height (i.e. 1.5) should be the same as the corresponding
  // "em" value since they're both context-aware values.
  return typeof value === 'string' ? value : `${value.toString()}em`;
}

const getContentHeight = memoizeOne(
  (
    headingLineHeight: number | string,
    descriptionLineHeight: number | string,
    numLines: number,
    additionalSpacing: number | string
  ) => {
    const headingHeight = `${getLineHeightWithUnit(headingLineHeight)} + ${additionalSpacing}`;
    const descriptionHeight = `(${getLineHeightWithUnit(descriptionLineHeight)} * ${numLines})`;

    return `calc(${headingHeight} + ${descriptionHeight})`;
  }
);

type Slots = {
  additionalContent: ReactNode;
  parent: ReactNode;
  content: ReactNode;
  heading: ReactNode;
  meta: ReactNode;
  thumbnail: ReactNode;
  timestamp: ReactNode;
};

type Props = ComponentProps<typeof Article> & {
  slots: Slots;
};

export const ContributionCardLayout = forwardRef<HTMLDivElement, Props>(
  ({ slots, ...props }, ref) => {
    const height = getContentHeight(
      1.25,
      getToken('lineHeights', 'tight'),
      4,
      getToken('space', 's02')
    );

    return (
      <Article {...props} ref={ref}>
        {slots.thumbnail}
        <Box overflowWrap="break-word" py="s03" wordBreak="break-word">
          {slots.timestamp}
          <Box height={{ sm: height }} overflow="hidden">
            {slots.heading}
            {slots.content}
          </Box>
          {slots.parent && <Box mt="s03">{slots.parent}</Box>}
          {slots.additionalContent && <Box mt="s03">{slots.additionalContent}</Box>}
          <Spacer mb="s04" />
          {slots.meta}
        </Box>
      </Article>
    );
  }
);
